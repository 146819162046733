<div class="loading-button-wrapper">
    <button class="loading-button" [ngClass]="buttonClass" [color]="color" mat-button (click)="clicked()"
            *ngIf="withoutBackground"
            [disabled]="disabledCondition || loadingBool" matTooltip="{{tooltip}}">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
    <button class="loading-button" [ngClass]="buttonClass" [color]="color" mat-flat-button (click)="clicked()"
            *ngIf="!withoutBackground"
            [disabled]="disabledCondition || loadingBool" matTooltip="{{tooltip}}">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>

    <mat-progress-spinner
            class="spinner"
            *ngIf="loadingBool"
            color="primary"
            diameter="24"
            strokeWidth="1"
            mode="indeterminate">
    </mat-progress-spinner>

</div>
<ng-template #content>
    <ng-content></ng-content>
</ng-template>
