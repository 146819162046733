import { Component, OnInit } from '@angular/core';
import {ConsultantAvailabilityData} from "../../../generated/data";
import {ConsultantAppointmentResource} from "../../../generated/resources";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DialogService} from "../../utility/side-sheet/dialog.service";
import {CreateConsultantAvailabilityDialogComponent} from "./create-consultant-availability-dialog/create-consultant-availability-dialog.component";

@Component({
  selector: 'consultant-availability',
  templateUrl: './consultant-availability.component.html',
  styleUrls: ['./consultant-availability.component.scss']
})
export class ConsultantAvailabilityComponent implements OnInit {


  public displayedColumns: string[] = ['date', 'reoccurring', 'actions'];
  public loading: boolean = true;

  availabilityData: ConsultantAvailabilityData[] = []

  constructor(
      private consultantAppointmentResource: ConsultantAppointmentResource,
      private dialogService: DialogService,
      private snackBar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
    this.getAvailabilities();
  }

  getAvailabilities(): void {
    this.loading = true;

    this.consultantAppointmentResource.getConsultantAvailabilities().then(availabilities => {
      this.availabilityData = availabilities
      this.loading = false;
    })
  }

  deleteAvailability(id: number) {
    this.consultantAppointmentResource.deleteConsultantAvailability({availabilityId: id}).then(() => {
          this.getAvailabilities()
          this.snackBar.open("Verfügbarkeit erfolgreich gelöscht!", null, {duration: 3000})
    }
    )
  }

  createAvailability() {
    const comp = this.dialogService.openOverlay(CreateConsultantAvailabilityDialogComponent, '600px');
    const subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(result => {
      if (result) this.getAvailabilities()
        subscription.unsubscribe();
    });
  }

}
