
<div class="celebration-wrapper" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
    <div>
        <i class="mdi mdi-party-popper"></i>
    </div>
    <div class="celebration-headline">Herzlichen Glückwunsch</div>
    <div *ngIf="data.length == 1">Ein Talent aus deinem Coaching wurde über Talentagent eingestellt!</div>
    <div *ngIf="data.length > 1">Talente aus deinem Coaching wurden über Talentagent eingestellt!</div>

    <div class="hiring-wrapper" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px">
        <div class="hiring" *ngFor="let h of data">{{h.talentFullName}} wurde von {{h.companyName}} eingestellt!</div>
    </div>

    <div><span *ngIf="incentAvailable">Mit dieser Einstellung erhältst du die Möglichkeit auf weitere Kontakte von Uniwunder. </span> <span>Weiter so!</span></div>

    <button (click)="close()" mat-button>Weiter geht's</button>
</div>
