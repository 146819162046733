import {Component, Input, OnInit} from '@angular/core';
import {ChatMessageData} from "../../../../generated/data";
import {ConsultantAuthService} from "../../../service/consultant.auth.service";
import {environment} from "../../../../environments/environment";
import {ConsultantTalentProfilePictureResource, ConsultantTalentResource} from "../../../../generated/resources";

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss']
})
export class ChatMessageComponent implements OnInit {

  consultantId: number

  @Input() messageData: ChatMessageData
  @Input() profilePictureUrl: string = 'url(../assets/images/blurred_user.png)'

  constructor(
      private authService: ConsultantAuthService,
  ) { }

  ngOnInit(): void {
    this.consultantId = this.authService.getAccountInfo().id
  }


}
