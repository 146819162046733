<div id="main-component-wrapper" class="width-100">
    <div class="main-header" fxLayout="row" fxLayoutAlign="space-between start">
        <div fxLayout="row" fxLayoutAlign="start center">
            <h1>Profil</h1>
        </div>
    </div>

    <div id="component-content-wrapper" class="mat-elevation-z8 width-100" fxLayout="column"
         fxLayoutAlign="start center">

        <div class="image-wrapper">
            <div class="profilepic"
                 fxLayout="row"
                 fxLayoutAlign="center end"
                 [ngStyle]="{'background-image': (!noProfilePictureAvailable && profilePicture) ? 'url(' + profilePicture + ')' : 'url(' + '../assets/images/blurred_user.png' + ')'}">
            </div>
        </div>

        <small style="color: red" *ngIf="profilePictureCtrl.errors != null && profilePictureCtrl.errors['sizeTooBig']">Die Datei ist
            größer als 3MB,
            Bitte wähle eine kleinere Datei
        </small>

        <div class="width-100 action-bar" fxLayout="row" fxLayoutAlign="center center">
            <input id="picture-upload" type="file" class="inputfile"
                   (change)="onFileChange($event)"
                   [accept]="fileService.whiteListedExtensions"/>
            <label for="picture-upload" fxLayout="row" fxLayoutAlign="center center" class="mat-elevation-z3">
                <i class="mdi mdi-upload m-right5"></i>
                <span>Bild auswählen</span>
            </label>
        </div>

        <form [formGroup]="form" fxLayout="column" fxLayoutAlign="start center" class="width-100">

            <div fxLayout="row" fxLayoutAlign="space-between center" class="width-100">
                <i class="mdi mdi-email m-right20"></i>
                <mat-form-field>
                    <mat-label>Email</mat-label>
                    <input formControlName="emailControl" matInput>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center" class="width-100">
                <i class="mdi mdi-phone m-right20"></i>
                <mat-form-field>
                    <mat-label>Telefon</mat-label>
                    <input formControlName="phoneControl" matInput>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center" class="width-100">
                <i class="mdi mdi-whatsapp m-right20"></i>
                <mat-form-field>
                    <mat-label>WhatsApp</mat-label>
                    <input formControlName="whatsAppControl" matInput>
                </mat-form-field>
            </div>


            <div class="width-100">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <img src="assets/felxperto.png" style="width: 30px; position: relative;
    left: -4px;" class="m-right10">
                    <mat-form-field>
                        <mat-label>Flexperto - Termin vereinbaren Link</mat-label>
                        <input formControlName="felxpertoControl" matInput>
                    </mat-form-field>
                </div>


                <div class="font-size-11">Über deinen Flexperto-Link können sich deine Talente direkt einen Termin bei dir buchen. Hast du einen Link eingetragen, sieht das Talent auf deinem Profil einen Button mit "Termin vereinbaren". Du solltest die Terminart "Talentagent" auf deiner Flexperto-Seite anbieten, danach werden die Kontakte suchen. <br><b>Beispiel für einen korrekten Link:</b><br>
                    https://mlp-onlineberatung.flexperto.com/expert/xxx/schedule?serviceId=xxx
                </div>
            </div>

            <mat-form-field>
                <mat-label>Titel</mat-label>
                <input formControlName="titleControl" matInput>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Über Mich (max. 250 Zeichen)</mat-label>
                <textarea formControlName="aboutMeControl" matInput maxlength="250"></textarea>
            </mat-form-field>
        </form>
        <div fxLayout="row" fxLayoutAlign="center" class="actions width-100">
            <loading-button [loadingBool]="loading" [buttonClass]="'mat-flat-button'" [color]="'primary'"
                            (clickEvent)="save()" [disabledCondition]="!form.valid">
                Speichern
            </loading-button>
        </div>
    </div>
</div>
