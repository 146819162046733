<dialog-header dialogHeadline="Stelle empfehlen"></dialog-header>

<div id="dialog-content-wrapper" fxLayout="column" fxLayoutAlign="space-between start">
    <div class="recommend-info" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px" class="layout-padding-8">
        <i class="mdi mdi-information-outline"></i>
        <div>
            Hier findest du Stellen, die für dieses Talent interessant sein könnten. Such etwas Passendes heraus und verschicke eine Empfehlung! Du schaffst damit Mehrwert beim Kontakt und qualifizierst dich bei Einstellung für die Incentivierung.
        </div>
    </div>
    <div class="side-sheet-content width-100">
        <loading-spinner [loadingBool]="loading">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px" class="action-bar" [formGroup]="filterForm" *ngIf="filterForm">

            <mat-slide-toggle formControlName="origin" (change)="getMatches()">Nur Kundenstellen (Incentivierung)</mat-slide-toggle>
            <app-recommend-mlp-position-button [talentId]="talentId"></app-recommend-mlp-position-button>

        </div>
        <table mat-table [dataSource]="talentPositionsData" matSort class="width-100">

            <!-- Position Column -->
            <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Stelle</th>
                <td mat-cell *matCellDef="let element"> {{element.positionTitle}} </td>
            </ng-container>

            <!-- Company Column -->
            <ng-container matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Unternehmen</th>
                <td mat-cell *matCellDef="let element">
                    <div fxLayout="row" fxLayoutAlign="start center">
                       <partner-logo [positionOrigin]="element.positionOrigin"></partner-logo>
                        {{element.companyName}}
                    </div>
                </td>
            </ng-container>

            <!-- State Column -->
            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status</th>
                <td mat-cell *matCellDef="let element"> {{element.state | state }} </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let element">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <button mat-icon-button (click)="openPositionDetailsDialog(element.positionId)"><i
                                class="mdi mdi-eye"></i></button>

                                            <button
                                                    mat-icon-button (click)="openRecommendPositionDialog(element)"
                                                    matTooltip="Stelle empfehlen">
                                                <i class="mdi mdi-message-alert"></i></button>
                    </div>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

            <div *ngIf="!loading && !talentPositionsData.length" class="text-center grey layout-padding-24-32">Leider wurden keine passende Stellen gefunden</div>
        </loading-spinner>
    </div>

    <div class="dialog-action-bar" fxLayoutGap="10px">
        <button mat-button (click)="close()">schließen</button>
    </div>

</div>
