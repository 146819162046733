<div>
    <div class="main-header" fxLayout="column" fxLayoutAlign="star start">
        <div class="explanation">
            Deine Talente können dir auf der Talentagent-Plattform Terminanfragen schicken. Diese siehst du hier.
             Wenn du einen Terminvorschlag <strong>annimmst</strong>, erhält das Talent eine Bestätigungsmail mit der Zeit und dem Datum. Der Termin wird außerdem automatisch in ADAPT angelegt.
            Wenn du die Anfrage <strong>ablehnst</strong>, erhält das Talent eine E-Mail, dass der Termin leider nicht zustande kommt und du dich zeitnah bei ihm meldest, um einen neuen Termin auszumachen.
            Jedes Talent kann dir nur <strong>einmal</strong> eine Terminanfrage schicken.
        </div>
    </div>

    <div class="mat-elevation-z3">
        <loading-spinner [loadingBool]="loading">
            <table mat-table [dataSource]="requestData" matSort>
                <ng-container matColumnDef="picture">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <div id="profile-picture" fxLayout="row" fxLayoutAlign="start center"
                             [ngStyle]="{'background-image': element.imgUrl}">
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef> Vorname</th>
                    <td mat-cell *matCellDef="let element"> {{element.talent.firstName}} </td>
                </ng-container>

                <ng-container matColumnDef="lastName">
                    <th mat-header-cell *matHeaderCellDef> Nachname</th>
                    <td mat-cell *matCellDef="let element"> {{element.talent.lastName}} </td>
                </ng-container>

                <ng-container matColumnDef="assignDate">
                    <th mat-header-cell *matHeaderCellDef> Erhalten am</th>
                    <td mat-cell *matCellDef="let element"> {{element.requestDate | date: 'dd.MM'}} </td>
                </ng-container>

                <ng-container matColumnDef="appointmentDate">
                    <th mat-header-cell *matHeaderCellDef>Wunschtermin</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.startDate | date: 'dd.MM,  HH:mm'}} - {{element.endDate | date: ' HH:mm'}} Uhr
                    </td>
                </ng-container>

                <!-- Actions Column -->
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <div class="actions" fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="8px">
                            <loading-button
                                    [loadingBool]="finishedIds.includes(element.requestId)"
                                    tooltip="Wunschtermin bestätigen"
                                    (clickEvent)="acceptRequest(element.requestId)">
                                Termin annehmen
                            </loading-button>

                            <loading-button
                                    [withoutBackground]="true"
                                    [loadingBool]="finishedIds.includes(element.requestId)"
                                    tooltip="Wunschtermin ablehnen"
                                    (clickEvent)="denyRequest(element.requestId)">
                                Termin ablehnen
                            </loading-button>

                            <button fxLayout="row"
                                    fxLayoutAlign="center center"
                                    matTooltip="Talentprofil"
                                    mat-icon-button
                                    (click)="viewTalentProfile(element.talent.id)">
                                <i class="mdi mdi-account"></i>
                            </button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>

            <div *ngIf="!requestData.length" class="text-center layout-padding-48 grey">Du hast aktuell keine offnen Terminanfragen</div>

        </loading-spinner>
    </div>
</div>
