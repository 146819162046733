import {Inject, Injectable} from '@angular/core';
import {AccountResource, LoginLoggingResource} from "../../generated/resources";
import {interval, Subject} from "rxjs";
import {take} from 'rxjs/operators';
import {JwtHelperService} from "@auth0/angular-jwt";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../environments/environment";

const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';
const ACCOUNT_INFO = 'account_info';

export type LoginState =
    'LoginSuccessful'
    | 'LoginFailedCredentialsIncorrect'
    | 'LoginFailedRoleIncorrect'
    | 'LoginExpired'
    | 'Logout'
    | 'TokenUpdated';

@Injectable()
export class AuthService {

  private authenticatedSubject = new Subject<LoginState>();
  private tokenRefresher = interval(600 * 1000);

  constructor(
      protected http: HttpClient,
      private accountResource: AccountResource,
      private jwtHelper: JwtHelperService,
      private loginLoggingResource: LoginLoggingResource
  ) {
    this.tokenRefresher.subscribe(() => {
      this.refreshTokenIfNeeded();
    });
  }
    setToken(newToken, refreshToken) {
        localStorage.setItem(ACCESS_TOKEN, newToken);
        if (refreshToken) {
            localStorage.setItem(REFRESH_TOKEN, refreshToken);
        } else {
            localStorage.removeItem(REFRESH_TOKEN);
        }
    }

    applyLogin(loginState: LoginState) {
        this.reloadAccountInfo().then(() => {
                this.authenticatedSubject.next(loginState);
            }
        )
        return this.authenticatedSubject.asObservable();
    }

  protected getAccountInfo() {
    return JSON.parse(localStorage.getItem(ACCOUNT_INFO));
  }

  public reloadAccountInfo() {
    return this.accountResource.getAccountInfo().then((accoutInfo) => {
      localStorage.setItem(ACCOUNT_INFO, JSON.stringify(accoutInfo));
    });
  }

  public onLoginSuccess(callback: () => any) {
    this.authenticatedSubject.subscribe(
        (result) => {
          if (result == "TokenUpdated" || result == "LoginSuccessful") {
            callback()
          }
        }
    )
  }

    private getRemainingTokenLifetimeSec(accessToken: string) {
        const now = new Date();
        const remainingTokenLifetimeSec = (this.jwtHelper.getTokenExpirationDate(accessToken).getTime() - now.getTime()) / 1000;
        return remainingTokenLifetimeSec
    }
    private refreshTokenIfNeeded() {
        const refreshToken = localStorage.getItem(REFRESH_TOKEN);
        if (!refreshToken || !refreshToken.length) {
            return;
        }
        const accessToken = localStorage.getItem(ACCESS_TOKEN);

        if (accessToken == null || this.getRemainingTokenLifetimeSec(accessToken) < 60 * 60 * 24) {

            const formData = new FormData();
            formData.append('client_id', environment.frontendUnisignClientId)
            formData.append('grant_type', "refresh_token")
            formData.append('refresh_token', refreshToken)
            this.http.post(environment.uniSignUrl + '/oauth2/token', formData).subscribe(
                response => {
                    this.setToken(response['access_token'], response['refresh_token'])
                },
                error => {
                    localStorage.removeItem(refreshToken)
                }
            )

        }
    }

  logout() {
    this.clear();
    this.authenticatedSubject.next('Logout');
  }

  private clear() {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(ACCOUNT_INFO);
  }

  private isExpired() {
    try {
      let isExpired = this.jwtHelper.isTokenExpired(localStorage.getItem(ACCESS_TOKEN));

      if (isExpired) {
        this.clear();
        this.authenticatedSubject.next('LoginExpired')
      }
      return isExpired;
    } catch (e) {
      this.clear();
    }

    return true
  }

  protected isAuthenticated(): boolean {

    const token = localStorage.getItem(ACCESS_TOKEN);

    if (token == null) {
      return false;
    }

    return !this.isExpired()
  }

  protected login(email: string, password: string, role: string) {

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      })
    };

    /** cannot use URLSearchParams due to compatibility issues with IE */
    let formParamsStr = [
      "username=" + encodeURIComponent(email),
      "password=" + encodeURIComponent(password),
      "grant_type=password",
        'client_id=' + environment.frontendUnisignClientId
    ].join("&");

    this.http.post<any>(environment.uniSignUrl + '/oauth2/token', formParamsStr, httpOptions).subscribe(
        (response) => {

          /** User exists with credentials either as recruiter, consultant or talent.
           * now, we have to check if the user is attempting a login from the correct frontend
           */
          if (response.access_token != null) {
              this.loginLoggingResource.trackUserLogin({origin: "OrganicLogin"})
              this.setToken(response['access_token'], response['refresh_token']);
              this.applyLogin('LoginSuccessful')
          }
        },
        (error) => {
          this.authenticatedSubject.next("LoginFailedCredentialsIncorrect")
        }
    );

    return this.authenticatedSubject.asObservable().pipe(take(1))
  }
}
