<form [formGroup]="passwordForm">
    <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start start">

        <mat-form-field>
            <input matInput
                   type="password"
                   placeholder="Altes Passwort" required
                   formControlName="oldPassword">
        </mat-form-field>

        <mat-form-field>
            <input matInput
                   type="password"
                   placeholder="Neues Passwort" required
                   formControlName="newPassword">
        </mat-form-field>

        <mat-form-field>
            <input matInput
                   type="password"
                   placeholder="Neues Passwort Wiederholen" required
                   formControlName="newPasswordRepeat">
        </mat-form-field>
    </div>

    <div style="min-height: 20px" class="m-bottom10">
        <div class="warn" *ngIf="!passwordsEqual() && passwordForm.controls.newPasswordRepeat.dirty">
            Die Passwörter stimmen nicht überein!
        </div>
        <div class="warn" *ngIf="showIncorretPassword">
            Das alte Passwort ist nicht richtig!
        </div>
    </div>
</form>

<div fxLayout="column" fxLayoutAlign="start start">
    <div fxLayout="row" fxLayoutAlign="start start" class="m-bottom10" [ngClass]="{'green': isPasswordLongEnough(),
                   'warn': !isPasswordLongEnough()}">
        <i *ngIf="!isPasswordLongEnough()" class="mdi mdi-close-circle m-right10"></i>
        <i *ngIf="isPasswordLongEnough()" class="mdi mdi-check-circle m-right10"></i>
        <div class="font-size-14">Das Passwort muss mindestens 8 Zeichen lang sein</div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" [ngClass]="{'green': passwordRequirementsAreTrue(),
                   'warn': !passwordRequirementsAreTrue()}">
        <i *ngIf="!passwordRequirementsAreTrue()" class="mdi mdi-close-circle m-right10"></i>
        <i *ngIf="passwordRequirementsAreTrue()" class="mdi mdi-check-circle m-right10"></i>
        <div class="font-size-14">Das Passwort muss einen Kleinbuchstaben sowie eine Zahl oder einen Großbuchstaben enthalten
        </div>
    </div>

    <div class="width-100 m-top15" fxLayout="row" fxLayoutAlign="end center">
        <loading-button [loadingBool]="saving" [disabledCondition]="!isValid()" color="primary" (clickEvent)="updatePassword()">Speichern
        </loading-button>
    </div>
</div>
