import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'talent-funnel',
  templateUrl: './talent-funnel.component.html',
  styleUrls: ['./talent-funnel.component.scss']
})
export class TalentFunnelComponent implements OnInit {

  @Input("funnelState") funnelState: number

  constructor() {
  }

  ngOnInit(): void {
  }

}
