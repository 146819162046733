import {Component, OnInit} from '@angular/core';
import {SideSheetRef} from "../../utility/side-sheet/sideSheetRef";
import {FormControl, Validators} from "@angular/forms";
import {ConsultantPrimeRecommendationResource} from "../../../generated/resources";
import {ConsultantTalentPositionData} from "../../../generated/data";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-recommend-position-dialog',
  templateUrl: './recommend-position-dialog.component.html',
  styleUrls: ['./recommend-position-dialog.component.scss']
})
export class RecommendPositionDialogComponent implements OnInit {

  textControl: FormControl = new FormControl('Diese Stelle passt gut zu deinem Profil und bietet eine super Chance für dich!', [Validators.minLength(30), Validators.maxLength(150)])
  saving: boolean = false

  talentId: number
  position: ConsultantTalentPositionData

  constructor(
      private sideSheetRef: SideSheetRef,
      private consultantRecommendationResource: ConsultantPrimeRecommendationResource,
      private matSnackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }

  close(result: Boolean = false) {
    this.sideSheetRef.sheetClosed.next(result)
  }

  save() {
    if(this.saving) return

    this.saving = true
    this.consultantRecommendationResource.recommendPositionForTalent(
        this.textControl.value,
        {
          positionId: this.position.positionId,
          talentId: this.talentId
        }
    ).then(() => {
      this.matSnackBar.open("Die Stelle wurde erfolgreich empfohlen", null, {duration: 3000})
      this.close(true)
    })
  }

}
