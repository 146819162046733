import {Component, OnInit} from '@angular/core';
import {SideSheetRef} from "../../utility/side-sheet/sideSheetRef";
import {FormControl} from "@angular/forms";
import {ConsultantTalentPositionData} from "../../../generated/data";
import {ConsultantEmploymentPositionResource} from "../../../generated/resources";

@Component({
  selector: 'app-mark-prime-position-as-unsuitable-dialog',
  templateUrl: './mark-prime-position-as-unsuitable-dialog.component.html',
  styleUrls: ['./mark-prime-position-as-unsuitable-dialog.component.scss']
})
export class MarkPrimePositionAsUnsuitableDialogComponent implements OnInit {

  position: ConsultantTalentPositionData
  talentId: number

  reasonControl: FormControl = new FormControl()

  constructor(
      private sideSheetRef: SideSheetRef,
      private consultantEmploymentPositionResource: ConsultantEmploymentPositionResource
  ) { }

  ngOnInit(): void {
  }

  close(result: boolean = false) {
    this.sideSheetRef.sheetClosed.next(result)
  }

  markPrimePositionAsUnsuitable() {
    this.consultantEmploymentPositionResource.markPrimePositionAsUnsuitable(this.reasonControl.value, {
      talentId: this.talentId, employmentPositionId: this.position.positionId
        }
    ).then(result => {
      this.close(true)
    })
  }

}
