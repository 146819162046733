import {Component, Input, OnInit} from '@angular/core';
import {ConsultantTalentResource} from "../../../../generated/resources";
import {ConsultantTalentPositionData} from "../../../../generated/data";
import {environment} from "../../../../environments/environment";
import {RecommendPositionDialogComponent} from "../../recommend-position-dialog/recommend-position-dialog.component";
import {MarkPrimePositionAsUnsuitableDialogComponent} from "../../mark-prime-position-as-unsuitable-dialog/mark-prime-position-as-unsuitable-dialog.component";
import {DialogService} from "../../../utility/side-sheet/dialog.service";
import {TalentMatchesRecommendationDialogComponent} from "../../talent-matches-recommendation-dialog/talent-matches-recommendation-dialog.component";
import {RecommendationInfoDialogComponent} from "../../recommendation-info-dialog/recommendation-info-dialog.component";

@Component({
  selector: 'talent-position-proposals',
  templateUrl: './talent-position-proposals.component.html',
  styleUrls: ['./talent-position-proposals.component.scss']
})
export class TalentPositionProposalsComponent implements OnInit {

  loading: boolean = true

  @Input() talentId: number;
  public talentPositionsData: ConsultantTalentPositionData[];
  public displayedColumns: string[] = ['date', 'position', 'company', 'state', 'action'];

  constructor(
      private talentResource: ConsultantTalentResource,
      private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.loadTalentPositionsData()
  }

  loadTalentPositionsData() {
    this.loading = true
    this.talentResource.getTalentRecommendationsFromConsultants(this.talentId).then(positions => {
      this.talentPositionsData = positions;
      this.loading = false
    })
  }

  openRecommendationInfoDialog() {
   this.dialogService.openOverlay(RecommendationInfoDialogComponent, "600px")
  }

  openRecommendPositionDialog() {
    let comp = this.dialogService.openOverlay(TalentMatchesRecommendationDialogComponent, "1000px")
    comp.instance.talentId = this.talentId

    let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(result => {
      this.loadTalentPositionsData()
      subscription.unsubscribe();
    });
  }

  openPositionDetailsDialog(posId: number) {
    window.open(environment.publicFrontendUrl + '/position-details/' + posId, '_blank')
  }
}
