<div id="main-component-wrapper">
    <div class="main-header" fxLayout="row" fxLayoutAlign="space-between start">
        <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom: 16px">
            <h1 style="margin-bottom: 0px">Dashboard</h1>

            <div *ngIf="!activeSlots" class="warn m-left20">Du hast keinen freien Terminslot für deine Talente eingetragen! Ohne freien Slot können Talente keine Termine anfragen! <a style="text-decoration: underline; color: #551A8B" (click)="routeToSlots()">Jetzt eintragen!</a></div>
        </div>
    </div>

    <loading-spinner [loadingBool]="loading">
        <div class="width-100 mat-elevation-z3" style="min-height: 618px" fxLayout="row" fxLayoutAlign="start start">

        <div fxLayout="column" fxLayoutAlign="space-around start"
             style="min-height: 538px; position: relative; width: 40%" *ngIf="performanceData">

                <div class="layout-padding width-100">
                    <h2>So funktioniert Talents</h2>
                    <iframe src="https://player.vimeo.com/video/670822449?h=30c9ff15bc&badge=0&autopause=0&player_id=0&app_id=58479" style="width: 100%" frameborder="0"
                            allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>

                <div class="layout-padding width-100">
                    <app-not-rated-talents></app-not-rated-talents>
                </div>


                <div class="layout-padding width-100">
                    <h2>Incentivierungsprogramm <i class="mdi mdi-help-circle-outline" (click)="openRecommendationInfoDialog()"></i>
                    </h2>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div class="progress-bar" [ngClass]="{'full': performanceData.refinancingRate * 100 >= 10}">
                            <div class="progress-bar-overlay"
                                 [style]="'width:'+ (performanceData.refinancingRate * 100 * 10) + '%'">
                                <div>{{performanceData.refinancingRate * 100 | number : '1.0-1'}}%</div>
                            </div>
                        </div>

                        <div class="m-left20">10%</div>
                    </div>

                    <div class="explanation">
                        {{performanceData.refinancingRate * 100 | number : '1.0-1' }}% deiner Talente finden derzeit einen Job bei Talentagent-Kunden. Wenn du 10%
                        erreichst,
                        kannst du alle deine Kontakte über die Incentivierung erhalten und erschaffst dir damit ein Perpetuum Mobile für deine
                        Kontaktgewinnung. <span class="link-classic" (click)="openRecommendationInfoDialog()">Empfiehl</span> deinen Absolventen Stellen, um deine Quote zu optimieren.
                    </div>

                </div>

                <div fxLayout="column" style="padding: 16px" class="width-100">
                    <h2>Deine Performance</h2>
                    <div class="metrics">
                        <div class="performance-metric" fxLayout="row" fxLayoutAlign="space-between center"><span><i
                                class="mdi mdi-24px mdi-account-multiple"></i>
                    insgesamt betreute Talente </span><span>{{performanceData.totalTalentsAmount}}</span></div>
                        <div class="performance-metric" fxLayout="row" fxLayoutAlign="space-between center"><span> <i
                                class="mdi mdi-24px  mdi-account-multiple"></i>
                    gerade in Betreuung </span><span>{{performanceData.talentsNowInCoaching}} <span
                                class="percentage">({{((performanceData.talentsNowInCoaching / performanceData.totalTalentsAmount) || 0) * 100 | number: '1.0-0'}}
                            %)</span></span></div>
                        <div class="performance-metric" fxLayout="row" fxLayoutAlign="space-between center"><span> <i
                                class="mdi mdi-24px  mdi-file-document"></i>
                     davon mit CV  </span><span>{{performanceData.talentsWithCv}} <span
                                class="percentage">({{((performanceData.talentsWithCv / performanceData.talentsNowInCoaching) || 0) * 100 | number: '1.0-0'}}
                            %)</span></span></div>
                        <div class="performance-metric" fxLayout="row" fxLayoutAlign="space-between center"><span><i
                                class="mdi mdi-24px  mdi-file-send"></i>
                     mit Bewerbungen  </span><span>{{performanceData.talentsWithApplications}} <span
                                class="percentage">({{((performanceData.talentsWithApplications / performanceData.talentsNowInCoaching) || 0) * 100 | number: '1.0-0'}}
                            %)</span></span></div>
                        <div class="performance-metric" fxLayout="row" fxLayoutAlign="space-between center"><span><i
                                class="mdi mdi-24px  mdi-forum-outline"></i>
                    im Bewerbungsprozess </span> <span>{{performanceData.talentsInHiringProcess}} <span
                                class="percentage">({{((performanceData.talentsInHiringProcess / performanceData.talentsNowInCoaching) || 0) * 100 | number: '1.0-0'}}
                            %)</span></span></div>
                        <div class="performance-metric" fxLayout="row" fxLayoutAlign="space-between center"><span><i
                                class="mdi mdi-24px  mdi-rocket-outline"></i>
                    Job gefunden </span><span>{{performanceData.talentsJobFound}} <span
                                class="percentage">({{((performanceData.talentsJobFound / performanceData.talentsNowInCoaching) || 0) * 100 | number: '1.0-0'}}
                            %)</span></span></div>
                        <div class="performance-metric" fxLayout="row" fxLayoutAlign="space-between center"><span><i
                                class="mdi mdi-24px  mdi-rocket"></i><i class="mdi mdi-currency-eur"></i>
                    Job gefunden bei Kunden</span><span>{{performanceData.talentJobFoundCustomer}} <span
                                class="percentage">({{((performanceData.talentJobFoundCustomer / performanceData.talentsJobFound) || 0) * 100 | number: '1.0-0'}}
                            %)</span></span></div>
                    </div>

                </div>
            </div>

            <div class="layout-padding mat-elevation-z1"
                 style="background-color: #f3f3f3; width: 60%">
                <h2>Neue Talente</h2>
                <div class="width-100" style="background-color: #f3f3f3; height: 618px">
                    <div class="width-100" style="max-height: 538px; overflow-y: auto; padding: 15px 20px;">
                        <talent-card *ngFor="let talent of talentsData" [talentId]="talent"
                                     (click)="openTalentProfile(talent)"></talent-card>
                        <div *ngIf="!talentsData?.length" class="info text-center">Du hast keine neuen Talente</div>
                    </div>
                </div>
            </div>
        </div>
    </loading-spinner>

</div>
