import { Component, OnInit } from '@angular/core';
import {SideSheetRef} from "../../utility/side-sheet/sideSheetRef";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-consultant-appointment-info-dialog',
  templateUrl: './consultant-appointment-info-dialog.component.html',
  styleUrls: ['./consultant-appointment-info-dialog.component.scss']
})
export class ConsultantAppointmentInfoDialogComponent implements OnInit {

  constructor(
      private sideSheetRef: SideSheetRef,
  ) {
  }

  ngOnInit(): void {
  }

  close(result: Boolean = false) {
    this.sideSheetRef.sheetClosed.next(result)
  }

}
