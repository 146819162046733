<div id="main-component-wrapper">
    <div class="main-header" fxLayout="row" fxLayoutAlign="space-between start">
        <div fxLayout="row" fxLayoutAlign="start center">
            <h1>Talente</h1>
        </div>

        <div class="actions" fxLayout="row" fxLayoutAlign="end center">
            <button mat-flat-button
                    color="primary"
                    fxLayout="row"
                    (click)="openInviteTalentDialog()"
                    fxLayoutAlign="center center">
                <i class="mdi mdi-share-variant"></i>
                <span>Talente einladen</span>
            </button>
        </div>
    </div>

    <div class="mat-elevation-z3">
        <loading-spinner [loadingBool]="loading">
        <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 8px">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
                <mat-form-field class="m-right20">
                    <mat-label>Funnel - Status</mat-label>
                    <mat-select [formControl]="funnelStateFilterControl">
                        <mat-option [value]="null">Alle Talente</mat-option>
                        <mat-option [value]="1">Onboarding</mat-option>
                        <mat-option [value]="2 ">Lebenslauf</mat-option>
                        <mat-option [value]="3">Beworben</mat-option>
                        <mat-option [value]="4">Vermittelt</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="m-right20">
                    <mat-label>Talent - Status</mat-label>
                    <mat-select [formControl]="talentStateControl">
                        <mat-option [value]="null">Alle Talente</mat-option>
                        <mat-option [value]="true">Nur Aktive Talente</mat-option>
                        <mat-option [value]="false">Nur Inaktive Talente</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="m-right20">
                    <mat-label>Als verloren markiert</mat-label>
                    <mat-select [formControl]="markedAsLostControl">
                        <mat-option [value]="null">Alle Talente</mat-option>
                        <mat-option [value]="true">Nur verlorene Talente</mat-option>
                        <mat-option [value]="false">Nur nicht verlorene Talente</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="m-right20">
                    <mat-label>Talent bewertet</mat-label>
                    <mat-select [formControl]="talentRatedControl">
                        <mat-option [value]="null">Alle Talente</mat-option>
                        <mat-option [value]="false">Nur unbewertete Talente</mat-option>
                        <mat-option [value]="true">Nur bewertete Talente</mat-option>
                    </mat-select>
                </mat-form-field>

                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="legacy">
                        <mat-label>Talent erhalten zwischen</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate [formControl]="acquiredStartControl" placeholder="Start date">
                            <input matEndDate [formControl]="acquiredEndControl" placeholder="End date">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix
                                               [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                    <button mat-button matTooltip="Datum zurücksetzen" (click)="resetDate()"><i
                            class="mdi mdi-delete warn"></i></button>
                </div>

            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
                <button class="m-right10" mat-button (click)="loadTalents()"><i class="mdi mdi-reload primary"></i>
                </button>
            </div>

        </div>

        <table mat-table [dataSource]="talentsData" matSort>
            <ng-container matColumnDef="picture">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <div id="profile-picture" fxLayout="row" fxLayoutAlign="start center"
                         *ngIf="element.imgUrl"
                         [ngStyle]="{'background-image': element.imgUrl}">
                    </div>

                </td>
            </ng-container>

            <!-- Position Column -->
            <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef> Vorname</th>
                <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="lastName">
                <th mat-header-cell *matHeaderCellDef>
                    <span *ngIf="!mediaService.getMaxWidthQuery(1050).matches">Nachname</span>
                    <span *ngIf="mediaService.getMaxWidthQuery(1050).matches">Name</span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="!mediaService.getMaxWidthQuery(1050).matches">{{element.lastName}}</span>
                    <div fxLayout="column"
                         *ngIf="mediaService.getMaxWidthQuery(1050).matches"
                         class="full-name" [matTooltip]="element.firstName + ' ' + element.lastName">
                        <span>{{element.firstName}}</span>
                        <span>{{element.lastName}}</span>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="assignDate">
                <th mat-header-cell *matHeaderCellDef> Erhalten am</th>
                <td mat-cell *matCellDef="let element"> {{element.assignDate | date: 'dd.MM.yy'}} </td>
            </ng-container>

            <!-- Phone Column-->
            <ng-container matColumnDef="phoneNumber">
                <th mat-header-cell *matHeaderCellDef>Telefonnummer</th>
                <td mat-cell *matCellDef="let element"> {{element.phoneNumber}}</td>
            </ng-container>

            <!-- Funnel Column -->
            <ng-container matColumnDef="funnel">
                <th mat-header-cell *matHeaderCellDef>Performance</th>
                <td mat-cell *matCellDef="let element">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <talent-funnel [funnelState]="element.funnelState | funnelStateValue"></talent-funnel>
                        <div matTooltip="Das Talent erhält derzeit keine neuen Stellenvorschläge (Du kannst mit der Betreuung dennoch fortfahren)."
                             *ngIf="element.matchingState != 'Matchable'"
                             fxLayout="row" fxLayoutAlign="start center"
                             class="m-left10">
                            <i class="mdi mdi-alert warn"></i>
                        </div>

                        <div matTooltip="Das Talent wurde in Adapt als verloren markiert"
                             *ngIf="element.markedAsLost"
                             fxLayout="row" fxLayoutAlign="start center"
                             class="m-left10">
                            <i style="color: #797979" class="mdi mdi-account-alert"></i>
                        </div>

                        <div matTooltip="Du oder das Talent hat angegeben, dass das Talent eingestellt wurde"
                             *ngIf="element.talentMarkedAsHired"
                             fxLayout="row" fxLayoutAlign="start center"
                             class="m-left10">
                            <i style="color: #797979" class="mdi mdi-account-plus"></i>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="firstContact">
                <th mat-header-cell *matHeaderCellDef> Talent bewerten</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.talentRated" fxLayout="row" fxLayoutAlign="start center" class="check">
                        <i class="mdi mdi-check font-size-20"></i>
                        <div class="m-left10">Talent bewertet</div>
                    </div>

                    <div>
                        <button mat-flat-button color="primary" *ngIf="!element.talentRated" (click)="openRateTalentContactedDialog(element.id)">
                            <span>Talent bewerten</span>
                        </button>
                    </div>

                </td>
            </ng-container>


            <!-- coachingServices Column -->
            <ng-container matColumnDef="coachingServices">
                <th mat-header-cell *matHeaderCellDef>Coaching Service</th>
                <td mat-cell *matCellDef="let element">
                    <div class="actions" fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="8px">
                        <coaching-offers [coachingOffers]="element.coachingTypes"></coaching-offers>
                    </div>
                </td>
            </ng-container>

            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <div class="actions" fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="8px">
                        <button fxLayout="row"
                                fxLayoutAlign="center center"
                                matTooltip="Talentprofil"
                                mat-icon-button
                                (click)="viewTalentProfile(element.id)">
                            <i class="mdi mdi-account"></i>
                        </button>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="recommendPosition">
                <th mat-header-cell *matHeaderCellDef>MLP Beraterstelle empfehlen</th>
                <td mat-cell *matCellDef="let element">
                    <app-recommend-mlp-position-button [talentId]="element.id"
                                                       [buttonText]="'Stelle empfehlen'"
                    ></app-recommend-mlp-position-button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [length]="totalElements"
                       [pageSizeOptions]="pageSizeOptions"
                       [pageIndex]="page"
                       [pageSize]="pageSize"
                       (page)="handlePage($event)"
                       showFirstLastButtons>
        </mat-paginator>
        </loading-spinner>
    </div>
</div>
