import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'partner-logo',
  templateUrl: './partner-logo.component.html',
  styleUrls: ['./partner-logo.component.scss']
})
export class PartnerLogoComponent implements OnInit {

  @Input() positionOrigin: string

  constructor() { }

  ngOnInit(): void {
  }

}
