import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Subject} from "rxjs";

@Component({
  selector: 'date-time-select',
  templateUrl: './date-time-select.component.html',
  styleUrls: ['./date-time-select.component.scss']
})
export class DateTimeSelectComponent implements OnInit {

  dateTimeFormGroup: FormGroup =  new FormGroup({
    date: new FormControl(Date()),
    innerHourControl: new FormControl(0),
    innerMinuteControl: new FormControl(0)
  });
  initializeWithoutValue = false;

  @Input() control: AbstractControl;

  @Input() placeholder: string = 'Wähle ein Datum';
  @Input() minDate: Date = new Date(1970, 0, 1)
  @Input() maxDate: Date = new Date(2120, 0, 1)
  @Input() externalValueUpdate: Subject<Date> = new Subject<Date>()



  @ViewChild('minuteInput') minuteInput: ElementRef;
  @ViewChild('hourInput') hourInput: ElementRef;


  hourAndMinuteStartValue: { hour: number, minute: number } = {hour: null, minute: null};

  constructor(
      private fb: FormBuilder
  ) {
  }

  ngOnInit() {
    this.initializeForm()
  }

  initializeForm() {

    this.dateTimeFormGroup = this.fb.group({
      innerDateControl: new FormControl({value: this.control.value, disabled: this.control.disabled}, [Validators.required]),
      innerMinuteControl: new FormControl({
        value: null,
        disabled: this.control.disabled
      }, [Validators.pattern('^[0-9]{2}'), Validators.required]),
      innerHourControl: new FormControl({
        value: null,
        disabled: this.control.disabled
      }, [Validators.pattern('^[0-9]{2}'), Validators.required])
    });

    if(this.control.value) {
      let bla = new Date(this.control.value)
      this.dateTimeFormGroup.controls['innerHourControl'].setValue(bla.getHours());
      this.dateTimeFormGroup.controls['innerMinuteControl'].setValue(bla.getMinutes());
    }
    this.reformatAllInputs();
    this.subscribeOnChanges()
  }

  subscribeOnChanges() {
    this.dateTimeFormGroup.controls['innerDateControl'].valueChanges.subscribe(value => {
      let newDate = new Date(value)
      newDate.setHours(this.dateTimeFormGroup.controls.innerHourControl.value);
      newDate.setMinutes(this.dateTimeFormGroup.controls.innerMinuteControl.value);
      this.control.setValue(newDate)

    })

    this.dateTimeFormGroup.controls['innerHourControl'].valueChanges.subscribe(value => {
      if (value == '' || value == "00") value = 0
      if (value > 23) {
        this.dateTimeFormGroup.controls['innerHourControl'].setValue(23)
        return
      }

      let currentDate = new Date(this.control.value);
      currentDate.setHours(value)
      this.control.setValue(currentDate)
      if (value.toString().length == 2) this.minuteInput.nativeElement.focus()
    })

    this.dateTimeFormGroup.controls['innerMinuteControl'].valueChanges.subscribe(value => {
      if (value == '' || value == "00") value = 0
      if (value > 59) {
        this.dateTimeFormGroup.controls['innerMinuteControl'].setValue(59)
        return
      }
      let currentDate = new Date(this.control.value);
      currentDate.setMinutes(value)
      this.control.setValue(currentDate)
    })

    this.externalValueUpdate.subscribe( newDate => {
      this.dateTimeFormGroup.controls.innerDateControl.setValue(newDate, {emitEvent: false})
      this.dateTimeFormGroup.controls.innerHourControl.setValue(newDate.getHours(), {emitEvent: false})
      this.dateTimeFormGroup.controls.innerMinuteControl.setValue(newDate.getMinutes(), {emitEvent: false})
      this.reformatAllInputs();
      this.control.setValue(newDate)

    })
  }

  reformatAllInputs(){
    this.reformatHourInput();
    this.reformatMinuteInput();
  }

  reformatHourInput() {
    this.reformatControl(this.dateTimeFormGroup.controls.innerHourControl)
  }

  reformatMinuteInput() {
    this.reformatControl(this.dateTimeFormGroup.controls.innerMinuteControl)
  }

  reformatControl(control: AbstractControl){
    if(!control.value || control.value.toString().length == 0){
      control.setValue("0" + 0)
      return
    }
    if (control.value.toString().length < 2) {
      control.setValue("0" + control.value)
      return
    }
    if(control.value.toString().length > 2){
      control.setValue(control.value.toString().substring(1))
    }
  }

  ifEmptyFocusHourInput() {
      if (this.dateTimeFormGroup.controls.innerMinuteControl.value?.toString().length <= 0 || this.dateTimeFormGroup.controls.innerMinuteControl.value == null) {
        this.hourInput.nativeElement.focus()
      }
  }

  clearMinutes(){
    this.dateTimeFormGroup.controls.innerMinuteControl.setValue('')
  }
  clearHours(){
    this.dateTimeFormGroup.controls.innerHourControl.setValue('')
  }
}
