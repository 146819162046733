import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'eventLog'
})
export class EventLogPipe implements PipeTransform {

  transform(log: any, positionName: string): any {
    switch (log) {
      case 'TalentCreation':
        return 'Anmeldung'
      case 'TalentAgentRequestedLogEntry':
      case 'TalentCoachRequestedLogEntry':
        return 'Karrierecoach angefordert'
      case 'CoachingMarkedAsLostLogEntry':
        return 'In Adapt als verloren markiert'
      case 'TalentAgentAssignedLogEntry':
      case 'TalentCoachAssignedLogEntry':
        return 'Karrierecoach zugewiesen'
      case 'TalentCvUploadLogEntry':
        return 'Lebenslauf hochgeladen'
      case 'TalentAccountReady':
        return 'User ist Login-Fähig'
      case 'TalentFirstContactWithCoachLogEntry':
      case 'TalentFirstContactWithCoachImpossibleLogEntry':
      case 'TalentCoachAssessTalentLogEntry':
        return 'Talent bewertet'
      case 'ApplicationSentLogEntry':
        if(positionName != null && positionName != '') return `Bewerbung abgeschickt (${positionName})`;
        else return 'Bewerbung abgeschickt'
      case 'ApplicationHiredLogEntry':
        if(positionName != null && positionName != '') return `Einstellung als (${positionName})`;
        else return 'Eingestellt'
      case 'ApplicationFailedLogEntry':
      case 'ApplicationBlockedByAdmin':
        if(positionName != null && positionName != '') return `Bewerbung gescheitert (${positionName})`;
        else return 'Bewerbung gescheitert'
      case 'ApplicationWithdrawnLogEntry':
        if(positionName != null && positionName != '') return `Bewerbung zurückgezogen (${positionName})`;
        else return 'Bewerbung zurückgezogen'
      default: return log
    }
  }
}
