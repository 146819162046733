import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TalentSearchbarService {

  openSearchbar: Subject<boolean> = new Subject<boolean>()
  openChatWithTalentId: Subject<number> = new Subject<number>()

  constructor() { }
}
