import {Component, OnInit} from '@angular/core';
import {ConsultantAuthService} from "../service/consultant.auth.service";
import {Router} from "@angular/router";
import {ConsultantAccountResource} from "../../generated/resources";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-privacy-agreement',
  templateUrl: './privacy-agreement.component.html',
  styleUrls: ['./privacy-agreement.component.scss']
})
export class PrivacyAgreementComponent implements OnInit {

  constructor(
      private consultantAccountResource: ConsultantAccountResource,
      private authService: ConsultantAuthService,
      private router: Router
  ) {
  }

  ngOnInit(): void {
  }

  acceptPrivacyAgreement() {
    this.consultantAccountResource.acceptPrivacyAgreement().then(() => {
      this.router.navigate(['dashboard'])
    })
  }

  logout() {
    this.authService.logout()
    this.router.navigate(['login'])
  }
}
