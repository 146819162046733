import {Component, Input, OnInit} from '@angular/core';
import {ApplicationData} from "../../../../generated/data";
import {ConsultantTalentResource} from "../../../../generated/resources";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'talent-applications',
  templateUrl: './talent-applications.component.html',
  styleUrls: ['./talent-applications.component.scss']
})
export class TalentApplicationsComponent implements OnInit {


  @Input() talentId: number;

  loadingApplications: boolean = true
  public applicationsData: ApplicationData[];
  public displayedColumns: string[] = ['date', 'position', 'company', 'state', 'action'];

  constructor(
      private talentResource: ConsultantTalentResource
  ) { }

  ngOnInit(): void {
    this.loadTalentPositionsData()
  }

  loadTalentPositionsData() {
    this.loadingApplications = true
    this.talentResource.getTalentApplications(this.talentId).then(positions => {
      this.applicationsData = positions.content;
      this.loadingApplications = false
    })
  }

  openPositionDetailsDialog(posId: number) {
    window.open(environment.publicFrontendUrl + '/position-details/' + posId, '_blank')
  }
}
