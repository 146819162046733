<dialog-header dialogHeadline="Terminanfragen in TALENTS"></dialog-header>
<div id="dialog-content-wrapper" fxLayout="column" fxLayoutAlign="space-between start">
    <div class="dialog-main-content">
        <iframe src="https://player.vimeo.com/video/670831037?h=bde487b6dc&badge=0&autopause=0&player_id=0&app_id=58479" width="600" height="300" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

    </div>

    <div class="dialog-action-bar" fxLayoutGap="10px">
        <button mat-button (click)="close()">schließen</button>
    </div>
</div>
