<div fxLayout="row" fxLayoutAlign="start end" class="message-wrapper width-100">

    <div *ngIf="messageData.sender.id != consultantId"
         class="width-10"
         id="profile-picture"
         fxLayout="row"
         fxLayoutAlign="center center"
         [ngStyle]="{'background-image': profilePictureUrl}">
    </div>
    <div *ngIf="messageData.sender.id != consultantId"
         fxLayout="column" fxLayoutAlign="end center">
        <div class="tail-left"></div>
    </div>
    <div [ngClass]="{'consultant': messageData.sender.id == consultantId}"
         class="message width-100"
         fxLayout="column" fxLayoutAlign="space-between start">
        <div class="text"
             fxLayout="row" fxLayoutAlign="stretch">
            {{messageData.text}}
        </div>
        <div class="width-100" fxLayout="row" fxLayoutAlign="end baseline">
            <div [ngClass]="{'time-consultant': messageData.sender.id == consultantId}"
                 class="time">{{messageData.occurred | date: 'HH:mm'}}</div>
        </div>
    </div>
</div>