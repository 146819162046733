import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'relationState'
})
export class RelationStatePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {
      case 'New':
        return 'Neu';
      case 'Seen':
        return 'Gesehen';
      case 'Clicked':
        return 'Geklickt';
      case 'Bookmarked':
        return 'Auf Merkliste';
      case 'CTAClicked':
        return 'CTA geklickt';
      case 'Rejected':
        return 'Aussortiert';
      case 'Withdrawn':
        return 'Zurückgezogen';
      case 'Accepted':
        return 'Angenommen';
      default: return value
    }
  }

}
