import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MatHorizontalStepper} from "@angular/material/stepper";
import {Router} from "@angular/router";
import {AccountResource as UniSignAccountResource,} from '../../generated/unisign/resources';
import {ConsultantAccountResource} from "../../generated/resources";
import {UtilityService} from "../service/utility-service";

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {

  passwordForm: FormGroup
  downloading: boolean = false

  constructor(
      private fb: FormBuilder,
      private router: Router,
      private accountRessource: UniSignAccountResource,
      private consultantAccountResource: ConsultantAccountResource,
      private utilityService: UtilityService
  ) { }

  @ViewChild('stepper') stepper: MatHorizontalStepper

  ngOnInit(): void {
    this.createPasswordForm()
  }


  createPasswordForm() {
    this.passwordForm = this.fb.group({
      newPassword: new FormControl(null, [Validators.required, (c) => this.requirementsPasswordMatched(c.value, 2, 8)]),
      newPasswordRepeat: new FormControl(null, [Validators.required, (c) => this.requirementsPasswordMatched(c.value, 2, 8)])
    })
  }

  isPasswordLongEnough(): boolean {
    return (this.passwordForm.controls.newPassword.value && this.passwordForm.controls.newPassword.value.length > 7)
  }

    requirementsPasswordMatched(password: string, minScore: number = 3, minlength: number = 8) {
        if (password == null) {
            return {passwordEmpty: true};
        }
        if (password.length < minlength) {
            return {passwordTooShort: true};
        }

        let securityScore = 0;

        if (/[a-z]/.test(password)) securityScore++; // at least one lowercase letter
        if (/[A-Z]/.test(password) || /[0-9]/.test(password)) securityScore++; // at least one uppercase letter
        // if (/[^A-Za-z0-9]/.test(password)) securityScore++; // at least one special char

        if (securityScore < minScore) {
            return {securityRequirementsNotMatched: true};
        }

        return null;
    }

  passwordRequirementsAreTrue() {
    let boolArray = [
      this.containsSmallLetter(),
        this.containsBigLetter() || this.containsNumber(),
    ]
    return boolArray.filter(bool => bool == true).length > 1
  }

  containsSmallLetter(): boolean {
    if (this.passwordForm.controls.newPassword.value == null) return false
    return (/[a-z]/.test(this.passwordForm.controls.newPassword.value));
  }

  containsBigLetter() {
    if (this.passwordForm.controls.newPassword.value == null) return false
    return (/[A-Z]/.test(this.passwordForm.controls.newPassword.value));
  }

  containsNumber() {
    if (this.passwordForm.controls.newPassword.value == null) return false
    return (/[0-9]/.test(this.passwordForm.controls.newPassword.value));
  }

  containsSpecialCharacter() {
    if (this.passwordForm.controls.newPassword.value == null) return false
    return (/[!@#$%^&*(),.?":{}|<>+§=\-]/.test(this.passwordForm.controls.newPassword.value));
  }

  passwordsEqual(): boolean {
    return this.passwordForm.controls.newPassword.value == this.passwordForm.controls.newPasswordRepeat.value
  }

  isPasswordValid() {
    return (this.passwordForm.valid && this.passwordsEqual() && this.passwordRequirementsAreTrue())
  }

  getNextButtonLabel(): string {
    if (!this.stepper) return ''

    switch (this.stepper.selectedIndex) {
      case 5: return 'AKZEPTIEREN'
      case (this.stepper.steps.length-1): return 'ZUM PROFIL'
      default : return 'WEITER'
    }
  }

  performNextButtonAction() {
    switch (this.stepper.selectedIndex) {
      case 4: {
        this.updatePassword()
        this.nextStep()
      }
      break;

      case 5: {
        this.acceptPrivacy()
        this.nextStep()
      }
      break;

      case (this.stepper.steps.length-1): {
        this.updateOnboardingCompleted()
        this.router.navigateByUrl('/profile')
      }
        break;


      default : this.nextStep()
    }
  }

  updatePassword() {
    this.accountRessource.changeOwnPassword({newPassword: this.passwordForm.controls.newPassword.value}).then(result => {
      this.utilityService.showSnackBar('Gespeichert!', 'top')
    })
  }

  downloadStarterKit() {
      this.downloading = true;
      window.open('../../assets/files/Starter-Kit_TalentagentxMLP_22Feb.pdf', '_blank');
      this.downloading = false;
  }

  isNextDisabled(): boolean {
    if (!this.stepper) return false

    return (this.stepper.selectedIndex == 4 && !this.isPasswordValid());
  }

  updateOnboardingCompleted() {
    this.consultantAccountResource.completedOnboarding()
  }

  acceptPrivacy() {
      this.consultantAccountResource.acceptPrivacyAgreement().then(result => {
        this.utilityService.showSnackBar('Gespeichert!', 'top')
      })
  }

  nextStep() {
    this.stepper.next()
  }
}
