import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UnisignService {


    forgotPassword: string = "public/forgot-password"
    resetPassword: string = "public/reset-password"


    constructor() { }

    routeToForgotPassword() {
        window.open(environment.uniSignUrl + "/" + this.forgotPassword + "?clientId=" + environment.frontendUnisignClientId, "_blank")
    }
}
