<dialog-header [dialogHeadline]="data.title"></dialog-header>
<div id="dialog-content-wrapper" fxLayout="column" fxLayoutAlign="space-between start">

    <div class="dialog-main-content layout-padding">
        <p class="message">{{data.message}}</p>
        <p class="description">{{data.description}}</p>
    </div>

    <div class="dialog-action-bar" fxLayoutGap="10px">
        <button mat-flat-button (click)="close()">Abbrechen</button>
        <loading-button [withoutBackground]="false"
                        buttonClass="mat-primary"
                        [color]="data.confirmButtonColor"
                        (clickEvent)="confirm()"
                        [loadingBool]="confirming">{{data.confirmButtonText}}</loading-button>
    </div>
</div>

