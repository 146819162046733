import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import { ConsultantAuthService } from './consultant.auth.service';
@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
      public authService: ConsultantAuthService,
      public router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.authService.isAuthenticated()) return true;
    this.router.navigate(['/login']);
    return false;
  }
}
