<div class="headline" fxLayout="row" fxLayoutAlign="space-between center">

    <div style="width: 40px; height: 40px"></div>

    <div fxLayout="row" fxLayoutAlign="center center">
        <h1>{{dialogHeadline}}</h1>
        <ng-content></ng-content>
    </div>


    <button side-sheet-back-arrow-button mat-icon-button>
        <mat-icon>close</mat-icon>
    </button>
</div>
