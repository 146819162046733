import { Component, OnInit } from '@angular/core';
import {
  AecdiscInformationDialogComponent
} from "../dialog-sheets/aecdisc-information-dialog/aecdisc-information-dialog.component";
import {DialogService} from "../utility/side-sheet/dialog.service";
import {
  ConsultantAppointmentInfoDialogComponent
} from "../dialog-sheets/consultant-appointment-info-dialog/consultant-appointment-info-dialog.component";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-consultant-appointments',
  templateUrl: './consultant-appointments.component.html',
  styleUrls: ['./consultant-appointments.component.scss']
})
export class ConsultantAppointmentsComponent implements OnInit {

  tabIndex: number = 0

  constructor(
      private dialogService: DialogService,
      private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if (this.route.snapshot.queryParams['tab']) {
      this.tabIndex = parseInt(this.route.snapshot.queryParams['tab']);
    }
  }

  openAppointentTutorialVideo() {
    this.dialogService.openOverlay(ConsultantAppointmentInfoDialogComponent, '600px');
  }

}
