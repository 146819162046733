import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class GeneralUtilsService {

  constructor(
      private snackBar: MatSnackBar,
  ) {
  }

  copyToClipboard(value) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.showSnackBar('In Zwischenablage kopiert.');
  }

  showSnackBar(message: string) {
    this.snackBar.open(message, null, {duration: 3000});
  }
}
