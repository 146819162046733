import {Inject, Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {saveAs} from 'file-saver';
import {environment} from '../../environments/environment';
import {AbstractControl, ValidatorFn} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class FileService {

  whiteListedExtensions = ["image/jpg", "image/jpeg", "image/gif", "image/png", "jpg", "jpeg", "png", "gif"];

  private apiUrl: string;

  constructor(
      @Inject('env') private env,
      private http: HttpClient
  ) {
  }

  uploadFileControlValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value || !control.value[0]) return {isNull: true};

      if (control.value[0].size >= 3000000) return {sizeTooBig: true};
      if (control.value[0].size == 0) return {fileIsEmpty: true};

      if (!this.whiteListedExtensions.some((extension, index, number) => {
        return control.value[0].name.includes(extension)
      })) {
        return {wrongExtension: true};
      }

      return null;
    }
  }

  getFileNameFromHeaders(headers: HttpHeaders): string {
      let contentDisposition = headers.get("Content-Disposition");
      if (!contentDisposition) return "cv.pdf";
      return headers.get("Content-Disposition").split(";")[1].trim().split("=")[1];
  }

  uploadConsultantProfilePicture(imageFile: File): Observable<Object> {
    const params = new HttpParams().set('fileName', imageFile.name);

    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream'
      }),
      params: params
    };

    return this.http.post(environment.apiUrl + '/consultants/me/profilePicture', imageFile, options);
  }

  getConsultantProfilePicture(): Observable<any> {
    return this.http.get(environment.apiUrl + `/consultants/me/profilePicture`, {
      headers: new HttpHeaders(),
      responseType: 'blob'
    });
  }

  downloadCv(talentId: number) {

    let requestParams = "";

    this.http.get(`${this.env.apiUrl}/consultant/talents/${talentId}/cv` + requestParams, {
      headers: new HttpHeaders(),
      responseType: 'arraybuffer',
      observe: 'response'
    }).subscribe((response: HttpResponse<ArrayBuffer>) => {

      let blob = new Blob([response.body]);

      if (blob.size > 0) {
        saveAs(blob, this.getFileNameFromHeaders(response.headers));
      }
    });
  }

  downloadEvaluationResult(talentId: number) {
    this.http.get(`${this.env.apiUrl}/consultant/talents/${talentId}/evaluation/result`, {
      headers: new HttpHeaders(),
      responseType: 'arraybuffer',
      observe: 'response'
    }).subscribe((response: HttpResponse<ArrayBuffer>) => {

      let blob = new Blob([response.body]);

      if (blob.size > 0) {
        saveAs(blob, this.getFileNameFromHeaders(response.headers));
      }
    });
  }

  getEvaluationResult(talentId: number): Observable<Blob> {

    let subject = new Subject<Blob>();

    this.http.get(`${this.env.apiUrl}/consultant/talents/${talentId}/evaluation/result`, {
      headers: new HttpHeaders(),
      responseType: 'blob',
      observe: 'response'
    }).subscribe((response: HttpResponse<Blob>) => {
      subject.next(response.body)
    });

    return subject.asObservable()
  }

  getCV(talentId: number): Observable<Blob> {

    let subject = new Subject<Blob>();

    this.http.get(environment.apiUrl + '/talentProfile/CurriculumVitae/' + talentId, {
      headers: new HttpHeaders(),
      responseType: 'blob',
      observe: 'response'
    }).subscribe((response: HttpResponse<Blob>) => {
      subject.next(response.body)
    });

    return subject.asObservable()
  }
}
