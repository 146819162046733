import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {AccountRegistrationSuccessData, AccountRegistrationData, AccountAvailabilityData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.unisign.api.PublicController
 */

@Injectable()
@ResourceParams({})
export class PublicResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/available',
        endpoint: 'com.uniwunder.unisign'
    })
    private _getEmailAvailableForRegistration: IResourceMethodObservableStrict<null, {email: string}, null, AccountAvailabilityData> | undefined
    getEmailAvailableForRegistration(requestParams: {email: string}): Promise<AccountAvailabilityData> {
        if (!this._getEmailAvailableForRegistration) throw new Error("resource has not been properly initialized")
        return this._getEmailAvailableForRegistration(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/public/register',
        endpoint: 'com.uniwunder.unisign'
    })
    private _registerNewAccount: IResourceMethodObservableStrict<AccountRegistrationData, {client_id: string}, null, AccountRegistrationSuccessData> | undefined
    registerNewAccount(requestBody: AccountRegistrationData, requestParams: {client_id: string}): Promise<AccountRegistrationSuccessData> {
        if (!this._registerNewAccount) throw new Error("resource has not been properly initialized")
        return this._registerNewAccount(requestBody, requestParams, null).toPromise()
    }

}