<div fxLayout="column" fxLayoutAlign="start start" class="not-found">

    <img src="assets/images/404.jpg" width="1920">

    <div class="not-found-content-container" fxLayout="row" fxLayoutAlign="center center">
        <div class="not-found-content" fxLayout="column" fxLayoutAlign="center center">
            <div class="title-404">404</div>
            <div class="title">Hier finden Sie leider keine Talente!</div>
            <div class="sub-title">
                Die von Ihnen angeforderte Seite, gibt es leider nicht.
            </div>
        </div>
    </div>
</div>
