<dialog-header dialogHeadline="Der AECdisc® in Talentagent"></dialog-header>
<div id="dialog-content-wrapper" fxLayout="column" fxLayoutAlign="space-between start">

    <div class="dialog-main-content">

        <iframe src="https://player.vimeo.com/video/537765079" width="600" height="300" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>




        <div class="layout-padding">
            Die AECdisc® Potentialanalyse kann jedem deiner Schützlinge bereitgestellt werden. Bitte beachte, dass die Auswertung gemeinsam mit einem geschulten Berater erfolgen muss. <br>Solltest du die Schulung nicht haben, kann einer deiner Kollegen oder dein LHT die Auswertung mit deinem Talent in deinem Namen durchführen. Mehr Informationen zum Test gibt es <a href="https://www.div-institut.de/potenzialanalyse/" target="_blank">hier</a><br><br>
            <b>Der Ablauf ist wie folgt:</b>
            <ul class="layout-padding">
                <li>Du forderst den Code an. Das Talent erhält eine automatische Email mit dem Zugangscode und kann den Test selbst durchführen</li>
                <li>Sobald das Testergebnis da ist, kannst du es hier in TALENTS abrufen. Wir benachrichtigen dich. Das Talent selbst kann das Ergebnis nicht ohne deine Unterstützung sehen</li>
                <li>Du kannst das Testergebnis für das Talent freischalten. Danach kann das Talent selbst die Testergebnise im Bereich "Karrierecoach" herunterladen. Bitte führe die Freischaltung spätestens nach dem gemeinsamen Termin durch.</li>
            </ul>
        </div>
    </div>

    <div class="dialog-action-bar" fxLayoutGap="10px">
        <button mat-button (click)="close()">schließen</button>
    </div>
</div>
