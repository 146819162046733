import {Component, Input, OnInit} from '@angular/core';
import {CoachingTypeData} from "../../../generated/data";

@Component({
  selector: 'coaching-offers',
  templateUrl: './coaching-offers.component.html',
  styleUrls: ['./coaching-offers.component.scss']
})
export class CoachingOffersComponent implements OnInit {

    @Input() coachingOffers: CoachingTypeData[] = []

    offerIds: number[]

  constructor() {

  }

  ngOnInit(): void {
      this.offerIds = this.coachingOffers.map(it => it.id)
  }

}
