import { Component, OnInit } from '@angular/core';
import {ConsultantTalentMinimalData, JsonWrappedValue} from "../../../generated/data";
import {SideSheetRef} from "../../utility/side-sheet/sideSheetRef";
import {GeneralUtilsService} from "../../service/general-utils.service";
import {ConsultantTalentResource} from "../../../generated/resources";

@Component({
  selector: 'app-support-dialog',
  templateUrl: './support-dialog.component.html',
  styleUrls: ['./support-dialog.component.scss']
})
export class SupportDialogComponent implements OnInit {

  downloading: boolean = false

  constructor(
      private sideSheetRef: SideSheetRef,
  ) { }

  ngOnInit(): void {

  }

  downloadStarterKit() {
    this.downloading = true;
    window.open('../../assets/files/StarterKit_Talentagent_210128.pdf', '_blank');
    this.downloading = false;
  }

  close(result: boolean = false) {
    this.sideSheetRef.sheetClosed.next(result);
  }


}
