<loading-spinner [loadingBool]="loadingNotifications">
    <div class="notifications-setting" fxLayout="row" fxLayoutAlign="space-between center"
         [ngClass]="{'loading' : loadingNotifications}">
        <button mat-button class="actions info" routerLink="/notifications">
            Benachrichtigungen
        </button>
        <button mat-button class="actions info" routerLink="/settings/notifications">
            <i class="mdi mdi-settings info"></i>
        </button>
    </div>

    <div *ngIf="notifications?.length <= 0" class="layout-padding info">
        Keine neuen Benachrichtigungen vorhanden
    </div>

    <div class="notification-items"
         #notificationItems
         *ngIf="notifications && notifications && notifications.length > 0">
        <div fxLayout="row" fxLayoutAlign="space-between center"
             [ngClass]="{'new-notification': notification.unseen}"
             (click)="notificationService.performAppropriateClickAction(notification)"
             *ngFor="let notification of notifications; let i = index">
            <div class="width-100">
                <div fxLayout="row" fxLayoutAlign="space-between start">
                    <div>{{(notification.type | consultantNotification)? (notification.type | consultantNotification) : notification.shortMessage}}</div>
                    <div class="notification-time">{{this.getCreationDateString(notification.creationDate)}}</div>
                </div>
                <div class="hint">{{notification.shortMessage | sliceString:100}}</div>
            </div>
        </div>
    </div>
    <button mat-button class="actions info all-notifications" routerLink="/notifications">
        Alle Benachrichtigungen
    </button>
</loading-spinner>
