import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  ChatData,
  ChatMessageData,
  ChatMinimalData,
  ConsultantTalentProfileData, LabelData,
} from "../../generated/data";
import {ConsultantChatResource, ConsultantTalentResource} from "../../generated/resources";
import {ConsultantAuthService} from "../service/consultant.auth.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {TalentSearchbarService} from "../service/talent-searchbar.service";
import {ChatService} from "../service/chat.service";

@Component({
  selector: 'app-chat-overview',
  templateUrl: './chat-overview.component.html',
  styleUrls: ['./chat-overview.component.scss']
})
export class ChatOverviewComponent implements OnInit, OnDestroy {

  chatMinimalData: ChatMinimalData[]
  chatData: ChatData
  // $unseenChatMessages: BehaviorSubject<ChatMessageData[]> = new BehaviorSubject<ChatMessageData[]>(null)

  minimalChatsLoading: boolean = true
  chatLoading: boolean = true

  consultantId: number
  selectedChatId: number
  talent: ConsultantTalentProfileData

  appComponentSubscription: Subscription
  refreshSubscription: Subscription

  constructor(
      private chatResource: ConsultantChatResource,
      private authService: ConsultantAuthService,
      private talentResource: ConsultantTalentResource,
      private talentSearchbarService: TalentSearchbarService,
      public chatService: ChatService
  ) {
    this.appComponentSubscription = this.talentSearchbarService.openChatWithTalentId.subscribe(next => {
      this.loadPartnerAndChatContent(next)
    })

    this.refreshSubscription = this.chatService.refreshChats.subscribe(() => {
      this.minimalChatsLoading = true
      this.loadMinimalChats()
    })
  }

  ngOnDestroy(): void {
    this.appComponentSubscription?.unsubscribe()
    this.refreshSubscription?.unsubscribe()
    }

  ngOnInit(): void {
    this.loadMinimalChats()
    this.consultantId = this.authService.getAccountInfo().id
  }

  loadChatPartner(talentId: number): Promise<any> {
       return  this.talentResource.getTalent(talentId).then(result => {
          this.talent = result
        })
  }

  loadMinimalChats(): void {
    this.chatResource.getChatMinimalDataForOverview().then(result => {
      this.chatMinimalData = result
      this.minimalChatsLoading = false


      //return if no chat was found
      if (this.chatMinimalData?.length == 0) {
        this.chatLoading = false
        return
      }

      //set first Chat seen after 3 seconds if chat was new
      setTimeout(() => {
        this.chatMinimalData[0].existsUnseen = false
      },3000)

      this.selectedChatId = this.chatMinimalData[0].id
      this.loadPartnerAndChatContent(
          this.chatService.getChatPartnerByChat(this.chatMinimalData[0]).id)
    })
  }

  loadChatContent(talentId: number): Promise<any> {
    this.chatLoading = true
    return this.chatResource.getChatForTalent(talentId).then(result => {
      this.selectedChatId = result?.id
      this.chatData = result
      this.chatLoading = false
      if (!result?.id) this.createDummyChat()
    })
  }

  loadPartnerAndChatContent(talentId: number): void {
    this.chatLoading = true
    this.loadChatPartner(talentId).then( () => {
      this.loadChatContent(talentId)
    })
  }

  createDummyChat() {
    let newChatData = this.chatService.createDummyChat(this.talent)
    let newMinimalChat = this.chatService.createDummyMinimalChat(this.talent)

    //removes top chat if user clicks create new chat twice
    if (this.chatMinimalData.length != 0 && this.chatMinimalData[0].id == null) this.chatMinimalData.shift()

    this.chatMinimalData.unshift(newMinimalChat)
    this.chatData = newChatData
    this.chatLoading = false
  }

  openTalentSearchbar(): void {
    this.talentSearchbarService.openSearchbar.next(true)
  }

  // loadUnseenMessagesOfChat(id: number): void {
  //
  //   if(this.chatMinimalData[0].id == null) this.chatMinimalData.shift()
  //
  //   this.loadChatData(id)
  // }

}
