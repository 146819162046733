import { Component, OnInit } from '@angular/core';
import {SideSheetRef} from "../../utility/side-sheet/sideSheetRef";
import {FormControl, Validators} from "@angular/forms";
import {ConsultantCoachingResource, ConsultantTalentResource} from "../../../generated/resources";
import { ConsultantMinimalApplicationData } from 'src/generated/data/consultant-minimal-application-data';

@Component({
  selector: 'app-mark-talent-as-hired-dialog',
  templateUrl: './mark-talent-as-hired-dialog.component.html',
  styleUrls: ['./mark-talent-as-hired-dialog.component.scss']
})
export class MarkTalentAsHiredDialogComponent implements OnInit {

  //input
  talentId: number

  applicationsLoaded: boolean = false

  applicationCtr: FormControl = new FormControl(null, Validators.required)
  applications: ConsultantMinimalApplicationData[] = []

  constructor(
      private sideSheetRef: SideSheetRef,
      private consultantTalentResource: ConsultantTalentResource,
      private consultantCoachingResource: ConsultantCoachingResource
  ) {
  }

  ngOnInit(): void {
    this.getSuitableTalentApplications()
  }

  getSuitableTalentApplications() {
    this.applicationsLoaded = false
    this.consultantTalentResource.getMarkableAsHiredTalentApplications(this.talentId).then(result => {
      this.applications = result
      this.applicationsLoaded = true
    })
  }

  markTalentAsHired() {
    this.consultantCoachingResource.markTalentAsHired(this.applicationCtr.value.id).then(result => {
      this.close(true)
    })
  }

  close(result: boolean = false) {
    this.sideSheetRef.sheetClosed.next(result);
  }
}

