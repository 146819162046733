<dialog-header dialogHeadline="Stelle passt nicht zu Talent"></dialog-header>
<div id="dialog-content-wrapper" fxLayout="column" fxLayoutAlign="space-between start">

    <div class="side-sheet-content layout-padding">
        <div>Du kannst die Stelle <b>"{{position?.positionTitle}}"</b> für das Talent als unpassend Markieren. Bitte verwende diese Funktion nur, wenn du
        dir auch wirklich sicher bist, dass das Talent kein Interesse an der Stelle hat.</div>

        <div>Gib bitte eine Begründung an, warum du dir sicher bist, dass das Talent diese Stelle nicht möchte.</div>

        <mat-form-field class="width-100 m-top20">
            <input matInput required
                   [formControl]="reasonControl"
                   placeholder="Begründung">
        </mat-form-field>

    </div>

    <div class="dialog-action-bar" fxLayoutGap="10px">
        <button mat-button (click)="close()">schließen</button>
        <button mat-flat-button color="primary" [disabled]="!reasonControl.valid" mat-button (click)="markPrimePositionAsUnsuitable()">Als upassend markieren</button>
    </div>
</div>
