<div class="width-100 height-100" fxLayout="row" fxLayoutAlign="center center">
    <div class="height-100 width-100" fxLayout="column" fxLayoutAlign="space-between">
        <div *ngIf="chatInOverview" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="chat-title" fxLayout="row" fxLayoutAlign="start center">

                <div id="profile-picture" fxLayout="row" fxLayoutAlign="center center"
                     [ngStyle]="{'background-image': profilePictureUrl}">
                </div>
                <div class="chat-name">
                    {{chatService.getChatPartnerByChat(chatData)?.name}}
                </div>
            </div>
            <div class="show-profile-button">
                <button mat-flat-button color="primary" (click)="viewTalentProfile()">Talentprofil anzeigen</button>
            </div>
        </div>

        <mat-divider *ngIf="chatInOverview"></mat-divider>

        <div class="height-100 messages-container" fxLayout="column" fxLayoutAlign="start start" #messageContainer>
            <div class="width-100" *ngFor="let message of chatData?.messages; let index = index">

                <div class="date" fxLayout="row" fxLayoutAlign="center center" *ngIf="isMessageOldestMessageOfDay(message, index)">
                    <span *ngIf="checkIfDateIsToday(message.occurred) == null">{{message.occurred | date: 'dd.MM.yyyy'}}</span>
                    <span *ngIf="checkIfDateIsToday(message.occurred) != null">{{checkIfDateIsToday(message.occurred)}}</span>
                </div>

<!--                <div fxLayout="row" fxLayoutAlign="center center" style="margin: 0.5rem" *ngIf="$unseenChatMessages.value != null && $unseenChatMessages.value.length != 0 && chat.id == $unseenChatMessages.value[0].id">-->
<!--                    <div class="new-message-divider"></div>-->
<!--                    <div class="new-messages">-->
<!--                        <span *ngIf="$unseenChatMessages.value.length == 1">1 Neue Nachricht</span>-->
<!--                        <span *ngIf="$unseenChatMessages.value.length > 1 && $unseenChatMessages.value.length < 10">{{$unseenChatMessages.value.length}} Neue Nachrichten</span>-->
<!--                        <span *ngIf="$unseenChatMessages.value.length > 9">9+ Neue Nachrichten</span>-->
<!--                    </div>-->
<!--                    <div class="new-message-divider"></div>-->
<!--                </div>-->

                <div class="width-100 message"
                     [ngClass]="{'consultant': message.sender.id == consultantId, 'margin-top': !isPreviousMessageFromTheSameUser(message, index)}">
                    <app-chat-message [messageData]="message" [profilePictureUrl]="profilePictureUrl"></app-chat-message>
                </div>

<!--                <div class="seen-text" fxLayout="row" fxLayoutAlign="end center" *ngIf="chat.sender.id == consultantId && checkForLastUnseenMessage(chat)">-->
<!--                    Gelesen-->
<!--                </div>-->
            </div>
        </div>

        <mat-divider style="margin-top: 1rem"></mat-divider>

        <div class="write-message" fxLayout="column" fxLayoutAlign="start start">
            <div class="width-100" fxLayout="row" fxLayoutAlign="space-between end">
                <textarea class="textarea" matInput [formControl]="messageControl" #textArea (keyup)="onKeyup($event)" placeholder="Schreibe eine Nachricht"></textarea>
                <button mat-icon-button (click)="sendMessage()">
                    <i class="mdi mdi-send"></i>
                </button>
            </div>
        </div>
    </div>
</div>
