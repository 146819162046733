<div id="main-component-wrapper">
    <div class="main-header" fxLayout="row" fxLayoutAlign="space-between start">
        <div fxLayout="row" fxLayoutAlign="start center">
            <h1>Chats</h1>
        </div>
    </div>

    <loading-spinner [loadingBool]="minimalChatsLoading">
    <div class="width-100 mat-elevation-z3 overview-container" fxLayout="row" fxLayoutAlign="start start">
        <div class="left-container mat-elevation-z1 height-100" fxLayout="column">
            <div class="new-chat" fxLayout="row" fxLayoutAlign="space-around center" (click)="openTalentSearchbar()">
                <div>
                    Chat suchen oder neuen Chat erstellen
                </div>

                <i class="mdi mdi-message-draw"></i>

            </div>
            <mat-divider></mat-divider>

            <div class="minimal-chats" *ngIf="chatMinimalData?.length != 0">
                <div fxLayout="column" class="minimal-chat-single"
                     *ngFor="let chat of chatMinimalData"
                     (click)="chat.existsUnseen = false; loadPartnerAndChatContent(chatService.getChatPartnerByChat(chat)?.id)"
                     [ngClass]="{'selected-chat': selectedChatId == chat.id || chat.id == null && selectedChatId == null}" #minimalChat>
                    <app-chat-minimal [chatMinimalData]="chat"
                                      [consultantId]="consultantId"></app-chat-minimal>
                    <div class="divider">
                        <mat-divider></mat-divider>
                    </div>
                </div>
            </div>
        </div>

        <div class="no-messages" *ngIf="chatMinimalData?.length == 0 && !chatData">
            Sie haben leider noch keine Nachrichten...
        </div>

        <div class="mat-elevation-z1 height-100 chat-wrapper" fxLayout="column" *ngIf="chatMinimalData?.length != 0 && chatData">

            <loading-spinner style="z-index: 1" [loadingBool]="chatLoading">

                <div class="height-100 chat-container" >
                    <app-chat *ngIf="chatData && !chatLoading"
                              [chatData]="chatData"
                              [chatInOverview]="true"
                              [talent]="talent"
                              (newMinimalChat)="loadMinimalChats()"
                              style="flex-grow: 1; overflow: auto">
                    </app-chat>
                </div>
            </loading-spinner>
        </div>
    </div>
    </loading-spinner>

</div>