<div class="main-wrapper">
    <div class="support-wrapper">
        <div class="privacy-agreement-wrapper mat-elevation-z4">

            <privacy-agreement-content></privacy-agreement-content>

            <div fxLayout="row" fxLayoutAlign="end center" class="action-bar layout-padding-24-32">
                <button mat-button color="warn" (click)="logout()">ABLEHNEN</button>
                <button mat-flat-button color="primary" (click)="acceptPrivacyAgreement()">ZUSTIMMEN</button>
            </div>
        </div>
    </div>
</div>
