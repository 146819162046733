import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {ConsultantTalentProfileData} from "../../../generated/data";
import {ConsultantTalentProfilePictureResource, ConsultantTalentResource} from "../../../generated/resources";
import {ConsultantAuthService} from "../../service/consultant.auth.service";

@Component({
    selector: 'talent-card',
    templateUrl: './talent-card.component.html',
    styleUrls: ['./talent-card.component.scss']
})
export class TalentCardComponent implements OnInit {

    @Input() talentId

    profilePictureUrl = '../assets/images/blurred_user.png';
    sevenDaysAgo: Date = new Date()

    public talentProfileData: ConsultantTalentProfileData

    constructor(
        public authService: ConsultantAuthService,
        private talentResource: ConsultantTalentResource,
        private consultantTalentProfilePictureResource: ConsultantTalentProfilePictureResource
    ) {
        this.sevenDaysAgo.setDate( new Date().getDate() - 7)
    }

    ngOnInit()
        :
        void {
        this.loadProfileData();
    }

    isActive(lastLogin?: Date): Boolean {
        if (!lastLogin) return false
        return new Date(lastLogin) >= this.sevenDaysAgo
    }

    loadProfileData()
        :
        void {
        this.talentResource.getTalent(this.talentId).then(profileData => {
            this.talentProfileData = profileData
            this.loadProfilePic();
        })
    }

    loadProfilePic() {
        if (this.talentProfileData.profilePictureId != null) {
            this.consultantTalentProfilePictureResource.getProfileImageHash(this.talentId).then(imageHash => {
                this.profilePictureUrl = environment.apiUrl + `/consultant/talents/${this.talentId}/profilePicture/${imageHash}`
            })
        }
    }

//Just for demo purposes
    getFunnelState() {
        switch (this.talentProfileData.funnelState) {
            case 'Denied':
                return 2
            case 'Withdrawn':
                return 2
            case 'CV':
                return 2
            case 'Pending':
                return 3
            case 'Granted':
                return 3
            case 'Interesting':
                return 3
            case 'AppProcessEnter':
                return 3
            case 'Hired':
                return 4
            default:
                return 1
        }
    }
}
