<div class="card-wrapper mat-elevation-z2" fxLayout="row">
    <div id="profile-picture"
         [ngStyle]="{'background-image': 'url(' + profilePictureUrl + ')'}">
    </div>

    <!--    <div *ngIf="!ProfilePic" id="profile-picture"-->
    <!--         [ngStyle]="{'background-image': 'url(' + '../assets/images/blurred_user.png' + ')'}"></div>-->

    <div class="contact-information width-100" fxLayout="row" fxLayoutAlign="space-between start">
        <div class="height-100 width-100" fxLayout="column" fxLayoutAlign="space-between start">
            <div class="width-100" fxLayout="row" fxLayoutAlign="space-between start">
                <span fxLayout="row" fxLayoutAlign="start center">
                                    <span class="talent-name">{{talentProfileData?.firstName}} {{talentProfileData?.lastName}}
                                        <active-marker *ngIf="isActive(talentProfileData?.lastLogin)"
                                                       [lastLogin]="talentProfileData?.lastLogin"></active-marker>
                </span>
                <coaching-offers *ngIf="talentProfileData?.coachingTypes"
                                 [coachingOffers]="talentProfileData?.coachingTypes"></coaching-offers>
                </span>
                <span style="color: #777; font-size: 12px; text-align: right">{{talentProfileData?.createdAt | date: "dd.MM.yy hh:mm"}}</span>
            </div>

            <div class="width-100 height-100 m-top10" fxLayout="row" fxLayoutAlign="space-between center"
                 style="font-size: 12px">
                <div fxLayout="column" fxLayoutAlign="center start" style="width: 210px; overflow: hidden">
                    <span><i class="mdi mdi-phone m-right5"></i>{{talentProfileData?.phoneNumber}}</span>
                    <span><i class="mdi mdi-email m-right5"></i>{{talentProfileData?.emailAddress}}</span>
                </div>

                <div class="height-100" fxLayout="column" fxLayoutAlign="end end">
                    <div *ngIf="!talentProfileData?.talentRated" style="margin-bottom: 3px; text-align: right" class="warn">Bewertung
                        Ausstehend
                    </div>

                    <div fxLayout="row" fxLayoutAlign="end center">
                        <div matTooltip="Das Talent wurde in Adapt als verloren markiert"
                             *ngIf="talentProfileData?.markedAsLost"
                             fxLayout="row" fxLayoutAlign="start center"
                             class="m-right10">
                            <i style="color: #797979; font-size: 20px" class="mdi mdi-account-alert"></i>
                        </div>
                        <talent-funnel *ngIf="talentProfileData" [funnelState]="getFunnelState()"
                                       style="margin-bottom: 5px"></talent-funnel>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
