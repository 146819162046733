import {FormGroup} from '@angular/forms';
import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})

export class UtilityService {
    constructor(
        private snackBar: MatSnackBar,
    ) {
    }

    showSnackBar(message: string, verticalPosition: MatSnackBarVerticalPosition = 'bottom') {
        this.snackBar.open(message, null, {duration: 3000, verticalPosition: verticalPosition});
    }

    copyToClipboard(textToCopy: string) {
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = textToCopy;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

        this.showSnackBar('In Zwischenablage kopiert');
    }
}

/**
 * Usage: Pass new Dataclass()
 * Will populate all keys of a data object with values of formControl of the formGroup that has the same name
 */
export function convertFormGroupToObject(object: any, formGroup: FormGroup): any {
    for (let control in formGroup.controls) {
        object[control] = formGroup.controls[control].value;
    }

    return object;
}

export async function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

