import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'funnelStateValue'
})
export class FunnelStateValuePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): number {
    switch (value) {
      case 'Denied':
        return 3
      case 'Withdrawn':
        return 3
      case 'CV':
        return 2
      case 'Pending':
        return 3
      case 'Granted':
        return 3
      case 'Interesting':
        return 3
      case 'AppProcessEnter':
        return 3
      case 'Failed':
        return 3
      case 'Hired':
        return 4
      default:
        return 1
    }
  }

}
