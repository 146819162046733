<dialog-header dialogHeadline="Stelle empfehlen"></dialog-header>
<div id="dialog-content-wrapper" fxLayout="column" fxLayoutAlign="space-between start">

    <div class="dialog-main-content layout-padding">
        <p style="font-size: 12px; font-style: italic; color: #777">
            Schlage hier die Stelle <span style="font-weight: bold">"{{position.positionTitle}}"</span> vor!
            Das Talent erhält eine Benachrichtigung über deinen Vorschlag per Mail und SMS.
            Kommentiere deine Empfehlung kurz, um sie persönlicher zu machen (30-150 Zeichen)</p>
        <mat-form-field style="width: 100%">
            <mat-label>Warum schlägst du diese Stelle vor?</mat-label>
            <textarea matInput required [formControl]="textControl" cdkTextareaAutosize [rows]="5" style="width: 100%"></textarea>
        </mat-form-field>
    </div>

    <div class="dialog-action-bar" fxLayoutGap="10px">
        <button mat-button (click)="close()">schließen</button>
        <button [loadingBool]="saving" [disabled]="textControl.invalid" mat-flat-button color="primary" (click)="save()">Vorschlag abschicken</button>
    </div>
</div>
