<div id="application-wrapper">
    <mat-sidenav-container id="side-nav-container" class="mat-elevation-z3">
        <mat-sidenav *ngIf="showNav()" mode="side" [opened]="true" class="height-100"
                     [ngClass]="{'sideNavExpanded': isExpanded}">
            <div id="logo-wrapper" fxLayout="row" fxLayoutAlign="center center">
                <img class="mobile-logo" src="../assets/images/logo_letter_inverse_s.png" alt="Logo Letter">
                <img class="full-logo" src="../assets/images/talents-logo.png" alt="talents-logo">
            </div>

            <div id="menu-wrapper">
                <mat-nav-list>
                    <mat-list-item [routerLink]="'dashboard'">
                        <mat-icon mat-list-icon>dashboard</mat-icon>
                        <h3 mat-line>Dashboard</h3>
                    </mat-list-item>
                    <mat-list-item [routerLink]="'talents'">
                        <mat-icon mat-list-icon>people</mat-icon>
                        <h3 mat-line>Talentübersicht</h3>
                    </mat-list-item>
                    <mat-list-item [routerLink]="'profile'">
                        <mat-icon mat-list-icon>account_circle</mat-icon>
                        <h3 mat-line>Mein Profil</h3>
                    </mat-list-item>
                    <mat-list-item [routerLink]="'appointmentRequests'">
                        <mat-icon mat-list-icon>calendar_month</mat-icon>
                        <h3 mat-line>Terminanfragen</h3>
                    </mat-list-item>
                    <mat-list-item [routerLink]="'chat-overview'" (click)="routeOrRefreshChatMessages()">
                        <mat-icon mat-list-icon [matBadge]="unseenChatMessagesCount.toString()"
                                  [matBadgeHidden]="unseenChatMessagesCount==0" matBadgeColor="warn">forum</mat-icon>
                        <h3 mat-line>Chats</h3>
                    </mat-list-item>
                </mat-nav-list>
            </div>
        </mat-sidenav>

        <mat-sidenav-content [ngStyle]="{'margin-left.px': !showNav()  ? 0 : 64}"
                             [ngClass]="{'sideNavExpanded': isExpanded, 'sideNavClosed': !authService.isAuthenticated()}">

            <div id="top-menu" *ngIf="showNav()" class="mat-elevation-z3" fxLayout="row"
                 fxLayoutAlign="space-between center">
                <button mat-icon-button (click)="isExpanded = !isExpanded" class="m-right20">
                    <mat-icon>menu</mat-icon>
                </button>

                <div class="search-wrapper"
                     fxLayout="row"
                     fxLayoutAlign="center center"
                     (click)="showSearchResults = true"
                     (clickOutside)="showSearchResults = false">

                    <input id="search-input" placeholder="Talent suchen" [formControl]="talentControl"
                           (focusin)="inputFocused=true" (blur)="endFocus()"
                           #searchInput>

                    <span>
                        <mat-progress-spinner
                                id="input-progress-spinner"
                                *ngIf="loadingTalents"
                                color="primary"
                                diameter="18"
                                strokeWidth="2"
                                mode="indeterminate">
                        </mat-progress-spinner>
                        <i class="mdi mdi-close"
                           (click)="resetSearchInput()"
                           *ngIf="talentControl.value && talentControl.value.toString().length > 0">
                        </i>
                    </span>

                    <span class="input-icon"><i class="mdi mdi-magnify"></i></span>

                    <div [ngClass]="{'fly-in': inputFocused}" class="mat-elevation-z6 width-100" id="search-list">
                        <div class="search-element width-100" fxLayout="row" fxLayoutAlign="space-between center"
                             *ngFor="let talent of talentsData">
                            <div class="info-element m-right40">{{talent.firstName}} {{talent.lastName}}</div>
                            <div class="info-element m-right40">{{talent.emailAddress}}</div>
                            <div fxLayout="row" fxLayoutAlign="end center">
                                <button mat-icon-button (click)="openTalentProfileDialog(talent)"
                                        matTooltip="Profil öffnen">
                                    <i class="mdi mdi-account"></i>
                                </button>
                                <button mat-icon-button
                                        matTooltip="Nachricht schreiben"
                                        routerLink="/chat-overview" (click)="openChatOverview(talent.id)"
                                        *ngIf="router.url == '/chat-overview'">
                                    <i class="mdi mdi-message-draw"></i>
                                </button>
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutAlign="start center" class="no-item layout-padding">
                            <div class="m-bottom15" *ngIf="showNoItemInfo">Es wurde kein Kontakt gefunden</div>
                        </div>
                    </div>
                </div>


                <div class="action-bar" fxLayout="row" fxLayoutAlign="center end">

                                        <button matTooltip="Info & Hilfe" fxLayout="row" (click)="openSupportDialog()" fxLayoutAlign="center center" mat-button>
                                            <i class="mdi mdi-help-circle"></i>
                                        </button>

<!--                    <button matTooltip="Chat mit Talenten" fxLayout="row" fxLayoutAlign="center center" mat-button [routerLink]="'chat-overview'" (click)="routeOrRefreshChatMessages()">-->
<!--                        <i class="mdi mdi-forum"-->
<!--                           [matBadge]="unseenChatMessagesCount.toString()"-->
<!--                           [matBadgeHidden]="unseenChatMessagesCount==0" matBadgeColor="warn"></i>-->
<!--                    </button>-->

                    <button [matMenuTriggerFor]="notificationMenu"
                            (click)="notificationMenuOpened.next(true)"
                            matTooltip="Benachrichtigungen" fxLayout="row"
                            fxLayoutAlign="center center" mat-button>
                        <mat-icon [matBadge]="unseenNotificationCount.toString()"
                                  [matBadgeHidden]="unseenNotificationCount==0" matBadgeColor="warn">notifications
                        </mat-icon>
                    </button>

                    <mat-menu #notificationMenu id="notificationMenu">
                        <notifications-menu [menuOpenedSubject]="notificationMenuOpened"></notifications-menu>
                    </mat-menu>

                    <button fxLayout="row" fxLayoutAlign="center center" mat-button [matMenuTriggerFor]="userMenu">
                        <i class="mdi mdi-account"></i>
                        <span>{{authService.getAccountInfo().firstName}} {{authService.getAccountInfo().lastName}}</span>
                    </button>

                    <mat-menu #userMenu>
                        <button fxLayout="row" fxLayoutAlign="start center" class="action-button"
                                (click)="router.navigate(['/settings'])" mat-menu-item>
                            <i class="mdi mdi-lock"></i>Einstellungen
                        </button>
                        <button (click)="logout()" fxLayout="row" fxLayoutAlign="start center" class="action-button"
                                routerLink="/login" mat-menu-item><i
                                class="mdi mdi-logout"></i>Abmelden
                        </button>
                    </mat-menu>
                </div>
            </div>

            <div id="content-wrapper"
                 [ngClass]="{
                 'sideNavClosed': !showNav(),
                 'no-footer': router.url == '/onboarding',
                 'h-100-important': router.url.includes('login') || router.url.includes('privacy')
                 }">
                <router-outlet></router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
