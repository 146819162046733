<loading-spinner [loadingBool]="loading">
    <div [ngClass]="{'not-rated-talents': unratedTalentsData?.length || loading}">

        <h2 style="margin-left: 0">Ausstehende Bewertungen
            <i class="mdi mdi-check-circle" *ngIf="unratedTalentsData.length == 0 && !loading"></i>
            <i class="mdi mdi-alert-circle" *ngIf="unratedTalentsData.length != 0 || loading"></i>
        </h2>
        <div class="no-open-ratings" *ngIf="unratedTalentsData.length == 0 && !loading">
            <span>Du hast aktuell keine offenen Bewertungen!</span>
        </div>
        <div class="info-text" *ngIf="unratedTalentsData.length != 0 || loading">
            <span>Bewerte alle Talente, die du erhalten hast.
                Mit deinem Feedback können wir die Qualität deiner Talente langfristig erhöhen!
            </span>
        </div>

        <div class="talent-cards-empty" [ngClass]="{'talent-cards': unratedTalentsData.length != 0 || loading}" fxLayout="column">
            <app-not-rated-talent-card id="card-{{index}}"
                                       [ngClass]="{'delete': deleteCardId == talent.id, 'move': index > lastDeletedIndex}"
                                       *ngFor="let talent of unratedTalentsData; let index = index"
                                       [talent]="talent"
                                       (delete)="removeUnratedTalent($event, index)">
            </app-not-rated-talent-card>

        </div>
        <mat-paginator *ngIf="unratedTalentsData.length != 0 || loading"
                       showFirstLastButtons
                       (page)="handlePage($event)"
                       [pageSize]="pageSize"
                       hidePageSize [pageIndex]="page"
                       [length]="totalElements">
        </mat-paginator>

    </div>

</loading-spinner>