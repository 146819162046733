import { Component, OnInit } from '@angular/core';
import {SideSheetRef} from "../../utility/side-sheet/sideSheetRef";

@Component({
  selector: 'app-recommendation-info-dialog',
  templateUrl: './recommendation-info-dialog.component.html',
  styleUrls: ['./recommendation-info-dialog.component.scss']
})
export class RecommendationInfoDialogComponent implements OnInit {

  constructor(
      private sideSheetRef: SideSheetRef,
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.sideSheetRef.sheetClosed.next()
  }

}
