<dialog-header [dialogHeadline]="talentProfileData?.firstName + ' ' + talentProfileData?.lastName">
        <active-marker *ngIf="isActive(talentProfileData?.lastLogin)" [lastLogin]="talentProfileData.lastLogin"></active-marker>
</dialog-header>

    <div class="profile-header-content">
        <loading-spinner [loadingBool]="loadingProfile">

        <div class="profile-header width-100" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="8px">
            <div class="profile-header-inner width-100" fxLayout="column" fxLayoutAlign="start center">
                <div class="mlp-application" [matTooltip]="getMlpAplicationsTooltip()" *ngIf="talentProfileData?.mlpApplicationsData?.length"
                    >Achtung! Das Talent hat eine MLP-Bewerbung!</div>

                <div class="width-100" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="contact-information" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="25px">
                        <div id="profile-picture"
                             [ngStyle]="{'background-image': 'url(' + profilePicture + ')'}">
                        </div>

                        <div>
                            <div class="data-description">Name</div>
                            <div class="data-value" [matTooltip]="talentProfileData?.firstName + ' ' + talentProfileData?.lastName">{{talentProfileData?.firstName}} {{talentProfileData?.lastName}}</div>
                        </div>

                        <div>
                            <div class="data-description">Telefon</div>
                            <div class="data-value"
                                 *ngIf="talentProfileData?.phoneNumber">{{talentProfileData?.phoneNumber}}</div>
                            <div class="missing" *ngIf="!talentProfileData?.phoneNumber">n.a.</div>
                        </div>

                        <div>
                            <div class="data-description">E-Mail</div>
                            <div class="data-value" [matTooltip]="talentProfileData?.emailAddress"
                                 *ngIf="talentProfileData?.emailAddress">{{talentProfileData?.emailAddress}}</div>
                            <div class="missing" *ngIf="!talentProfileData?.emailAddress">n.a.</div>
                        </div>

                        <div>
                            <div class="data-description">Lebenslauf</div>
                            <div class="data-value cv-download" style="color: green" *ngIf="talentProfileData?.cvId != null"
                                 matTooltip="CV Herunterladen"
                                 (click)="downloadCv()">
                                Vorhanden <i class="mdi mdi-download"></i>
                            </div>
                            <div class="missing" style="color: var(--warn);" *ngIf="talentProfileData?.cvId == null">
                                <i class="mdi mdi-alert"></i> fehlt
                            </div>
                        </div>

                    </div>
                    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px" class="info-icons">
                        <i [matTooltip]="'Bewertung eingetragen'" *ngIf="talentProfileData?.talentRated && !talentProfileData?.talentUnreachable" class="mdi mdi-message"></i>
                        <i [matTooltip]="'Talent nicht erreichbar'" *ngIf="talentProfileData?.talentRated && talentProfileData?.talentUnreachable" class="mdi mdi-phone-off"></i>
                        <i [matTooltip]="getMlpAplicationsTooltip()" *ngIf="talentProfileData?.mlpApplicationsData?.length" class="mdi mdi-text-box" style="color: #2ebb00"></i>
                        <i [matTooltip]="'Dieses Talent wurde bereits von dir oder dem Talent selbst als eingestellt gemeldet'" *ngIf="talentProfileData?.talentMarkedAsHired" class="mdi mdi-star"></i>
                        <i [matTooltip]="'Dieses Talent möchte keine Stellenvorschläge mehr erhalten'" *ngIf="talentProfileData?.matchingState == 'MatchingDenied' && !talentProfileData?.deleted" class="mdi mdi-lightbulb-off"></i>
                        <i [matTooltip]="'Dieses Talent hat sich von Talentagent abgemeldet'" *ngIf="talentProfileData?.deleted" class="mdi mdi-alert"></i>
                        <i [matTooltip]="'Dieses Talent wurde in Adapt als verloren markiert'" *ngIf="talentProfileData?.markedAsLost" style="color: var(--warn)" class="mdi mdi-account-alert"></i>

                        <button style="font-size: 14px" mat-flat-button color="primary" *ngIf="!talentProfileData?.talentRated"
                                (click)="openRateTalentContactedDialog(talentProfileData?.id)">
                            <span>Talent bewerten</span>
                        </button>

                        <button mat-icon-button class="matMenuButton"
                                [matMenuTriggerFor]="talentMenu">
                            <i class="mdi mdi-dots-vertical"></i>
                        </button>
                    </div>
                </div>
            </div>

        </div>

        <mat-menu #talentMenu="matMenu">
            <button matTooltip="Kontaktprofil in Adapt öffnen" (click)="routeToAdapt()" mat-menu-item >
                <span>In ADAPT öffnen</span>
            </button>

            <button mat-menu-item color="primary"
                    (click)="openMarkTalentAsHiredDialog()"
                    [disabled]="talentProfileData?.talentMarkedAsHired">
                Als Eingestellt markieren
            </button>
        </mat-menu>
        </loading-spinner>
    </div>


    <mat-tab-group class="width-100" [selectedIndex]="tabIndex" style="height: calc(100% - 134px)">
        <mat-tab label="Kontaktinformationen">

            <div class="funnel-bar blue inc-7" *ngIf="talentProfileData">
                <ul>
                    <li [ngClass]="{'current': (this.talentProfileData.funnelState | funnelStateValue) == 1}">Onboarding
                    </li>
                    <li [ngClass]="{'current': (this.talentProfileData.funnelState | funnelStateValue) == 2}">Lebenslauf
                    </li>
                    <li [ngClass]="{'current': (this.talentProfileData.funnelState | funnelStateValue) == 3}">Bewerbung</li>
                    <li [ngClass]="{'current': (this.talentProfileData.funnelState | funnelStateValue) == 4}">Einstellung
                    </li>
                </ul>
            </div>
            <div fxLayout="row" class="width-100" style="margin-bottom: 10px">

                <div id="study-info" class="width-50 layout-padding-24-32 data-wrapper" fxLayout="column"
                     fxLayoutAlign="start start">
                    <div fxLayout="row" fxLayoutAlign="space-between center" class="width-100">
                        <div class="data-headline">Studium</div>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start start" class="width-100">
                        <div class="width-60 data-container" fxLayout="column" fxLayoutAlign="start start">


                            <div fxLayout="column" fxLayoutAlign="start start">
                                <div class="data-description">Hochschule</div>
                                <div class="data-value"
                                     *ngIf="talentProfileData?.university">{{talentProfileData.university}}</div>
                                <div class="missing" *ngIf="!talentProfileData?.university">n.a.</div>
                            </div>

                            <div fxLayout="column" fxLayoutAlign="start start">
                                <div class="data-description">Studiengang</div>
                                <div class="data-value"
                                     *ngIf="talentProfileData?.subject">{{talentProfileData?.subject}}</div>
                                <div class="missing" *ngIf="!talentProfileData?.subject">n.a.</div>
                            </div>

                        </div>

                        <div class="width-40 data-container" fxLayout="column" fxLayoutAlign="start start">

                            <div fxLayout="column" fxLayoutAlign="start start">
                                <div class="data-description">Abschluss</div>
                                <div class="data-value"
                                     *ngIf="talentProfileData?.degreeType">{{talentProfileData?.degreeType}}</div>
                                <div class="missing" *ngIf="!talentProfileData?.degreeType">n.a.</div>
                            </div>

                            <div fxLayout="column" fxLayoutAlign="start start">
                                <div class="data-description">Abschlussdatum (Angabe des Talents bei der Anmeldung)</div>
                                <div class="data-value" style="text-overflow: unset; white-space: unset"
                                     *ngIf="talentProfileData?.endDate">{{getTalentFinishStudyString()}} </div>
                                <div class="missing" *ngIf="!talentProfileData?.endDate">n.a.</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="layout-padding-24-32 data-wrapper width-50">
                    <div class="data-headline">Coaching-Wünsche</div>
                    <div style="    color: #777;
    font-style: italic;
    margin-bottom: 10px;
    font-size: 12px;">Diese Leistungen wünscht sich das Talent von dir
                    </div>
                    <form [formGroup]="coachingsForm" class="data-container height-100" fxLayout="column"
                          fxLayoutAlign="start start">
                        <div *ngFor="let coaching of availableCoachings" fxLayout="row" fxLayoutAlign="space-between start"
                             class="width-100 m-bottom5">
                            <span>{{coaching?.title}}</span>
                            <mat-checkbox [formControlName]="coaching?.title" disabled></mat-checkbox>
                        </div>
                    </form>

                </div>
            </div>
        </mat-tab>
        <mat-tab label="Stellen empfehlen">
            <talent-position-proposals [talentId]="talentId"></talent-position-proposals>

        </mat-tab>
        <mat-tab label="Bewerbungen">
            <ng-template matTabContent>
                <talent-applications [talentId]="talentId"></talent-applications>
            </ng-template>
        </mat-tab>
        <mat-tab label="Chat mit Talent">
            <div class="chat-wrapper">
                <app-chat [chatData]="chatData" [talent]="talentProfileData" *ngIf="chatData" [chatInOverview]="false"></app-chat>
            </div>
        </mat-tab>
        <mat-tab label="AECdisc®-Potenzialanalyse">
            <div class="width-100 height-100 white-background" fxLayout="column" fxLayoutAlign="space-between start">
                <div *ngIf="!talentEvaluationData" class="layout-padding width-100">
                    <div class="aec-step">Es wurde noch kein Code für die AECdisc®-Potenzialanalyse angefordert.</div>
                    <div>Du kannst einen Zugang zu AECDisc für dieses Talent anfordern. Wenn du das tust, wird das Talent per Email benachrichtigt und kann den Test dann selbstständig ausfüllen. Das Ergebnis stellen wir nur dir zur Verfügung, so dass du es mit dem Talent durchsprechen kannst.
                    </div>
                </div>

                <div *ngIf="talentEvaluationData && !talentEvaluationData.testResultId" class="layout-padding width-100">
                    <div class="aec-step">Du hast bereits einen Code für das Talent angefordert.</div>
                    <div>Das Talent hat eine Mail erhalten, dass du einen Code bereitgestellt hast und kann den Test jetzt machen. Sobald der Test fertig ist, benachrichtigen wir dich und das Ergebnis ist hier abrufbar. Das Talent erhält das Ergebnis vorerst noch nicht, bis du mit ihm gesprochen hast. Es kann bis zu 24h dauern, bis der fertige Test in TALENTS verfügbar ist. Wenn du weißt, dass das Talent den Test schon fertiggestellt hat, kannst du unten auf "Teststatus aktualisieren" klicken, um das Ergebnis sofort abzuholen.
                    </div>
                    <div fxLayout="column" fxLayoutAlign="start center" class="aecdisc-code-wrapper">
                        <div>Zugangscode für das Talent </div>
                        <div style="color: #777">(Das Talent hat diesen Code bereits automatisch erhalten)</div>
                        <div class="aecdisc-code" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                            <div>{{talentEvaluationData?.password}}</div>
                            <button mat-icon-button matTooltip="Kopieren" (click)="utilityService.copyToClipboard(talentEvaluationData?.password)"><i class="mdi mdi-content-copy"></i></button>
                        </div>
                    </div>
                </div>

                <div *ngIf="talentEvaluationData && talentEvaluationData.testResultId && !talentEvaluationData.approved" class="layout-padding width-100">
                    <div class="aec-step">Das Talent hat die AECdisc®-Potenzialanalyse gemacht.</div>
                    <div>
                        Du kannst das Ergebnis jetzt herunterladen. Das Talent sieht die Auswertung erst, wenn du sie über die Funktion "Ergebnis für Talent freigeben" freischaltest. Danach ist sie über den Reiter "Karrierecoach" für den Absolventen abrufbar.
                    </div>
                </div>

                <div *ngIf="talentEvaluationData && talentEvaluationData.testResultId && talentEvaluationData.approved" class="layout-padding width-100">
                    <div class="aec-step">Du hast die Analyse für das Talent freigeschaltet.</div>
                    <div>
                        Das Talent kann nun auch selbst über den Reiter "Karrierecoach" auf das Testergebnis zugreifen. Der Testvorgang ist damit beendet.
                    </div>
                </div>

                <div class="actions width-100 layout-padding" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
                    <button mat-button color="primary"
                            (click)="openAecdiscInformationDialog()">
                        <i class="mdi mdi-24px mdi-information-outline"></i>
                        Wie funktioniert AECdisc® in Talentagent?
                    </button>
                    <loading-button
                            *ngIf="!talentEvaluationData"
                            [buttonClass]="'mat-flat-button'" [color]="'primary'"
                            [loadingBool]="loadingTalentEvaluation"
                            (clickEvent)="createTalentEvaluation()">
                        Code anfordern
                    </loading-button>

                    <loading-button [color]="'primary'"
                                    *ngIf="talentEvaluationData && talentEvaluationData.status != 'CLOSED' && talentEvaluationData.testResultId == null"
                                    [loadingBool]="refreshingConsultantEvaluationGroup"
                                    (click)="triggerRefreshTalentAecdisc()">
                        Teststatus aktualisieren
                    </loading-button>

                    <loading-button
                            *ngIf="talentEvaluationData && talentEvaluationData.testResultId"
                            [buttonClass]="'mat-flat-button'" [color]="'primary'"
                            [disabledCondition]="downloadingAecdiscResult"
                            (clickEvent)="downloadAecdiscResult()">
                        Ergebnis herunterladen
                    </loading-button>

                    <button mat-flat-button color="primary" *ngIf="talentEvaluationData && talentEvaluationData.testResultId" (click)="openTalentEvaluationResult()">
                        Ergebnis öffnen
                    </button>

                    <loading-button
                            *ngIf="talentEvaluationData && talentEvaluationData.testResultId"
                            [buttonClass]="'mat-flat-button'" [color]="'primary'"
                            [loadingBool]="downloadingAecdiscResult"
                            [disabledCondition]="talentEvaluationData && talentEvaluationData.testResultId && talentEvaluationData.approved"
                            (clickEvent)="releaseAecdiscResult()">
                        Ergebnis für Talent freigeben
                    </loading-button>
                </div>
            </div>

        </mat-tab>
        <mat-tab label="Ereignishistorie">
            <div class="last-login" *ngIf="talentProfileData?.lastLogin">Letzte Aktivität: {{talentProfileData.lastLogin  | date: 'dd.MM.yy'}}</div>
            <table mat-table [dataSource]="talentEventHistoryData" class="width-100">
                <!-- Date Column -->
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> Datum</th>
                    <td mat-cell *matCellDef="let element"> {{element.occurred | date: 'dd.MM.yy HH:mm'}} Uhr</td>
                </ng-container>

                <!-- Event Column -->
                <ng-container matColumnDef="event">
                    <th mat-header-cell *matHeaderCellDef> Event</th>
                    <td mat-cell *matCellDef="let element"> {{element.logDtype | eventLog: element.positionTitle}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsEventHistory"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsEventHistory;"></tr>
            </table>
        </mat-tab>
        <mat-tab label="Notizen">
            <div class="width-100 height-100 white-background">
                <mat-form-field class="width-100 layout-padding white-background height-100-68">
                    <mat-label>Notizen</mat-label>
                    <textarea #note matInput maxlength="4000" rows="20" cols="40" [formControl]="comment"
                              class="height-100"></textarea>
                    <mat-hint align="end">{{comment?.value?.length}} / 4000</mat-hint>
                </mat-form-field>
                <div class="actions width-100 layout-padding" fxLayout="row" fxLayoutAlign="end center">
                    <button mat-flat-button color="primary"
                            (click)="updateContactComment()">
                        Änderungen Speichern
                    </button>
                </div>
            </div>

        </mat-tab>

    </mat-tab-group>



