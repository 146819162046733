import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {AuthGuardService} from './service/auth-guard.service';
import {DashboardComponent} from './dashboard/dashboard.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {TalentsComponent} from './talents/talents.component';
import {ConsultantProfileComponent} from './consultant-profile/consultant-profile.component';
import {ProfilePreviewComponent} from './consultant-profile/profile-preview/profile-preview.component';
import {NotificationsComponent} from './notifications/notifications.component';
import {SettingsComponent} from './settings/settings.component';
import {PrivacyAgreementComponent} from './privacy-agreement/privacy-agreement.component';
import {OnboardingComponent} from "./onboarding/onboarding.component";
import {OneTimeTokenLoginComponent} from "./one-time-token-login/one-time-token-login.component";
import {ConsultantAppointmentsComponent} from "./consultant-appointments/consultant-appointments.component";
import {ChatOverviewComponent} from "./chat-overview/chat-overview.component";

const routes: Routes = [
  {path: '', component: LoginComponent, canActivate: [AuthGuardService]},
  {path: 'login', component: LoginComponent},
  {path: 'privacy', component: PrivacyAgreementComponent, canActivate: [AuthGuardService]},
  {path: 'settings', component: SettingsComponent, canActivate: [AuthGuardService]},
  {path: 'appointmentRequests', component: ConsultantAppointmentsComponent, canActivate: [AuthGuardService]},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService]},
  {path: 'onboarding', component: OnboardingComponent, canActivate: [AuthGuardService]},
  {path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuardService]},
  {path: 'talents', component: TalentsComponent, canActivate: [AuthGuardService]},
  {path: 'profile', component: ConsultantProfileComponent, canActivate: [AuthGuardService]},
  {path: 'profile/preview', component: ProfilePreviewComponent, canActivate: [AuthGuardService]},
  {path: 'onetimetoken/login', component: OneTimeTokenLoginComponent},
    {path: 'chat-overview', component: ChatOverviewComponent, canActivate: [AuthGuardService]},
  {path: '404', component: NotFoundComponent},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)]
})
export class AppRoutingModule {
}
