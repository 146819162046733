<dialog-header dialogHeadline="Info & Hilfe"></dialog-header>
<div id="dialog-content-wrapper" fxLayout="column" fxLayoutAlign="space-between start">

    <div class="dialog-main-content height-100 layout-padding" fxLayout="column" fxLayoutAlign="space-between start">


        <div class="width-100">
            <h2 class="width-100 text-center">Starter-Kit</h2>
            <div class="m-top15 m-bottom50" fxLayout="column" fxLayoutAlign="start center">
                <div class="text-center m-bottom20">Alles Wichtige auf einen Blick - Lade dir hier das Talentagent
                    Starter-Kit von Uniwunder herunter !
                </div>
                <loading-button [loadingBool]="downloading" color="accent" (clickEvent)="downloadStarterKit()">
                    <i class="mdi mdi-file-download font-size-24 m-right10"></i> Starter-Kit downloaden
                </loading-button>
            </div>

            <h2 class="width-100 text-center">Video von Jonas Köck</h2>
            <div class="text-center video-wrapper">
                <a href="https://web.microsoftstream.com/video/8fe79a2c-ba58-45c6-8246-89cc566feb25" target="_blank"
                   class="video-link font-size-16">
                    <i class="mdi mdi-play-circle m-right5"></i>Zum Video</a>
            </div>
        </div>

        <div class="width-100">
            <h2 class="width-100 text-center">Support</h2>
            <div fxLayout="column" fxLayoutAlign="start center" class="info m-bottom10 width-100 font-size-16">
                <div fxLayout="row" fxLayoutAlign="center center" class="width-100">
                    <i class="mdi mdi-phone m-right10"></i>
                    <div>Telefon: 0351 / 799 033 77</div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center" class="width-100 m-bottom20 m-top5">
                    <i class="mdi mdi-email m-right10"></i>
                    <a href="mailto:support@uniwunder.com">support@uniwunder.com</a>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center" style="font-size: 13px" class="width-100">
                    <i class="mdi mdi-clock m-right10"></i>
                    <div>Supportzeiten: Mo-Do 9.00-12.00 Uhr und 14.00-16.00 Uhr sowie Fr 9.00-14.00 Uhr</div>
                </div>
            </div>
        </div>
    </div>

    <div class="dialog-action-bar" fxLayoutGap="10px">
        <button mat-flat-button (click)="close()">Schließen</button>
    </div>
</div>

