import { Component, OnInit } from '@angular/core';
import {SideSheetRef} from "../../utility/side-sheet/sideSheetRef";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-aecdisc-information-dialog',
  templateUrl: './aecdisc-information-dialog.component.html',
  styleUrls: ['./aecdisc-information-dialog.component.scss']
})
export class AecdiscInformationDialogComponent implements OnInit {
  constructor(
      private sideSheetRef: SideSheetRef,
      private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
  }

  close(result: Boolean = false) {
    this.sideSheetRef.sheetClosed.next(result)
  }


}
