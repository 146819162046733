import {Component, OnInit} from '@angular/core';
import {
  ChatData, ChatMessageData,
  CoachingTypeData,
  ConsultantTalentEventHistoryData,
  ConsultantTalentProfileData,
  PrimePositionData,
  TalentEvaluationData
} from '../../../generated/data';
import {
  CoachingTypeResource, ConsultantChatResource,
  ConsultantCoachingResource,
  ConsultantTalentProfilePictureResource,
  ConsultantTalentResource
} from '../../../generated/resources';
import {SideSheetRef} from '../../utility/side-sheet/sideSheetRef';
import {FileService} from '../../service/file.service';
import {environment} from '../../../environments/environment';
import {FormControl, FormGroup} from '@angular/forms';
import {UtilityService} from '../../service/utility-service';
import {DialogService} from '../../utility/side-sheet/dialog.service';
import {RateTalentContactedDialogComponent} from "../rate-talent-contacted-dialog/rate-talent-contacted-dialog.component";
import {ConsultantAuthService} from "../../service/consultant.auth.service";
import {AecdiscInformationDialogComponent} from "../aecdisc-information-dialog/aecdisc-information-dialog.component";
import {DomSanitizer} from "@angular/platform-browser";
import {MarkTalentAsHiredDialogComponent} from "../mark-talent-as-hired-dialog/mark-talent-as-hired-dialog.component";
import {DatePipe} from "@angular/common";
import {BehaviorSubject} from "rxjs";
import {ChatService} from "../../service/chat.service";

@Component({
  selector: 'app-talent-profile',
  templateUrl: './talent-profile-dialog.component.html',
  styleUrls: ['./talent-profile-dialog.component.scss']
})
export class TalentProfileDialogComponent implements OnInit {

  public talentProfileData: ConsultantTalentProfileData;
  public primePositionsData: PrimePositionData[];
  public talentEventHistoryData: ConsultantTalentEventHistoryData[];
  public coachingsForm: FormGroup = new FormGroup({});
  public availableCoachings: CoachingTypeData[];
  public selectedCoachings: CoachingTypeData[];
  public talentApplicationCount: number;
  public talentPositionProposalCount: number;
  public displayedColumnsEventHistory: string[] = ['date', 'event'];
  public profilePicture = '../assets/images/blurred_user.png';
  public chatData: ChatData
  // public $unseenChatMessages: BehaviorSubject<ChatMessageData[]> = new BehaviorSubject<ChatMessageData[]>(null)

  comment: FormControl = new FormControl('');

  loadingPrime: boolean = true

  talentEvaluationData: TalentEvaluationData

  loadingTalentEvaluation: boolean = false
  refreshingConsultantEvaluationGroup: boolean = false
  downloadingAecdiscResult: boolean = false
  loadingReleaseAecdiscResult: boolean = false
  loadingProfile: boolean = true


  //input
  talentId: number;
  tabIndex: number = 0;

  sevenDaysAgo: Date = new Date()

  constructor(
      public authService: ConsultantAuthService,
      public utilityService: UtilityService,
      private sideSheetRef: SideSheetRef,
      private talentResource: ConsultantTalentResource,
      private consultantTalentProfilePictureResource: ConsultantTalentProfilePictureResource,
      private coachingTypeResource: CoachingTypeResource,
      private fileService: FileService,
      private consultantCoachingResource: ConsultantCoachingResource,
      private sanitizer: DomSanitizer,
      private dialogService: DialogService,
      private datePipe: DatePipe,
      private chatResource: ConsultantChatResource,
      public chatService: ChatService
  ) {
      this.sevenDaysAgo.setDate( new Date().getDate() - 7)
  }

  ngOnInit(): void {
    this.loadAllData();
    this.loadCoachings();
  }

  getTalentFinishStudyString(): string {
    if (!this.talentProfileData.endDate) return "n.a."
    let dateDiff = (new Date(this.talentProfileData.endDate).getTime() - new Date(this.talentProfileData.createdAt).getTime())/ 1000 / 60 / 60 / 24
    if (dateDiff <= 0) return 'schon fertig '
    if (dateDiff <= 90) return 'innerhalb von 3 Monaten '
    if (dateDiff <= 180) return 'innerhalb von 6 Monaten '
   return 'mehr als 6 Monate '
  }

  getMlpAplicationsTooltip(): string {
    let tooltipPositions: string = "Talent hat sich auf folgende MLP-Stellen Beworben: "
    this.talentProfileData.mlpApplicationsData.forEach((item, index) => {
      tooltipPositions = tooltipPositions + (item.employmentPosition.name + " (" + this.datePipe.transform(item.applicationDate, "dd.MM.yy") + ")" + (index+1 == this.talentProfileData.mlpApplicationsData.length? "" : ", "))
    })
    return tooltipPositions
  }

  loadCoachings() {
    this.coachingTypeResource.getAllCoachingTypes().then(coachings => {
      this.availableCoachings = coachings;

      this.availableCoachings.forEach(coaching => {
        this.coachingsForm.addControl(coaching.title, new FormControl());
      });

      if (this.talentId) {
          this.coachingTypeResource.getSelectedCoachingTypesOfTalent(this.talentId).then(coachings => {
              this.selectedCoachings = coachings;

              this.selectedCoachings.forEach(coaching => {
                  this.coachingsForm.controls[coaching.title].setValue(true);
              });
          });
      }
    })
  }

  loadAllData(): void {
    this.loadProfileData();
    this.getTalentEvaluation();
    this.getTalentApplicationCount();
    this.getTalentPositionProposalCount();
    this.loadTalentEventHistory();
    this.loadPrimePositions();
    this.loadChat();
  }

  openAecdiscInformationDialog() {
   this.dialogService.openOverlay(AecdiscInformationDialogComponent, '600px');

  }

  openRateTalentContactedDialog(talentId: number) {
    const comp = this.dialogService.openOverlay(RateTalentContactedDialogComponent, '600px');
    comp.instance.talentId = talentId
    let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(result => {
      if (result) this.loadProfileData()
      subscription.unsubscribe();
    });
  }

  loadTalentEventHistory(): void {
    this.talentResource.getEventHistoryOfTalent(this.talentId).then(events => {
      this.talentEventHistoryData = events
    })
  }

  loadPrimePositions() {
    this.loadingPrime = true
    this.talentResource.getTalentPrimePositions(this.talentId).then(result => {
      this.primePositionsData = result
      this.loadingPrime = false
    })
  }

  loadProfileData(): void {
    this.loadingProfile = true
    this.talentResource.getTalent(this.talentId).then(profileData => {
      this.talentProfileData = profileData;
      this.comment.setValue(this.talentProfileData.coaching.comment);
      this.loadProfilePic();
      this.loadingProfile = false
    });
  }

  loadChat(): void {
    this.chatResource.getChatForTalent(this.talentId).then(result => {
      if (result.id) this.chatData = result
      else {
        this.chatData = this.chatService.createDummyChat()
      }
    })
  }

  updateContactComment() {
    this.consultantCoachingResource.updateCoachingComment(this.talentProfileData.coaching.id, {comment: this.comment.value}).then(result => {
      this.utilityService.showSnackBar('Notiz bearbeitet');
    });
  }

  loadProfilePic() {
    if (this.talentProfileData.profilePictureId != null) {
      this.consultantTalentProfilePictureResource.getProfileImageHash(this.talentId).then(imageHash => {
        this.profilePicture = environment.apiUrl + `/consultant/talents/${this.talentId}/profilePicture/${imageHash}`
      })
    }
  }

  getTalentApplicationCount() {
    this.talentResource.getTalentApplicationsCount(this.talentId).then(count => {
      this.talentApplicationCount = count.value
    })
  }

  getTalentPositionProposalCount() {
    this.talentResource.getTalentPositionProposalCount(this.talentId).then(count => {
      this.talentPositionProposalCount = count.value;
    });
  }

  routeToAdapt() {
    window.open(environment.adaptFrontendUrl + '/contacts?talentId=' + this.talentProfileData.id, '_blank')
  }

  downloadCv(): void {
    this.fileService.downloadCv(this.talentId);
  }

  createTalentEvaluation() {
    this.loadingTalentEvaluation = true
    this.consultantCoachingResource.createTalentEvaluation(this.talentId).then(result => {
      this.talentEvaluationData = result
      this.loadingTalentEvaluation = false
    })
  }

  getTalentEvaluation() {
    this.loadingTalentEvaluation = true
    this.consultantCoachingResource.getTalentEvaluation(this.talentId).then(result => {
      if (result?.evaluationId) this.talentEvaluationData = result
      this.loadingTalentEvaluation = false
    })
  }

  openTalentEvaluationResult() {
    this.fileService.getEvaluationResult(this.talentId).subscribe(blob => {
      window.open(URL.createObjectURL(blob), '_blank')
    })
  }

  openMarkTalentAsHiredDialog() {
    let comp = this.dialogService.openOverlay(MarkTalentAsHiredDialogComponent, '600px')
    comp.instance.talentId = this.talentProfileData.id
    let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(result => {
      if (result) this.talentProfileData.talentMarkedAsHired = true
      subscription.unsubscribe();
    });
  }


  triggerRefreshTalentAecdisc() {
    this.refreshingConsultantEvaluationGroup = true
    this.consultantCoachingResource.updateConsultantEvaluationGroup().then(result => {
      this.getTalentEvaluation();
      this.refreshingConsultantEvaluationGroup = false
    })
  }

  downloadAecdiscResult() {
    this.downloadingAecdiscResult = true
    this.fileService.downloadEvaluationResult(this.talentId);
    this.downloadingAecdiscResult = false
  }

  releaseAecdiscResult() {
    this.loadingReleaseAecdiscResult = true
    this.consultantCoachingResource.approveTalentEvaluation(this.talentId).then((result) => {
      this.getTalentEvaluation();
      this.loadingReleaseAecdiscResult = false
    })
  }

    isActive(lastLogin?: Date): Boolean {
        if (!lastLogin) return false
        return new Date(lastLogin) >= this.sevenDaysAgo
    }

}
