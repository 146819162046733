<div id="main-component-wrapper">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="main-header">
        <h1>Einstellungen</h1>
    </div>

    <loading-spinner [loadingBool]="loading">
        <div fxLayout="row" class="content">
            <div fxFlex="10" class="settings-list">
                <button mat-button class="settingItem"
                        (click)="choseMenuSetting('notifications')"
                        [ngClass]="{'chosen-settings-item' : chosenSettingsItem == 'notifications'}"
                >E-Mail Benachrichtigungen
                </button>
                <button mat-button class="settingItem"
                        (click)="choseMenuSetting('account')"
                        [ngClass]="{'chosen-settings-item' : chosenSettingsItem == 'account'}">Account
                </button>
            </div>


            <div class="content-area mat-elevation-z2" [ngClass]="{'fadeIn': settingItemChanged == true}">
                <div fxLayout="row" fxLayoutAlign="space-between center"
                     class="content-header dark-blue-background layout-padding">
                    <div *ngIf="chosenSettingsItem == 'account'">Account</div>
                    <div *ngIf="chosenSettingsItem == 'notifications'">E-Mail Benachrichtigungen</div>
                    <div fxLayout="row" fxLayoutGap="10px" class="actions">
                    </div>
                </div>


                <div class="layout-padding" *ngIf="chosenSettingsItem == 'account'">
                    <change-password></change-password>
                </div>

                <div class="layout-padding" *ngIf="chosenSettingsItem == 'notifications'">
                    <div class="hint m-bottom15">
                        TALENTS schickt dir zu verschiedenen Ereignissen
                        Benachrichtigungen per E-Mail. Hier kannst du einstellen, welche Benachrichtigungen du erhalten
                        möchtest.
                    </div>

                    <email-notifications></email-notifications>
                </div>

            </div>
        </div>
    </loading-spinner>
</div>
