<loading-spinner [loadingBool]="loadingApplications">
    <table mat-table [dataSource]="applicationsData" matSort class="width-100">

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Datum</th>
            <td mat-cell *matCellDef="let element">{{element.creationDate | date: 'dd.MM.yy'}}</td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Stelle</th>
            <td mat-cell *matCellDef="let element" [matTooltip]="element.position.title"
                class="cell">{{element.position.title}}</td>
        </ng-container>

        <!-- Company Column -->
        <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Unternehmen</th>
            <td mat-cell *matCellDef="let element" [matTooltip]="element.companyName">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <img *ngIf="element.position.origin == 'Uniwunder'"
                         src="assets/images/uniwunder-logo.jpeg"
                         width="25" height="25"
                         class="m-right5" matTooltip="Vermittlung über Uniwunder GmbH">

                    <img *ngIf="element.position.origin == 'Campusjaeger'"
                         src="assets/images/campusjaeger-logo.jpg"
                         width="25" height="25"
                         class="m-right5" matTooltip="Vermittlung über Campusjäger GmbH">

                    <img *ngIf="element.position.origin == 'Pluss'"
                         src="assets/images/pluss.png"
                         width="25" height="25"
                         class="m-right5" matTooltip="Vermittlung über Pluss GmbH">

                    <img *ngIf="element.position.origin == 'Absolventa'"
                         src="assets/images/absolventa-gmb-h-logo-xl.png"
                         width="25" height="25"
                         class="m-right5" matTooltip="Vermittlung über Absolventa GmbH">
                    <div class="cell">{{element.companyName}}</div>
                </div>
            </td>
        </ng-container>

        <!-- State Column -->
        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status</th>
            <td mat-cell *matCellDef="let element"> {{element.state | applicationState }} </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let element">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <button mat-icon-button (click)="openPositionDetailsDialog(element.position.id)"><i
                            class="mdi mdi-eye"></i></button>
                </div>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div *ngIf="!loadingApplications && !applicationsData.length" class="text-center layout-padding-24-32">Das Talent hat sich bisher noch nicht beworben.</div>

</loading-spinner>
