import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {AccountPasswordChangeData, AccountDisplayData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.unisign.api.AccountController
 */

@Injectable()
@ResourceParams({})
export class AccountResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/account/me/password',
        endpoint: 'com.uniwunder.unisign'
    })
    private _changeOwnPassword: IResourceMethodObservableStrict<AccountPasswordChangeData, null, null, any> | undefined
    changeOwnPassword(requestBody: AccountPasswordChangeData): Promise<any> {
        if (!this._changeOwnPassword) throw new Error("resource has not been properly initialized")
        return this._changeOwnPassword(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/account/me',
        endpoint: 'com.uniwunder.unisign'
    })
    private _getOwnAccountData: IResourceMethodObservableStrict<null, null, null, AccountDisplayData> | undefined
    getOwnAccountData(): Promise<AccountDisplayData> {
        if (!this._getOwnAccountData) throw new Error("resource has not been properly initialized")
        return this._getOwnAccountData(null, null, null).toPromise()
    }

}