<form fxLayout="row" fxLayoutAlign="start center" [formGroup]="dateTimeFormGroup">
    <mat-form-field class="m-right40">
        <input matInput
               [placeholder]="placeholder"
               [matDatepicker]="picker"
               [min]="minDate"
               [max]="maxDate"
               required
               formControlName="innerDateControl">
        <mat-datepicker
            #picker
            required
            selectFirstDateOnClose="true"
        ></mat-datepicker>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    </mat-form-field>


    <div fxLayout="row" fxLayoutAlign="start center" id="wrapper">
        <i class="mdi mdi-alarm"></i>
        <mat-form-field class="input first">

            <input matInput required formControlName="innerHourControl" (blur)="reformatHourInput()" #hourInput
                   (focus)="clearHours()"
                   max="23" type="number"
                   min="0" autocomplete="off">
        </mat-form-field>

        <div id="point">:</div>


        <mat-form-field class="input">
            <input matInput formControlName="innerMinuteControl" (blur)="reformatMinuteInput()" #minuteInput type="number"
                   (focus)="clearMinutes()"
                   max="59" min="0" autocomplete="off" (keydown.backspace)="ifEmptyFocusHourInput()">
        </mat-form-field>
    </div>
</form>
