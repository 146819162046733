import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {TokenAction} from 'src/generated/data';
import {LoginLoggingResource} from 'src/generated/resources';
import {ConsultantAuthService} from "../service/consultant.auth.service";
import {catchError} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {throwError} from "rxjs";

@Component({
  selector: 'app-one-time-token-login',
  templateUrl: './one-time-token-login.component.html',
  styleUrls: ['./one-time-token-login.component.scss']
})
export class OneTimeTokenLoginComponent implements OnInit {

    error: string

    constructor(
        private activatedRoute: ActivatedRoute,
        private consultantAuthSerivce: ConsultantAuthService,
        private router: Router,
        private loginLoggingResource: LoginLoggingResource
    ) {
    }

    ngOnInit() {

        this.activatedRoute.queryParams.subscribe(params => {
            const loginCode = params['token'] // direct link
            const messageUuid = params['messageUuid'] // message identifier
            const authCode = params['code'] // authorization code (OAuth)
            const sessionAttribution =  params['sessionAttribution'] ?? params['utm_campaign']
            const tokenAction = params['reason'] ?? params['utm_source']

            if (loginCode) {
                this.consultantAuthSerivce.codeLogin(loginCode)
                    .pipe(
                        catchError((error: HttpErrorResponse) => {
                            this.error = error.error?.error ?? error.message
                            this.router.navigate(['/onetimetoken/expired'])
                            return throwError(() => new Error('invalid code'))
                        })
                    )
                    .subscribe(() => {
                        this.route(sessionAttribution, tokenAction, messageUuid)
                    })
            } else if (authCode) {
                const codeVerifier = localStorage.getItem("code_verifier")
                localStorage.removeItem("code_verifier")
                this.consultantAuthSerivce.oauthLogin(authCode, codeVerifier)
                    .pipe(
                        catchError((error: HttpErrorResponse) => {
                            this.error = error.error?.error ?? error.message
                            this.router.navigate(['/onetimetoken/expired'])
                            return throwError(() => new Error('oauth error'))
                        })
                    )
                    .subscribe(() => {
                        return this.route(sessionAttribution, tokenAction, messageUuid)
                    })
            }
        })
    }

    route(sessionAttribution, tokenAction: TokenAction, messageUuid?: string) {
        this.loginLoggingResource.trackUserLogin({origin: sessionAttribution, tokenAction: tokenAction, messageUuid: messageUuid})

        this.consultantAuthSerivce.reloadAccountInfo().then(() => {
            this.activatedRoute.queryParams.subscribe(params => {
                let redirectPath: string;
                switch (tokenAction) {
                    case "ChatMessages":
                        redirectPath = '/chat-overview'
                        break;
                    default:
                        redirectPath = '/dashboard'
                }

                this.router.navigateByUrl(redirectPath)

            })
        })
    }
}
