<div class="max1180 notification-wrapper layout-padding-48">
    <h1>Benachrichtigungen</h1>

    <loading-spinner [loadingBool]="loading">
        <div class="infinity-scroll-container"
             #notificationItems
             (scroll)="checkIfBottom($event)">
            <div class="notification-item" *ngFor="let notification of notifications" fxLayout="row"
                 fxLayoutAlign="space-between center"
                 [ngClass]="{'new-notification': notification.unseen}"
                 fxLayoutGap="5px"
                 style="margin-top: 5px; padding: 30px 25px">
                <div class="notification-header" fxLayout="column">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div class="notification-title">{{(notification.type | consultantNotification)? (notification.type | consultantNotification) : notification.shortMessage}}</div>
                        <div fxLayout="row" class="date-bar" fxLayoutAlign="end end" fxLayoutGap="3px">
                            <div class="date">{{notification.creationDate | date: 'dd.MM.yy'}} um</div>
                            <div class="date">{{notification.creationDate | date: 'HH:mm'}} Uhr</div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div class="notification-text" fxFlex="75">{{notification.message}}
                        </div>

                        <div fxLayoutAlign="end end" class="notification-link link-classic"
                             (click)="notificationService.performAppropriateClickAction(notification)"
                             fxFlex="20">{{notificationService.getActionLabel(notification)}}<i
                                class="mdi mdi-arrow-right link-icon"></i></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="notification-item" *ngIf="notifications.length == 0"
             fxLayoutAlign="center center">
            Keine Benachrichtigungen vorhanden
        </div>
    </loading-spinner>
</div>
