<dialog-header dialogHeadline="Wie kann ich Stellen empfehlen?"></dialog-header>
<div id="dialog-content-wrapper" fxLayout="column" fxLayoutAlign="space-between start">

    <div class="side-sheet-content">
        <iframe src="https://player.vimeo.com/video/639986105" style="width: 100%" height="300" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        <div class="width-100  layout-padding" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="50px">
            Du hast die Möglichkeit, deinen Talenten Stellen von Talentagent-Kunden zu empfehlen. Diese Stellen werden dem Talent dann im Talentagent als ein persönlicher Vorschlag von dir präsentiert. <br><br>
            Du kannst deine Kontakte so bei der Jobsuche unterstützen!<br>

            Außerdem erhältst du eine Incentivierung, wenn eines deiner Talente bei einem Talentagent-Kunden angestellt wird. <br><br> Wenn du es schaffst, dass mindestens 1/10 deiner betreuten Kontakte bei Kunden in den Job gehen, kannst du unbegrenzt neue Absolventen-Coachings über die Incentivierung erhalten. <br><br>
            Du kannst auch deine bestehenden Kontakte über deinen Einladungs-Link auf die Plattform holen und dort vermitteln - Auch das zählt! Du findest den Link in deinem "Talente"-Bereich.

            <br><br>
            Gehe auf das Profil eines Talents, wechsel auf den Reiter "Stellen empfehlen" und klicke anschließend auf den Button "Stellen empfehlen".
            Dort kannst du dann eine passende Stelle raussuchen und dem Talent mit einem Klick auf die kleine Sprechblase rechts empfehlen.
            Den Status deiner Empfehlung kannst du dann weiter auf den Talentprofil auf dem Reiter "Stellen empfehlen" verfolgen.
        </div>
    </div>

    <div class="dialog-action-bar" fxLayoutGap="10px">
        <button mat-button (click)="close()">schließen</button>
    </div>
</div>
