<dialog-header dialogHeadline="Terminslot erstellen"></dialog-header>
<div id="dialog-content-wrapper" fxLayout="column" fxLayoutAlign="space-between start">

    <div class="dialog-main-content layout-padding" [formGroup]="availabilityGroup">
        <p style="font-size: 12px; font-style: italic; color: #777">
            Erstelle hier deine Terminslots. Wenn die "Terminslot wiederholt sich jede Woche" auswählst, wird ab dem gewählten Datum, jede Woche um diese Zeit den Talenten dein Terminslot angezeigt</p>

        <div fxLayout="column" fxLayoutGap="20px">
                <date-time-select [control]="availabilityGroup.controls['date']" [minDate]="minDate"></date-time-select>

                <mat-form-field style="width: 180px">
                    <mat-label>Dauer deines Terminslots</mat-label>
                    <mat-select formControlName="duration">
                        <mat-option [value]="30">30 Minuten</mat-option>
                        <mat-option [value]="60">60 Minuten</mat-option>
                        <mat-option [value]="90">90 Minuten</mat-option>
                    </mat-select>
                </mat-form-field>

            <mat-checkbox formControlName="reoccurring">Terminslot wiederholt sich jede Woche</mat-checkbox>
        </div>

    </div>

    <div class="dialog-action-bar" fxLayoutGap="10px">
        <button mat-button (click)="close()">schließen</button>
        <loading-button [loadingBool]="saving" color="primary" (clickEvent)="save()">Terminslot speichern</loading-button>
    </div>
</div>
