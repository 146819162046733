import {Component, OnInit} from '@angular/core';
import {SideSheetRef} from "../../utility/side-sheet/sideSheetRef";

export interface ConfirmDialogData {
  message: string;
  confirmButtonText: string; //Optional
  title: string //optional
  confirmButtonColor: string // Optional
  description: string // Optional
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  confirming: Boolean = false;
  data: ConfirmDialogData;

  constructor(
      public sideSheetRef: SideSheetRef,
  ) {
  }

  ngOnInit() {
  }

  confirm() {
    this.confirming = true
    this.sideSheetRef.sheetClosed.next(this.data)
  }

  close() {
    this.sideSheetRef.sheetClosed.next(false);
  }

}
