import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SideSheetBackArrowDirective} from "./side-sheet-back-arrow.directive";
import {SideSheetCloseButtonDirective} from './side-sheet-close-button.directive';


@NgModule({
  declarations: [
    SideSheetBackArrowDirective,
    SideSheetCloseButtonDirective
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    SideSheetBackArrowDirective,
    SideSheetCloseButtonDirective,
  ]
})
export class SideSheetModule {
}
