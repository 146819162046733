import {Component, Input, OnInit} from '@angular/core';
import {ConsultantMatchesFilterData, ConsultantTalentPositionData} from "../../../generated/data";
import {ConsultantTalentResource} from "../../../generated/resources";
import {RecommendPositionDialogComponent} from "../recommend-position-dialog/recommend-position-dialog.component";
import {environment} from "../../../environments/environment";
import {DialogService} from "../../utility/side-sheet/dialog.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {SideSheetRef} from "../../utility/side-sheet/sideSheetRef";

@Component({
  selector: 'app-talent-matches-recommendation-dialog',
  templateUrl: './talent-matches-recommendation-dialog.component.html',
  styleUrls: ['./talent-matches-recommendation-dialog.component.scss']
})
export class TalentMatchesRecommendationDialogComponent implements OnInit {

  loading: boolean = true
  result: boolean = false

  states = [
    'New',
    'Seen',
    'Clicked',
    'Bookmarked',
    'CTAClicked'
  ]
  filterForm: FormGroup

  @Input() talentId: number;

  public talentPositionsData: ConsultantTalentPositionData[];

  public displayedColumns: string[] = ['position', 'company', 'action'];

  constructor(
      private talentResource: ConsultantTalentResource,
      private dialogService: DialogService,
      private fb: FormBuilder,
      private sideSheetRef: SideSheetRef
  ) { }

  ngOnInit(): void {
    this.initForm()
    this.getMatches()
  }

  initForm() {
    this.filterForm = this.fb.group({
      states: new FormControl(this.states, Validators.required),
      origin: new FormControl(true, Validators.required)
    })
  }

  getMatches() {
    this.loading = true

    let filterData = <ConsultantMatchesFilterData>{
      onlyCustomerPositions: this.filterForm.controls.origin.value,
      states: this.filterForm.controls.states.value
    }

    this.talentResource.getTalentPositionMatchesByFilter(filterData, this.talentId).then(positions => {
      this.talentPositionsData = positions;
      this.loading = false
    })
  }

  openRecommendPositionDialog(position: ConsultantTalentPositionData) {
    const comp = this.dialogService.openOverlay(RecommendPositionDialogComponent, '600px')
    comp.instance.position = position
    comp.instance.talentId = this.talentId

    let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(result => {
      if (result) {
        this.getMatches()
        this.result = true
      }
      subscription.unsubscribe();
    });
  }

  close() {
    this.sideSheetRef.sheetClosed.next(this.result)
  }

  openPositionDetailsDialog(posId: number) {
    window.open(environment.publicFrontendUrl + '/position-details/' + posId, '_blank')
  }
}
