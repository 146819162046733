import {Component, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {DialogService} from '../side-sheet/dialog.service';
import {ConsultantNotificationResource} from '../../../generated/resources';
import {NotificationData} from '../../../generated/data';
import {NotificationService} from '../../service/notification.service';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'notifications-menu',
  templateUrl: './notifications-menu.component.html',
  styleUrls: ['./notifications-menu.component.scss']
})
export class NotificationsMenuComponent implements OnInit {

  notifications: NotificationData[] = [];
  loadingNotifications: boolean = false;

  today: Date = new Date();

  @Input() menuOpenedSubject: Subject<boolean>;

  constructor(
      private router: Router,
      private dialogService: DialogService,
      private consultantNotificationResource: ConsultantNotificationResource,
      private notificationService: NotificationService,
      private datePipe: DatePipe,
  ) {
  }

  ngOnInit(): void {
    this.menuOpenedSubject.subscribe(next => {
      this.getTop5Notifications();
    });
  }

  getTop5Notifications() {
    this.loadingNotifications = true;
    this.consultantNotificationResource.getTop5NotificationsForConsultant().then(result => {
          this.notifications = result;
          this.loadingNotifications = false;
        }
    );
  }

  getCreationDateString(creationDate: Date): string {
    let date = new Date(creationDate);
    if ((date.getFullYear() == this.today.getFullYear()) && date.getMonth() == this.today.getMonth() && date.getDate() == this.today.getDate()) {
      return this.datePipe.transform(date, 'HH:mm');
    } else {
      return this.datePipe.transform(date, 'dd.MM.yy');
    }
  }
}
