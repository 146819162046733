import {Component, Input, OnInit} from '@angular/core';
import {delay} from '../../service/utility-service';

@Component({
    selector: 'loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {


    @Input() loadingBool: Boolean;
    @Input() hint: string;
    @Input() hints: string[];
    @Input() smaller: Boolean = false;


    constructor() {
    }

    ngOnInit() {
        if (this.hints) {
            this.animate();
        }
    }

    animationStep = 0;

    animate() {
        if (!this.hints[this.animationStep]) {
            return;
        }
        this.hint = this.hints[this.animationStep];
        delay(5000).then(() => {
            this.animationStep++;
            this.animate();
        })
    }

    ngAfterViewInit(): void {

    }

}
