import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ConsultantTalentPositionData, CreateConsultantAvailabilityData} from "../../../../generated/data";
import {SideSheetRef} from "../../../utility/side-sheet/sideSheetRef";
import {ConsultantAppointmentResource, ConsultantPrimeRecommendationResource} from "../../../../generated/resources";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-create-consultant-availability-dialog',
  templateUrl: './create-consultant-availability-dialog.component.html',
  styleUrls: ['./create-consultant-availability-dialog.component.scss']
})
export class CreateConsultantAvailabilityDialogComponent implements OnInit {


  saving: boolean = false
  availabilityGroup: FormGroup

  minDate

  // weekDays : {
  //   dayOfWeek: string,
  //   value: number,
  //   title: string
  // }[] = [
  //   {dayOfWeek: "MONDAY", title: "Montag", value: 1},
  //   {dayOfWeek: "TUESDAY", title: "Dienstag", value: 2},
  //   {dayOfWeek: "WEDNESDAY", title: "Mittwoch", value: 3},
  //   {dayOfWeek: "THURSDAY", title: "Donnerstag", value: 4},
  //   {dayOfWeek: "FRIDAY", title: "Freitag", value: 5},
  //   {dayOfWeek: "SATURDAY", title: "Samstag", value: 6},
  //   {dayOfWeek: "SUNDAY", title: "Sonntag", value: 0},
  // ]

  constructor(
      private fb: FormBuilder,
      private sideSheetRef: SideSheetRef,
      private matSnackBar: MatSnackBar,
      private consultantAppointmentResource: ConsultantAppointmentResource
  ) {
    let now = new Date()
    this.minDate = new Date(now.getFullYear(), now.getMonth(), now.getDate()+1, 6, 0, 0, 0)
  }

  ngOnInit(): void {
    this.initForm()
  }

  initForm() {
    this.availabilityGroup = this.fb.group({
      duration: [60, Validators.required],
      reoccurring: [true, Validators.required],
      date: [this.minDate, Validators.required]
    })
  }

  close(result: Boolean = false) {
    this.sideSheetRef.sheetClosed.next(result)
  }

  save() {
    this.saving = true

    let data: CreateConsultantAvailabilityData = {
      duration: this.availabilityGroup.controls.duration.value,
      reoccurring: this.availabilityGroup.controls.reoccurring.value,
      time:  this.availabilityGroup.controls.date.value
    }

    this.consultantAppointmentResource.createConsultantAvailability(data).then(result => {
      if (result?.value) {
        this.matSnackBar.open("Neue Verfügbarkeit erstellt!", null, {duration: 3000})
        this.close(true)
      } else {
        this.matSnackBar.open("Fehler, zu diesem Zeitpunkt existiert bereits ein aktiver Terminslot!", null, {duration: 6000})
        this.close(false)
      }
    })
  }

}
