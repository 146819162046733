<dialog-header dialogHeadline="Talent als eingestellt markieren"></dialog-header>
<div id="dialog-content-wrapper" fxLayout="column" fxLayoutAlign="space-between start">

    <loading-spinner [loadingBool]="!applicationsLoaded">
    <div class="dialog-main-content layout-padding">
        <div class="grey m-bottom40">
            Hier kannst du Talente als eingestellt markieren,
            damit das Uniwunder-Team den Fall prüfen kann und du die Möglichkeit auf deine 10 Kontakte
            erhältst. Die Prüfung kann dann einige Tage in Anspruch nehmen und du wirst per
            Notification informiert, wenn die Einstellung bestätigt wurde.
            <br><br>
            Bei Fragen kannst du dich an <a
                style="text-decoration: none"
                href="mailto:onlineakquise@mlp.de">onlineakquise@mlp.de</a> wenden.
        </div>

        <div>Wähle die Bewerbung aus, welche zu einer Einstellung geführt hat.</div>

        <mat-form-field class="width-100 m-top10">
            <mat-label>Bewerbung</mat-label>
            <mat-select panelClass="mat-select-panel-class" [formControl]="applicationCtr" [disabled]="!applications.length && applicationsLoaded">
                <mat-option *ngFor="let a of applications" [value]="a">
                    <div fxLayout="column" class="option">
                        <div>{{a.positionTitle}}</div>
                        <div class="company-name">{{a.companyName}}</div>
                    </div>
<!--                    <div fxLayout="row">-->
<!--                        <div class="width-50">{{a.positionTitle}}</div>-->
<!--                        <div class="width-50">{{a.companyName}}</div>-->
<!--                    </div>-->
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="warn text-center m-top30" *ngIf="!applications.length && applicationsLoaded">
            Du kannst dieses Talent nicht als eingestellt markieren, da es keine offene Bewerbung hat!
        </div>
    </div>
    </loading-spinner>

    <div class="dialog-action-bar" fxLayoutGap="10px">
        <button mat-button (click)="close()">schließen</button>
        <button mat-flat-button color="primary" [disabled]="applicationCtr.invalid" (click)="markTalentAsHired()">Speichern</button>
    </div>
</div>

