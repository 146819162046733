import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {UtilityService} from "../../service/utility-service";
import {AccountResource} from "../../../generated/resources";
import {AccountResource as UniSignAccountResource,} from '../../../generated/unisign/resources';
import {AccountPasswordChangeData} from "../../../generated/unisign/data";

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {


  saving: boolean = false
  showIncorretPassword: boolean = false
  passwordForm: FormGroup

  constructor(
      private fb: FormBuilder,
      private utilityService: UtilityService,
      private accountResource: AccountResource,
      private uniSignAccountResource: UniSignAccountResource
  ) { }

  ngOnInit(): void {
    this.createForm()
  }

  createForm() {
    this.passwordForm = this.fb.group({
      oldPassword: new FormControl(null, [Validators.required]),
      newPassword: new FormControl(null, [Validators.required, Validators.min(8)]),
      newPasswordRepeat: new FormControl(null, [Validators.required, Validators.min(8)])
    })
  }

  isPasswordLongEnough(): boolean {
    return (this.passwordForm.controls.newPassword.value && this.passwordForm.controls.newPassword.value.length > 7)
  }

    passwordRequirementsAreTrue() {
        let boolArray = [
            this.containsSmallLetter(),
            this.containsBigLetter() || this.containsNumber(),
        ]
        return boolArray.filter(bool => bool == true).length > 1
    }

  containsSmallLetter(): boolean {
    if (this.passwordForm.controls.newPassword.value == null) return false
    return (/[a-z]/.test(this.passwordForm.controls.newPassword.value));
  }

  containsBigLetter() {
    if (this.passwordForm.controls.newPassword.value == null) return false
    return (/[A-Z]/.test(this.passwordForm.controls.newPassword.value));
  }

  containsNumber() {
    if (this.passwordForm.controls.newPassword.value == null) return false
    return (/[0-9]/.test(this.passwordForm.controls.newPassword.value));
  }

  containsSpecialCharacter() {
    if (this.passwordForm.controls.newPassword.value == null) return false
    return (/[!@#$%^&*(),.?":{}|<>+§=\-]/.test(this.passwordForm.controls.newPassword.value));
  }

  passwordsEqual(): boolean {
    return this.passwordForm.controls.newPassword.value == this.passwordForm.controls.newPasswordRepeat.value
  }

  isValid() {
    return (this.passwordForm.valid && this.passwordsEqual() && this.passwordRequirementsAreTrue())
  }

  updatePassword() {
    this.saving = true

    let data: AccountPasswordChangeData = {
      newPassword: this.passwordForm.controls.newPassword.value,
      currentPassword: this.passwordForm.controls.oldPassword.value
    }
    this.uniSignAccountResource.changeOwnPassword(data).then(result => {
      if (result.success) {
        this.utilityService.showSnackBar("Passwort erfolgreich gespeichert.")
        this.saving = false

        this.passwordForm.controls.newPassword.setValue(null)
        this.passwordForm.controls.newPasswordRepeat.setValue(null)
        this.passwordForm.controls.oldPassword.setValue(null)
        this.passwordForm.controls.newPassword.markAsUntouched()
        this.passwordForm.controls.newPasswordRepeat.markAsUntouched()
        this.passwordForm.controls.oldPassword.markAsUntouched()

        this.showIncorretPassword = false

      } else {
        this.showIncorretPassword = true
        this.saving = false
      }
    })
  }

}
