import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ConsultantNotificationResource} from '../../generated/resources';
import {NotificationData} from 'src/generated/data';
import {NotificationService} from '../service/notification.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  @ViewChild('notificationItemContainer') private notificationItemContainer: ElementRef;
  @ViewChild('notificationItems', {read: ElementRef}) notificationItems: ElementRef;

  pageNum: number = 0;
  pageSize: number = 10;
  totalElements: number = 0;
  totalPages: number = 0;

  notifications: NotificationData[] = [];
  loading: boolean = true;

  constructor(
      private router: Router,
      private consultantNotificationResource: ConsultantNotificationResource,
      public notificationService: NotificationService
  ) {
  }

  ngOnInit() {
    this.load();
  }

  load() {
    this.loading = true;
    this.consultantNotificationResource.getNotificationsForConsultant({page: this.pageNum, pageSize: this.pageSize})
        .then(result => {
          this.notifications = this.notifications.concat(result.content);
          this.totalElements = result.totalElements;
          this.totalPages = result.totalPages;
          this.loading = false;
          this.pageNum += 1;
        });
  }

  checkIfBottom(event) {
    if (this.notificationItems.nativeElement.scrollTop == this.notificationItems.nativeElement.scrollHeight - this.notificationItems.nativeElement.clientHeight) {
      this.load();
    }
  }

}
