<div class="text-center">
    <h1><b>Verpflichtung zur Einhaltung des Datenschutzrechts</b></h1>
    <p class="layout-padding-24-32">gegenüber der</p>

    <div class="m-bottom40">
        <span><b>Uniwunder GmbH</b></span><br/>
        <span>Bergstraße 29</span><br/>
        <span>01069 Dresden</span><br/>
    </div>


    <div class="content-wrapper">
        <p>
            Als Karrierecoach werden Dir Kundendaten der Uniwunder GmbH übertragen. Unsere Verpflichtung
            gegenüber den Kunden, stets auf die Einhaltung des Datenschutzes zu achten, gilt auch für Dich.
            Hiermit sicherst Du zu, die entsprechenden Regelungen einzuhalten und die Daten allein dazu zu
            verwenden, den jeweiligen Kundenauftrag auszuführen.
        </p>

        <p>
            Im Einzelnen: Die einschlägigen gesetzlichen Vorschriften verlangen, dass personenbezogene Daten
            so verarbeitet werden, dass die Rechte der durch die Verarbeitung betroffenen Personen auf
            Vertraulichkeit und Integrität ihrer Daten gewährleistet werden.
            Daher ist es nur gestattet, personenbezogene Daten in dem Umfang und in der Weise zu verarbeiten,
            wie es zur Erfüllung der Dir übertragenen Aufgaben erforderlich ist.
            Im Übrigen bist Du gem. Art. 5 Abs. 1 f, Art. 32 Abs. 4 Datenschutz-Grundverordnung (DSGVO)
            zur Vertraulichkeit verpflichtet. Es ist Dir untersagt, personenbezogene Daten unbefugt oder
            unrechtmäßig zu verarbeiten oder schuldhaft die Sicherheit der Verarbeitung in einer Weise zu
            verletzen, die zur Vernichtung, zum Verlust, zur Veränderung, zur unbefugten Offenlegung oder
            unbefugtem Zugang führt.
        </p>

        <p>Diese Verpflichtung besteht auch nach Beendigung Deiner Tätigkeit fort.</p>

        <p>
            Verstöße gegen die Vertraulichkeit können nach Art. 83 Abs. 4 DSGVO, §§ 42, 43 BDSG sowie nach
            anderen Strafvorschriften (s. Anlage) mit Freiheits- oder Geldstrafe geahndet werden.
        </p>

        <p>Beachte insbesondere folgende Vorschriften:</p>
    </div>

    <h3><b>Rechtliche Grundlagen</b></h3>

    <div class="content-wrapper">
        <p><b>Artikel 5 DS-GVO</b></p>
        <p>(1) Personenbezogene Daten müssen</p>

        <p>
            a) auf rechtmäßige Weise, nach Treu und Glauben und in einer für die betroffene Person
            nachvollziehbaren Weise verarbeitet werden („Rechtmäßigkeit, Verarbeitung nach Treu und Glauben,
            Transparenz“);
        </p>

        <p>
            b) für festgelegte, eindeutige und legitime Zwecke erhoben werden und dürfen nicht in einer mit
            diesen Zwecken nicht zu vereinbarenden Weise weiterverarbeitet werden; eine Weiterverarbeitung für
            im öffentlichen Interesse liegende Archivzwecke, für wissenschaftliche oder historische
            Forschungszwecke oder für statistische Zwecke gilt gemäß Artikel 89 Absatz 1 nicht als
            unvereinbar mit den ursprünglichen Zwecken („Zweckbindung“);
        </p>

        <p>
            c) dem Zweck angemessen und erheblich sowie auf das für die Zwecke der Verarbeitung notwendige Maß
            beschränkt sein („Datenminimierung“);
        </p>

        <p>
            d) sachlich richtig und erforderlichenfalls auf dem neuesten Stand sein; es sind alle angemessenen
            Maßnahmen zu treffen, damit personenbezogene Daten, die im Hinblick auf die Zwecke ihrer
            Verarbeitung unrichtig sind, unverzüglich gelöscht oder berichtigt werden („Richtigkeit“);
            4.5.2016 L 119/35 Amtsblatt der Europäischen Union DE (1)Richtlinie (EU) 2015/1535 des Europäischen
            Parlaments und des Rates vom 9. September 2015 über ein Informationsverfahren auf dem Gebiet der
            technischen Vorschriften und der Vorschriften für die Dienste der
            Informationsgesellschaft (ABl. L 241 vom 17.9.2015, S. 1).
        </p>

        <p>
            e) in einer Form gespeichert werden, die die Identifizierung der betroffenen Personen nur so
            lange ermöglicht, wie es für die Zwecke, für die sie verarbeitet werden, erforderlich ist;
            personenbezogene Daten dürfen länger gespeichert werden, soweit die personenbezogenen Daten
            vorbehaltlich der Durchführung geeigneter technischer und organisatorischer Maßnahmen, die von
            dieser Verordnung zum Schutz der Rechte und Freiheiten der betroffenen Person gefordert werden,
            ausschließlich für im öffentlichen Interesse liegende Archivzwecke oder für wissenschaftliche und
            historische Forschungszwecke oder für statistische Zwecke gemäß Artikel 89 Absatz 1 verarbeitet
            werden („Speicherbegrenzung“);
        </p>

        <p>
            f) in einer Weise verarbeitet werden, die eine angemessene Sicherheit der personenbezogenen
            Daten gewährleistet, einschließlich Schutz vor unbefugter oder unrechtmäßiger Verarbeitung und
            vor unbeabsichtigtem Verlust, unbeabsichtigter Zerstörung oder unbeabsichtigter Schädigung durch
            geeignete technische und organisatorische Maßnahmen („Integrität und Vertraulichkeit“);
        </p>

        <p><b>Art. 32 Abs. 4 DS-GVO </b></p>

        <p>
            (4) Der Verantwortliche und der Auftragsverarbeiter unternehmen Schritte, um sicherzustellen,
            dass ihnen unterstellte natürliche Personen, die Zugang zu personenbezogenen Daten haben,
            diese nur auf Anweisung des Verantwortlichen verarbeiten, es sei denn, sie sind nach dem Recht
            der Union oder der Mitgliedstaaten zur Verarbeitung verpflichtet.
        </p>

        <p><b>Art. 83 Abs. 4 DS-GVO </b></p>

        <p>
            (4) Bei Verstößen gegen die folgenden Bestimmungen werden im Einklang mit Absatz 2 Geldbußen von
            bis zu 10 000 000 EUR oder im Fall eines Unternehmens von bis zu 2 % seines gesamten weltweit
            erzielten Jahresumsatzes des vorangegangenen Geschäftsjahrs verhängt, je nachdem, welcher der
            Beträge höher ist: a) die Pflichten der Verantwortlichen und der Auftragsverarbeiter gemäß den
            Artikeln 8, 11, 25 bis 39, 42 und 43; b) die Pflichten der Zertifizierungsstelle gemäß den
            Artikeln 42 und 43; c) die Pflichten der Überwachungsstelle gemäß Artikel 41 Absatz 4.
        </p>

        <p><b>§ 42 BDSG</b></p>

        <p>
            (1) Mit Freiheitsstrafe bis zu drei Jahren oder mit Geldstrafe wird bestraft,
            wer wissentlich nicht allgemein zugängliche personenbezogene Daten einer großen Zahl von Personen,
            ohne hierzu berechtigt zu sein,
        </p>

        <ol>
            <li>einem Dritten übermittelt oder</li>
            <li>auf andere Art und Weise zugänglich macht</li>
        </ol>

        <p>und hierbei gewerbsmäßig handelt.</p>

        <p>
            (2) Mit Freiheitsstrafe bis zu zwei Jahren oder mit Geldstrafe wird bestraft,
            wer personenbezogene Daten, die nicht allgemein zugänglich sind,
        </p>

        <ol>
            <li>ohne hierzu berechtigt zu sein, verarbeitet oder</li>
            <li>durch unrichtige Angaben erschleicht</li>
        </ol>

        <p>
            und hierbei gegen Entgelt oder in der Absicht handelt, sich oder einen anderen zu bereichern
            oder einen anderen zu schädigen.
        </p>

        <p>
            (3) 1Die Tat wird nur auf Antrag verfolgt. 2Antragsberechtigt sind die betroffene Person,
            der Verantwortliche, die oder der Bundesbeauftragte und die Aufsichtsbehörde.
        </p>

        <p>
            (4) Eine Meldung nach Artikel 33 der Verordnung (EU) 679/2016 oder eine Benachrichtigung nach
            Artikel 34 Absatz 1 der Verordnung (EU) 679/2016 darf in einem Strafverfahren gegen den
            Meldepflichtigen oder Benachrichtigenden oder seine in § 52 Absatz 1 der Strafprozessordnung
            bezeichneten Angehörigen nur mit Zustimmung des Meldepflichtigen oder Benachrichtigenden
            verwendet werden.
        </p>

        <p><b>§ 43 BDSG</b></p>

        <p>(1) Ordnungswidrig handelt, wer vorsätzlich oder fahrlässig</p>

        <ol>
            <li>entgegen § 30 Absatz 1 ein Auskunftsverlangen nicht richtig behandelt oder</li>
            <li>entgegen § 30 Absatz 2 Satz 1 einen Verbraucher nicht, nicht richtig, nicht vollständig oder
                nicht rechtzeitig unterrichtet.
            </li>
        </ol>

        <p>(2) Die Ordnungswidrigkeit kann mit einer Geldbuße bis zu fünfzigtausend Euro geahndet werden.</p>

        <p>
            (3) Gegen Behörden und sonstige öffentliche Stellen im Sinne des § 2 Absatz 1 werden keine Geldbußen
            verhängt.
        </p>

        <p>
            (4) Eine Meldung nach Artikel 33 der Verordnung (EU) 679/2016 oder eine Benachrichtigung
            nach Artikel 34 Absatz 1 der Verordnung (EU) 679/2016 darf in einem Verfahren nach dem Gesetz
            über Ordnungswidrigkeiten gegen den Meldepflichtigen oder Benachrichtigenden oder seine in
            § 52Absatz 1 der Strafprozessordnung bezeichneten Angehörigen nur mit Zustimmung des
            Meldepflichtigen oder Benachrichtigenden verwendet werden.
        </p>

        <h4><b>Strafgesetzbuch (StGB): </b></h4>

        <p><b>§ 202a Ausspähen von Daten </b></p>

        <p>
            (1) Wer unbefugt sich oder einem anderen Zugang zu Daten, die nicht für ihn bestimmt und die
            gegen unberechtigten Zugang besonders gesichert sind, unter Überwindung der Zugangssicherung
            verschafft, wird mit Freiheitsstrafe bis zu drei Jahren oder mit Geldstrafe bestraft.
            (2) Daten im Sinne des Absatzes 1 sind nur solche, die elektronisch, magnetisch oder sonst
            nicht unmittelbar wahrnehmbar gespeichert sind oder übermittelt werden.
        </p>

        <p>
            § 202b Abfangen von Daten Wer unbefugt sich oder einem anderen unter Anwendung von technischen
            Mitteln nicht für ihn bestimmte Daten (§ 202a Abs. 2) aus einer nichtöffentlichen
            Datenübermittlung oder aus der elektromagnetischen Abstrahlung einer Datenverarbeitungsanlage
            verschafft, wird mit Freiheitsstrafe bis zu zwei Jahren oder mit Geldstrafe bestraft,
            wenn die Tat nicht in anderen Vorschriften mit schwererer Strafe bedroht ist.
        </p>

        <p>
            § 202c Vorbereiten des Ausspähens und Abfangens von Daten (1) Wer eine Straftat nach § 202a oder
            § 202b vorbereitet, indem er 1. Passwörter oder sonstige Sicherungscodes, die den Zugang zu
            Daten (§ 202a Abs. 2) ermöglichen, oder 2. Computerprogramme, deren Zweck die Begehung einer
            solchen Tat ist, herstellt, sich oder einem anderen verschafft, verkauft, einem anderen überlässt,
            verbreitet oder sonst zugänglich macht, wird mit Freiheitsstrafe bis zu zwei Jahren oder mit
            Geldstrafe bestraft.
        </p>

        <p>(2) § 149 Abs. 2 und 3 gilt entsprechend. </p>

        <p><b>§ 202d Datenhehlerei</b></p>

        <p>
            (1) Wer Daten (§ 202a Absatz 2), die nicht allgemein zugänglich sind und die ein anderer durch
            eine rechtswidrige Tat erlangt hat, sich oder einem anderen verschafft, einem anderen überlässt,
            verbreitet oder sonst zugänglich macht, um sich oder einen Dritten zu bereichern oder einen
            anderen zu schädigen, wird mit Freiheitsstrafe bis zu drei Jahren oder mit Geldstrafe bestraft.
        </p>

        <p>
            (2) Die Strafe darf nicht schwerer sein als die für die Vortat angedrohte Strafe.
        </p>

        <p>
            (3) Absatz 1 gilt nicht für Handlungen, die ausschließlich der Erfüllung rechtmäßiger dienstlicher
            oder beruflicher Pflichten dienen. Dazu gehören insbesondere 1. solche Handlungen von Amtsträgern
            oder deren Beauftragten, mit denen Daten ausschließlich der Verwertung in einem
            Besteuerungsverfahren, einem Strafverfahren oder einem Ordnungswidrigkeitenverfahren zugeführt
            werden sollen, sowie 2. solche beruflichen Handlungen der in § 53 Absatz 1 Satz 1 Nummer 5 der
            Strafprozessordnung genannten Personen, mit denen Daten entgegengenommen, ausgewertet oder
            veröffentlicht werden.
        </p>
    </div>
</div>
