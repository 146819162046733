export const environment = {
    production: true,
    apiHost: '$$API_HOST$$',
    apiUrl: 'https://$$API_HOST$$/main',
    publicFrontendUrl: 'https://$$PUBLIC_FE$$',
    adaptFrontendUrl: 'https://$$ADAPT_FE$$',
    talentFrontendLoginPath: 'https://$$TALENTAGENT_FRONTEND_URL$$/talent/login',
    frontendUnisignClientId: '$$FRONTEND_UNISIGN_CLIENT$$',
    uniSignUrl: 'https://$$UNISIGN_HOST$$',
    uniSignHost: '$$UNISIGN_HOST$$',
    appLoginRedirectUri: 'https://$$MLP_FE_URL$$/login',
};
