<div id="main-component-wrapper">

    <div fxLayout="row" fxLayoutAlign="space-between center">
        <h1 style="padding-bottom: 20px; font-size: 30px">Terminanfragen und Terminslots</h1>
        <button mat-button style="margin-bottom: 16px" color="primary" (click)="openAppointentTutorialVideo()">Tutorial Video anschauen</button>
    </div>

    <div class="mat-elevation-z3">
        <mat-tab-group [(selectedIndex)]="tabIndex">
            <mat-tab label="Terminanfragen">
                <appointment-requests></appointment-requests>
            </mat-tab>
            <mat-tab label="Deine Terminslots">
                <consultant-availability></consultant-availability>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

