import {AuthService} from "./auth.service";
import {AccountInfoData} from "../../generated/data";
import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {tap} from "rxjs/operators";

@Injectable()
export class ConsultantAuthService extends AuthService {
  isAuthenticated() {
    const info = super.getAccountInfo();
    return info != null && info.role == "Consultant" && super.isAuthenticated()
  }

  login(email: string, password: string) {
    return super.login(email, password, "Consultant")
  }

  getAccountInfo() {
    return <AccountInfoData>super.getAccountInfo();
  }

    codeLogin(code: string) {
        const formData = new FormData();
        formData.append('client_id', environment.frontendUnisignClientId)
        formData.append('grant_type', "urn:ietf:params:oauth:grant-type:custom_code")
        formData.append('code', code)
        return this.http.post(environment.uniSignUrl + "/oauth2/token", formData).pipe(
            tap((response: any) => {
                this.setToken(response['access_token'], response['refresh_token'])
                this.applyLogin("LoginSuccessful")
            })
        )
    }

    oauthLogin(code: string, codeVerifier: string | null) {
        const formData = new FormData();
        formData.append('client_id', environment.frontendUnisignClientId)
        formData.append('grant_type', "authorization_code")
        formData.append('redirect_uri', environment.appLoginRedirectUri)
        formData.append('code', code)
        if (codeVerifier) {
            formData.append('code_verifier', codeVerifier)
        }
        return this.http.post(environment.uniSignUrl + "/oauth2/token", formData).pipe(
            tap((response: any) => {
                this.setToken(response['access_token'], response['refresh_token'])
                this.applyLogin("LoginSuccessful")
            })
        )
    }
}
