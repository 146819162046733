<div class="minimal-chat-wrapper" [ngClass]="{'unseen': chatMinimalData?.existsUnseen}" fxLayout="row" fxLayoutAlign="start center">

    <div class="unseen-dot" *ngIf="chatMinimalData?.existsUnseen"></div>

    <div id="profile-picture" fxLayout="row" fxLayoutAlign="center center"
        [ngStyle]="{'background-image': profilePictureUrl}">
    </div>

    <div class="width-100" fxLayout="column" fxLayoutAlign="space-between start">
        <div class="width-100" fxLayout="row" fxLayoutAlign="space-between baseline">
            <div class="name">
                {{chatService.getChatPartnerByChat(chatMinimalData)?.name}}
            </div>
            <div class="last-date" *ngIf="chatMinimalData.lastMessage">
                {{chatMinimalData.lastMessage.occurred | date: 'dd.MM.yyyy'}}
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
            <p class="last-message">
                {{lastMessage}}
            </p>
        </div>
    </div>

</div>