import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ConsultantAuthService} from '../service/consultant.auth.service';
import {Router} from '@angular/router';
import {ConsultantAccountResource} from '../../generated/resources';
import {UnisignService} from "../service/unisign.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    error: boolean = false
    errorMessage: string = ''

    public loginForm: FormGroup;
    public loadingPerformLogin: boolean;

    private state: "LoginSuccessful" | "LoginFailedCredentialsIncorrect" | "LoginFailedRoleIncorrect" | "LoginExpired" | "Logout" | "TokenUpdated";

    constructor(
        private authService: ConsultantAuthService,
        private consultantAccountResource: ConsultantAccountResource,
        private router: Router,
        private fb: FormBuilder,
        private unisignService: UnisignService
    ) {
    }

    ngOnInit(): void {
        if (this.authService.isAuthenticated()) {
            this.router.navigateByUrl('dashboard');
        }
        this.initForm();
    }

    initForm(): void {
        this.loginForm = this.fb.group({
            username: new FormControl(null, [Validators.required]),
            password: new FormControl(null, [Validators.required])
        })
    }

    performLogin(): void {
        this.loadingPerformLogin = true;

        this.errorMessage = ''
        this.error = false

        this.authService.login(this.loginForm.controls.username.value, this.loginForm.controls.password.value).subscribe(
            (state) => {
                this.state = state;

                if (state == 'LoginSuccessful' || state == 'TokenUpdated') {
                    this.consultantAccountResource.hasConsultantOnboardingCompleted().then(result => {
                        if (result.value) {

                            this.consultantAccountResource.hasConsultantAcceptedPrivacyAgreement().then(result => {
                                if (result.value) {
                                    this.router.navigateByUrl('dashboard');
                                } else {
                                    this.router.navigateByUrl('privacy');
                                }
                            });

                        } else {
                            this.router.navigateByUrl('onboarding');
                        }
                    })

                } else {
                    this.error = true
                    this.errorMessage = "E-Mail-Adresse oder Passwort falsch."

                    this.loginForm.controls.password.setValue("");
                    this.loginForm.controls.username.setErrors({'incorect': true});
                    this.loginForm.controls.password.setErrors({'incorect': true});
                }
                this.loadingPerformLogin = false;
            }
        );
    }

    openSendNewPasswordDialog() {
        this.unisignService.routeToForgotPassword()
    }
}
