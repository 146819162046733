import {
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewContainerRef
} from '@angular/core';
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatButton} from "@angular/material/button";


@Directive({
  selector: '[loadingBool]'
})
export class LoadingButtonDirective implements OnInit{

  progressElement: any
  loadingState: boolean
  disabledState: boolean

  @Input() set disabled(value: boolean) {
    this.disabledState = value
    this.updateButtonStatus()
  }

  @Input() set loadingBool(value: boolean) {
    this.loadingState = value
    this.updateButtonStatus()
  }

  constructor(
      private matButton: MatButton,
      private componentFactoryResolver: ComponentFactoryResolver,
      private viewContainerRef: ViewContainerRef,
      private renderer: Renderer2,
      private elementRef: ElementRef
  ) {
  }

  ngOnInit(): void {
    const element: HTMLButtonElement = this.elementRef.nativeElement;
    if (element.tagName.toLowerCase() !== 'button') {
      return;
    }

    this.loadComponent()
  }

  loadComponent() {
    this.viewContainerRef.clear()

    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(MatProgressSpinner);

    let matProgress = this.viewContainerRef.createComponent(componentFactory);
    matProgress.instance.mode = "indeterminate"
    matProgress.instance.diameter = 20
    matProgress.instance.strokeWidth = 3
    matProgress.instance.color = "primary"
    matProgress.instance._elementRef.nativeElement.style.position = "absolute"
    matProgress.instance._elementRef.nativeElement.style.opacity = "0"
    matProgress.instance._elementRef.nativeElement.style.top = "calc(50% - 10px)"
    matProgress.instance._elementRef.nativeElement.style.left = "calc(50% - 10px)"

    this.progressElement = matProgress.injector.get(MatProgressSpinner)._elementRef.nativeElement
    this.renderer.appendChild(this.elementRef.nativeElement, this.progressElement)
    this.updateButtonStatus()
  }

  updateButtonStatus() {
    if (this.loadingState || this.disabledState) this.matButton.disabled = true
    if (!this.loadingState && !this.disabledState) this.matButton.disabled = false

    this.loadingState? this.show() :this.hide()
  }

  show() {
    if (!this.progressElement) return
    this.progressElement.style.opacity = '0.7';
    this.progressElement.style.display = 'block';
  }

  hide() {
    if (!this.progressElement) return
    this.progressElement.style.display = 'none';
    this.progressElement.style.opacity = '0';
  }

}