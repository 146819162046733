<loading-spinner [loadingBool]="loading">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="action-bar">

        <div class="recommend-info" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
            <i class="mdi mdi-information-outline"></i>
            <div>
                Empfiehl deinem Talent hier passende Stellen! Wird dein Kontakt beim Talentagent-Kunden angestellt, qualifizierst du dich für die Incentivierung!
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
            <button mat-button (click)="openRecommendationInfoDialog()" color="primary">Wie funktioniert das?</button>
            <button mat-flat-button (click)="openRecommendPositionDialog()" color="primary">Stellen empfehlen</button>
        </div>
    </div>
    <div *ngIf="talentPositionsData?.length > 0">
    <div class="headline">Abgeschickte Empfehlungen:</div>
    <table mat-table [dataSource]="talentPositionsData" matSort class="width-100">

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Datum</th>
            <td mat-cell *matCellDef="let element"> {{element.recommendedDate | date: 'dd.MM.yy HH:mm'}} </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Stelle</th>
            <td mat-cell *matCellDef="let element"> {{element.positionTitle}} </td>
        </ng-container>

        <!-- Company Column -->
        <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Unternehmen</th>
            <td mat-cell *matCellDef="let element">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <img *ngIf="element.positionOrigin == 'Uniwunder'"
                         src="assets/images/uniwunder-logo.jpeg"
                         width="25" height="25"
                         class="m-right5" matTooltip="Vermittlung über Uniwunder GmbH">

                    <img *ngIf="element.positionOrigin == 'Campusjaeger'"
                         src="assets/images/campusjaeger-logo.jpg"
                         width="25" height="25"
                         class="m-right5" matTooltip="Vermittlung über Campusjäger GmbH">

                    <img *ngIf="element.positionOrigin == 'Pluss'"
                         src="assets/images/pluss.png"
                         width="25" height="25"
                         class="m-right5" matTooltip="Vermittlung über Pluss GmbH">

                    <img *ngIf="element.positionOrigin == 'Absolventa'"
                         src="assets/images/absolventa-gmb-h-logo-xl.png"
                         width="25" height="25"
                         class="m-right5" matTooltip="Vermittlung über Absolventa GmbH">
                    {{element.companyName}}
                </div>
            </td>
        </ng-container>

        <!-- State Column -->
        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status</th>
            <td mat-cell *matCellDef="let element"> {{element.state | state }} </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let element">
                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
                    <button mat-icon-button (click)="openPositionDetailsDialog(element.positionId)"><i
                            class="mdi mdi-eye"></i></button>
                </div>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    </div>
    <div *ngIf="!loading && !talentPositionsData?.length" class="text-center layout-padding-24-32">Du hast noch keine Stelle empfohlen. Klicke auf "Stellen empfehlen" um loszulegen!</div>
    <button mat-flat-button *ngIf="talentPositionsData?.length > 10"(click)="openRecommendPositionDialog()" color="primary">Mehr Stellen empfehlen</button>
</loading-spinner>
