import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'state'
})
export class StatePipe implements PipeTransform {

  transform(value: any): any {
    switch (value) {
      case 'New':
      case 'Seen':
      case 'Clicked':
      case 'Bookmarked':
      case 'CTAClicked':
        return 'Unbearbeitet'
      case 'Withdrawn':
        return 'Stelle nicht mehr verfügbar'
      case 'Rejected':
        return 'Abgelehnt'
      case 'Accepted':
        return 'Beworben'
      default: return value
    }
  }
}
