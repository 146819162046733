import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FileService} from '../service/file.service';
import {DomSanitizer} from '@angular/platform-browser';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConsultantProfileData} from '../../generated/data';
import {ConsultantProfileResource} from '../../generated/resources';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-consultant-profile',
  templateUrl: './consultant-profile.component.html',
  styleUrls: ['./consultant-profile.component.scss']
})
export class ConsultantProfileComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public profilePictureCtrl: FormControl
  public profilePicture: any;
  public noProfilePictureAvailable: boolean = false;
  public loading: boolean = false;

  private aboutMeText: string = 'Als erfahrener Coach im Bereich Karriere und Finanzen unterstütze ich meine Kunden' +
      ' gesamtheitlich und achte auf Ihre individuellen Lebensziele';

  constructor(
      private fb: FormBuilder,
      public fileService: FileService,
      private sanitizer: DomSanitizer,
      private matSnackBar: MatSnackBar,
      private profileResource: ConsultantProfileResource,
  ) {
    this.form = fb.group({
      titleControl: null,
      aboutMeControl: null,
      phoneControl: [null, Validators.required],
      whatsAppControl: [null],
      felxpertoControl: [null, Validators.pattern('(https://).*')],
      emailControl: [null, [Validators.email, Validators.required]]
    })
  }

  ngOnDestroy(): void {

    }

  ngOnInit(): void {
    this.profilePictureCtrl = new FormControl(null, this.fileService.uploadFileControlValidator())
    this.profileResource.getOwnProfileData().then(profileData => {
      this.form.controls.emailControl.setValue(profileData.emailAddress)
      this.form.controls.phoneControl.setValue(profileData.phoneNumber)
      this.form.controls.whatsAppControl.setValue(profileData.whatsApp)
      this.form.controls.titleControl.setValue(profileData.title)
      this.form.controls.aboutMeControl.setValue(profileData.aboutMe);
      this.form.controls.felxpertoControl.setValue(profileData.flexpertoLink);
    });

    this.loadProfilePicture();


  }

  save() {
    const profileData: ConsultantProfileData = {
      emailAddress: this.form.controls.emailControl.value,
      phoneNumber: this.form.controls.phoneControl.value,
      whatsApp: this.form.controls.whatsAppControl.value,
      title: this.form.controls.titleControl.value,
      aboutMe: this.form.controls.aboutMeControl.value,
      flexpertoLink: this.form.controls.felxpertoControl.value
    }

    this.loading = true;
    this.profileResource.updateConsultant(profileData).then(() => {
      this.loading = false
      this.matSnackBar.open('Profil erfolgreich gespeichert', null, {duration: 3000});
    })
  }

    loadProfilePicture() {
        this.fileService.getConsultantProfilePicture().subscribe((result) => {
            if (result.size == 0) {
                this.noProfilePictureAvailable = true;
            } else {
                // this.profilePicture = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(result));
                this.profilePicture = URL.createObjectURL(result);
            }
        });
    }

  onFileChange(event) {
    this.profilePictureCtrl.setValue(event.target.files)
    this.profilePictureCtrl.setErrors(null)
    this.profilePictureCtrl.updateValueAndValidity()
    if (this.profilePictureCtrl.invalid) return

    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e: any) => {
        this.noProfilePictureAvailable = false;
        let blob = this.createImageFile(e.target.result, event.target.files[0].name)

        this.fileService.uploadConsultantProfilePicture(blob).subscribe(() => {
            this.profilePicture = URL.createObjectURL(blob);
            this.matSnackBar.open('Profilbild aktualisiert', null, {duration: 3000});
        });
      };
    }
  }

  createImageFile(file, fileName: string) {
    const imageBlob = this.dataURItoBlob(file.replace(RegExp('data.*base64,'), ''));
    return new File([imageBlob], fileName, {type: 'image/jpeg'});
  }

  dataURItoBlob(dataURI) {
    const byteString = atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], {type: 'image/jpeg'});
  }
}
