import {Component, OnInit} from '@angular/core';
import {ConsultantNotificationConfigurationResource} from '../../../generated/resources';
import {ConsultantNotificationConfigurationData} from '../../../generated/data';
import {UtilityService} from '../../service/utility-service';

@Component({
  selector: 'email-notifications',
  templateUrl: './email-notifications.component.html',
  styleUrls: ['./email-notifications.component.scss']
})
export class EmailNotificationsComponent implements OnInit {

  config: ConsultantNotificationConfigurationData;
  configs = [];

  loadingConfig: boolean = true;
  savingConfig: boolean = false;

  constructor(
      private consultantNotificationConfigurationResource: ConsultantNotificationConfigurationResource,
      private utilityService: UtilityService
  ) {
  }

  ngOnInit(): void {
    this.getConfig();
  }

  updateConfig() {
    this.savingConfig = true;
    this.consultantNotificationConfigurationResource.updateConsultantNotificationConfiguration(this.config).then(result => {
      this.utilityService.showSnackBar('Erfolgreich Gespeichert');
      this.savingConfig = false;
    });
  }

  getConfig() {
    this.loadingConfig = true;
    this.consultantNotificationConfigurationResource.getConsultantNotificationConfiguration().then(result => {
      this.config = result;
      this.loadingConfig = false;
    });
  }

}
