import {Component, Input, OnInit} from '@angular/core';
import {ChatData, ChatMinimalData} from "../../../generated/data";
import {ConsultantAuthService} from "../../service/consultant.auth.service";
import {ConsultantTalentProfilePictureResource, ConsultantTalentResource} from "../../../generated/resources";
import {environment} from "../../../environments/environment";
import {ChatService} from "../../service/chat.service";

@Component({
  selector: 'app-chat-minimal',
  templateUrl: './chat-minimal.component.html',
  styleUrls: ['./chat-minimal.component.scss']
})
export class ChatMinimalComponent implements OnInit {

  profilePictureUrl: string = 'url(../assets/images/blurred_user.png)'
  lastMessage: string
  talentId: number

  @Input() chatMinimalData: ChatMinimalData
  @Input() consultantId: number
  @Input() chatName: string

  constructor(
      private authService: ConsultantAuthService,
      private talentService: ConsultantTalentResource,
      public chatService: ChatService,
      private consultantTalentProfilePictureResource: ConsultantTalentProfilePictureResource
  ) { }

  ngOnInit(): void {
    this.getLastMessageSentText()
    this.getProfilePicture()
  }

  getProfilePicture(): void {
    for (let member of this.chatMinimalData.members) {
      if (member.id != this.consultantId) {
        this.talentId = member.id
      }
    }

    this.talentService.getTalent(this.talentId).then(result => {
      if (result.profilePictureId) {
        this.consultantTalentProfilePictureResource.getProfileImageHash(this.talentId).then( result => {
          this.profilePictureUrl = 'url(' + environment.apiUrl + `/consultant/talents/${this.talentId}/profilePicture/${result})`
        })
      }
    })
  }

  getLastMessageSentText(): void {
    let sender = ""
    if (this.chatMinimalData.lastMessage == null) return

    if (this.chatMinimalData.lastMessage.sender.id == this.authService.getAccountInfo().id) {
      sender = "Du"
    } else {
      sender = this.chatMinimalData.lastMessage.senderFirstName
    }

    this.lastMessage =  sender + ": " + this.chatMinimalData.lastMessage.text
  }
}
