import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'consultantNotification'
})
export class ConsultantNotificationPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch (value) {
      case 'ConsultantTalentRejectedByCompanyNotification':
        return 'Talent wurde aussortiert';
      case 'ConsultantTalentCvUploadNotification':
        return 'Erster CV hochgeladen';
      case 'ConsultantTalentHiredByCompanyNotification':
        return 'Talent wurde eingestellt';
      case 'ConsultantTalentAssignedNotification':
        return 'Neues Talent erhalten';
      case 'ConsultantTalentAppliedForPositionNotification':
        return 'Talent hat sich beworben';
      case 'ConsultantOpenFirstContactTalentsNotification':
        return 'Bitte Kontaktiere deine neuen Talente';
      case 'ConsultantTalentRemovedFromMatchingNotification':
        return 'Talent möchte keine Stellenvorschläge';
    }
    return null;
  }

}
