import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ConsultantNewHiringData} from "../../../generated/data";
import {ConsultantNotificationResource} from "../../../generated/resources";

@Component({
  selector: 'app-new-hiring-celebration-dialog',
  templateUrl: './new-hiring-celebration-dialog.component.html',
  styleUrls: ['./new-hiring-celebration-dialog.component.scss']
})
export class NewHiringCelebrationDialogComponent implements OnInit {

  incentAvailable: boolean = false

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: ConsultantNewHiringData[],
      private dialogRef: MatDialogRef<NewHiringCelebrationDialogComponent>,
      private consultantNotificationResource: ConsultantNotificationResource
  ) { }

  ngOnInit(): void {
    this.resetHirings()
    let index = this.data.findIndex( a => a.origin == "Uniwunder")
    if (index >= 0) this.incentAvailable = true
  }

  close() {
    this.dialogRef.close()
  }

  resetHirings() {
    this.consultantNotificationResource.resetNewHirings()
  }

}
