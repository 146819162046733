import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl} from "@angular/forms";

@Component({
  selector: 'password-input-field',
  templateUrl: './password-input-field.component.html',
  styleUrls: ['./password-input-field.component.scss']
})
export class PasswordInputFieldComponent implements OnInit {

  type: string = "password";

  @Input() control: AbstractControl;
  @Input() placeholder: string;
  @Input() name: string;

  constructor() {
  }

  ngOnInit() {

    if (!this.name || this.name == '') {
      this.name = 'password';
    }

    if (!this.placeholder || this.placeholder == '') {
      this.placeholder = 'Neues Passwort*';
    }
  }

  togglePasswordVisibility() {
    if (this.type === 'password') {
      this.type = 'text'
    } else {
      this.type = 'password'
    }
  }

}
