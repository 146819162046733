import {Injectable} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';

@Injectable({
    providedIn: 'root'
})
export class MediaService {
    mobileQuery: MediaQueryList;

    constructor(
        private media: MediaMatcher
    ) {
    }

    getMaxWidthQuery(widthPx: number) {
        return this.media.matchMedia(`(max-width: ${widthPx}px)`);
    }

    isMobile() {
        return this.mobileQuery.matches;
    }

    isIOs(): boolean {
        return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    }
}

