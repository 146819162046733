<dialog-header dialogHeadline="Talent / Unternehmen einladen"></dialog-header>
<div id="dialog-content-wrapper" fxLayout="column" fxLayoutAlign="space-between start">

    <div class="dialog-main-content layout-padding">
        <h2>Für Talente</h2>
        <p>
            Du kannst diesen Link deinen Talenten schicken - damit können sie sich im Talentagent anmelden und du wirst ihnen sofort als Coach zugewiesen.
        </p>
        <div class="width-100" fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field class="mat-form-field-long">
                <input fxFill [ngModel]="inviteLinkTalent" [placeholder]="'Talent Einlade-Link'" matInput type="text">
            </mat-form-field>
            <button mat-icon-button (click)="copyInviteLink(inviteLinkTalent)">
                <i class="mdi mdi-content-copy"></i>
            </button>
        </div>

        <h2>Für Unternehmen</h2>
        <p style="margin-top: 20px">
            Du kannst diesen Link einem Unternehmen schicken - damit können sie sich im Talentagent anmelden.
        </p>

        <div class="width-100" fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field class="mat-form-field-long">
                <input fxFill [ngModel]="inviteLinkCompany" [placeholder]="'Unternehmen Einlade-Link'" matInput type="text">
            </mat-form-field>
            <button mat-icon-button (click)="copyInviteLink(inviteLinkCompany)">
                <i class="mdi mdi-content-copy"></i>
            </button>
        </div>
    </div>

    <div class="dialog-action-bar" fxLayoutGap="10px">
        <button mat-flat-button (click)="close()">schließen</button>
    </div>
</div>

