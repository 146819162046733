import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import {ChatData, ChatMemberData, ChatMinimalData, ConsultantTalentProfileData, LabelData} from "../../generated/data";
import {ConsultantAuthService} from "./consultant.auth.service";

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  refreshChats: Subject<boolean> = new Subject<boolean>()

  constructor(
      private authService: ConsultantAuthService
  ) { }

  getChatPartnerByChat(chat: ChatData | ChatMinimalData): ChatMemberData {
    return chat?.members.find(member => member.dtype == 'Talent')
  }

  createDummyChat(talent?: ConsultantTalentProfileData): ChatData {
    return <ChatData>{
      id: null,
      members: talent? [{dtype: 'Talent', id: talent.id, name: talent.firstName + " " + talent.lastName, studyName: null}] : [],
      messages: [],
      deleted: false,
      type: "CoachingChat",
      createdAt: null
    }

  }

  createDummyMinimalChat(talent?: ConsultantTalentProfileData): ChatMinimalData {
    return <ChatMinimalData>{
      id: null,
      members: talent? [{dtype: 'Talent', id: talent.id, name: talent.firstName + " " + talent.lastName, studyName: null}] : [],
      lastMessage: null,
      company: null,
      existsUnseen: false,
      type: 'CoachingChat'
    }
  }

}
