import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConsultantTalentData, ConsultantTalentProfileData} from "../../../generated/data";
import {ConsultantTalentResource} from "../../../generated/resources";
import {DialogService} from "../../utility/side-sheet/dialog.service";
import {TalentProfileDialogComponent} from "../../dialog-sheets/talent-profile-dialog/talent-profile-dialog.component";
import {
  RateTalentContactedDialogComponent
} from "../../dialog-sheets/rate-talent-contacted-dialog/rate-talent-contacted-dialog.component";
import {NotRatedTalentsComponent} from "../not-rated-talents/not-rated-talents.component";
import {animate} from "@angular/animations";


@Component({
  selector: 'app-not-rated-talent-card',
  templateUrl: './not-rated-talent-card.component.html',
  styleUrls: ['./not-rated-talent-card.component.scss']
})
export class NotRatedTalentCardComponent implements OnInit {

  @Input() talent: ConsultantTalentData
  @Output("delete") deleteItem: EventEmitter<any> = new EventEmitter()

  constructor(
      private talentResource: ConsultantTalentResource,
      private dialogService: DialogService,
  ) {}

  ngOnInit(): void {
  }

  openRateTalentContactedDialog(talentId: number): void {
    const comp = this.dialogService.openOverlay(RateTalentContactedDialogComponent, '600px');
    comp.instance.talentId = talentId
    let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(result => {
      if (result) {
        document.getElementById(talentId.toString()).style.animation = "bg-change 1s"
        this.deleteItem.emit(this.talent.id)
      }
      subscription.unsubscribe()
    })
  }
}
