<div>
    <div class="main-header" fxLayout="column" fxLayoutAlign="star start">
        <div class="explanation">Hier kannst du wöchentliche Zeiten angeben, in denen du am ehesten Zeit für einen Talentagent-Termin hast.
            Diese Zeiten werden deinen Talenten bevorzugt für ihre Anfrage empfohlen. Du kannst die Termine aber immer noch prüfen und ggf. ablehnen.
        </div>
    </div>

    <div class="width-100" style="padding: 0 16px 16px 16px" fxLayout="row" fxLayoutAlign="end end">
        <button color="primary" mat-flat-button (click)="createAvailability()">Neuen Terminslot erstellen</button>
    </div>

    <div class="mat-elevation-z3">
        <loading-spinner [loadingBool]="loading">
            <table mat-table [dataSource]="availabilityData" matSort>
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>Datum</th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="!element.reoccurring">{{element.startDate | date: 'dd.MM.yy,  HH:mm'}} - {{element.endDate | date: 'HH:mm'}}</div>
                        <div *ngIf="element.reoccurring">ab {{element.startDate | date: 'E, dd.MM.yy,  HH:mm'}} - {{element.endDate | date: 'HH:mm'}}</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="reoccurring">
                    <th mat-header-cell *matHeaderCellDef>Wiederholung Wöchentlich</th>
                    <td mat-cell *matCellDef="let element"> {{element.reoccurring? 'Ja' : 'Nein'}} </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <div class="actions" fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="8px">
                            <button matTooltip="Terminslot löschen"
                                    mat-button color="primary"
                                    (click)="deleteAvailability(element.id)">
                                Terminslot löschen
                            </button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>

            <div *ngIf="!availabilityData.length && !loading" class="text-center layout-padding-48 grey">Du hast aktuell keine Terminslots eingetragen</div>

        </loading-spinner>
    </div>
</div>
