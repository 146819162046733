import {Component, OnInit, ViewChild} from '@angular/core';
import {ConsultantTalentResource} from "../../../generated/resources";
import {ConsultantTalentData} from "../../../generated/data";
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-not-rated-talents',

  templateUrl: './not-rated-talents.component.html',
  styleUrls: ['./not-rated-talents.component.scss']
})
export class NotRatedTalentsComponent implements OnInit {

  unratedTalentsData: ConsultantTalentData[] = []
  deleteCardId: number = 0
  lastDeletedIndex: number = Number.MAX_SAFE_INTEGER
  height: number = 0
  loading: boolean = true
  page: number = 0
  pageSize: number = 10
  totalElements: number = 0

  constructor(
      private talentResource: ConsultantTalentResource
  ) { }

  ngOnInit(): void {
    this.loadUnratedTalents()
  }

  loadUnratedTalents(): void {
    this.loading = true
    this.talentResource.getUnratedTalentsOfConsultant(
        {page: this.page, pageSize: this.pageSize}
    ).then(talents => {
      this.unratedTalentsData = talents.content
      this.totalElements = talents.totalElements
      this.loading = false
    })
  }

  handlePage(event): void {
    this.page = event.pageIndex
    this.loadUnratedTalents();
  }

  removeUnratedTalent(id: number, i: number): void {
    this.lastDeletedIndex = i
    this.deleteCardId = id
    this.getElementHeight(i)
    setTimeout(() => {
      this.unratedTalentsData.splice(i, 1)
      this.deleteCardId = null
      this.lastDeletedIndex = Number.MAX_SAFE_INTEGER
      if (this.unratedTalentsData.length == 0) {
        this.page = 0
        this.loadUnratedTalents()
      }
    }, 1000)
  }


  getElementHeight(i: number): void {
    this.height = document.getElementById("card-"+ i.toString()).offsetHeight
  }

}
