import {AfterViewInit, Directive, ElementRef, HostListener} from '@angular/core';
import {DialogService} from "./dialog.service";

@Directive({
  selector: 'button[side-sheet-back-arrow-button]'
})
export class SideSheetBackArrowDirective implements AfterViewInit {

  @HostListener('click', ['$event.target'])
  back() {
    this.dialogService.getLastSheet().sideSheetRef.sheetClosed.next()
  }

  constructor(private el: ElementRef, private dialogService: DialogService) {
  }

  ngAfterViewInit(): void {
    // if (this.dialogService.openedSideSheets.length < 2) {
    //   this.el.nativeElement.disabled = true;
    //   this.el.nativeElement.style.opacity = 0;
    // }
  }


}
