<div id="login-main-wrapper">
    <div id="login-support-main-wrapper">
        <div id="login-window" class="mat-elevation-z4">
<!--            <div class="logo mat-elevation-z1"></div>-->
            <img class="logo" src="assets/images/talents-logo.png">
            <div>
                <div class="headline">LOGIN</div>
                <form class="login" fxLayout="column" fxLayoutAlign="center center" [formGroup]="loginForm"
                      *ngIf="loginForm">
                    <mat-form-field class="input">
                        <input (keydown.enter)="performLogin()" matInput formControlName="username"
                               placeholder="E-Mail-Adresse" required>
                    </mat-form-field>
                    <mat-form-field class="input">
                        <input (keydown.enter)="performLogin()" matInput formControlName="password"
                               placeholder="Passwort" required
                               type="password">
                    </mat-form-field>

                    <div *ngIf="error && errorMessage">
                        <div class="error-message">{{errorMessage}}</div>
                    </div>

                    <div (click)="openSendNewPasswordDialog()" class="new-password">Neues Passwort anfordern</div>
                </form>
                <div class="width-100 button-container m-top30" fxLayout="row" fxLayoutAlign="center center">
                    <loading-button [loadingBool]="loadingPerformLogin" (clickEvent)="performLogin()">Login
                    </loading-button>
                </div>
            </div>
        </div>
    </div>
</div>
