import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'applicationState'
})
export class ApplicationStatePipe implements PipeTransform {


  transform(value: unknown, ...args: unknown[]): unknown {
    switch(value){
      case "Draft": return "Entwurf"
      case "Sent": return "Warten auf QS"
      case "Denied": return "Abgelehnt(QS)"
      case "Withdrawn": return "Zurückgezogen"
      case "Granted": return "Von QS freigegeben"
      case "Failed": return "Abgelehnt(UN)"
      case "Interesting": return "Interessant"
      case "AppProcessEnter": return "Im Bewerbungsprozess"
      case "Hired": return "Angestellt"

    }
  }
}
