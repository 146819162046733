<img *ngIf="positionOrigin == 'Uniwunder'"
     src="assets/images/uniwunder-logo.jpeg"
     class="m-right5" matTooltip="Vermittlung über Uniwunder GmbH">

<img *ngIf="positionOrigin == 'Campusjaeger'"
     src="assets/images/campusjaeger-logo.jpg"
     class="m-right5" matTooltip="Vermittlung über Campusjäger GmbH">

<img *ngIf="positionOrigin == 'Pluss'"
     src="assets/images/pluss.png"
     class="m-right5" matTooltip="Vermittlung über Pluss GmbH">

<img *ngIf="positionOrigin == 'Absolventa'"
     src="assets/images/absolventa-gmb-h-logo-xl.png"
     class="m-right5" matTooltip="Vermittlung über Absolventa GmbH">

<img *ngIf="positionOrigin == 'Stellenanzeigen'"
     src="assets/images/Stellenanzeigen_logo.png"
     class="m-right5" matTooltip="Vermittlung über Stellenanzeigen.de">

<img *ngIf="positionOrigin == 'Jobware'"
     src="assets/images/jobware_logo.png"
     class="m-right5" matTooltip="Vermittlung über Jobware">

<img *ngIf="positionOrigin == 'Joblift'"
     src="assets/images/joblift_logo.png"
     class="m-right5" matTooltip="Vermittlung über Joblift">

<img *ngIf="positionOrigin == 'Experteer'"
     src="assets/images/experteer_logo.jpg"
     class="m-right5" matTooltip="Vermittlung über Experteer">
