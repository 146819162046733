import {Component, OnInit} from '@angular/core';
import {SideSheetRef} from '../../utility/side-sheet/sideSheetRef';
import {GeneralUtilsService} from '../../service/general-utils.service';
import {ConsultantHidEveResource, ConsultantProfileResource} from '../../../generated/resources';
import {ConsultantAuthService} from '../../service/consultant.auth.service';

@Component({
  selector: 'app-invite-talent-dialog',
  templateUrl: './invite-talent-dialog.component.html',
  styleUrls: ['./invite-talent-dialog.component.scss']
})
export class InviteTalentDialogComponent implements OnInit {

  inviteLinkTalent: string;
  inviteLinkCompany: string;

  constructor(
      private sideSheetRef: SideSheetRef,
      private generalUtilsService: GeneralUtilsService,
      private consultantAuthService: ConsultantAuthService,
      private consultantHidEveResource: ConsultantHidEveResource,
      private consultantProfileResource: ConsultantProfileResource
  ) {
  }

  ngOnInit(): void {
    this.getShortUrlForConsultant();
    this.getShortenedCompanyInviteUrl()
  }

  getShortUrlForConsultant() {
    this.consultantHidEveResource.getOrCreateShortenUrl().then(result => {
      this.inviteLinkTalent = result;
    });
  }

  getShortenedCompanyInviteUrl() {
    this.consultantHidEveResource.getShortenedCompanyInviteUrl().then(result => {
      this.inviteLinkCompany = result;
    });
  }



  close() {
    this.sideSheetRef.sheetClosed.next(false);
  }

  copyInviteLink(value: string) {
    this.generalUtilsService.copyToClipboard(value);
  }


}
