import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {delay} from '../service/utility-service';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  userInfo;

  //other
  chosenSettingsItem = 'notifications';
  settingItemChanged: boolean = false;

  saving = false;
  loading = false;

  constructor(
      private router: Router,
      private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
  }

  choseMenuSetting(settingsItem: string) {
    if (this.chosenSettingsItem != settingsItem) {
      this.chosenSettingsItem = settingsItem;
      this.settingItemChanged = true;
      delay(300).then(() => {
        this.settingItemChanged = false;
      });
    }
  }
}
