<div id="loading-spinner-content-wrapper">
    <div id="loading-spinner-overlay" *ngIf="loadingBool" fxLayout="column" fxLayoutAlign="center center">
        <mat-progress-spinner
                [ngClass]="{'smaller' : (smaller && loadingBool)}"
                color="primary"
                diameter="30"
                strokeWidth="4"
                mode="indeterminate">
        </mat-progress-spinner>
        <div *ngIf="hint?.length > 0" class="primary m-top20">{{hint}}</div>
    </div>
    <ng-content></ng-content>
</div>
