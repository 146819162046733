import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DialogService} from "../side-sheet/dialog.service";
import {
  RecommendPositionDialogComponent
} from "../../dialog-sheets/recommend-position-dialog/recommend-position-dialog.component";
import {ConsultantPositionRecommendationInfoData, ConsultantTalentPositionData} from "../../../generated/data";
import {ConsultantPrimeRecommendationResource, ConsultantTalentResource} from "../../../generated/resources";

@Component({
  selector: 'app-recommend-mlp-position-button',
  templateUrl: './recommend-mlp-position-button.component.html',
  styleUrls: ['./recommend-mlp-position-button.component.scss']
})
export class RecommendMlpPositionButtonComponent implements OnInit {

  @Input() talentId: number
  @Input() buttonText: string = "MLP Beraterstelle empfehlen"

  loading: boolean = true;

  positionRecommendedInfo: ConsultantPositionRecommendationInfoData;

  isRecommended: boolean
  result: boolean = false

  constructor(
      private dialogService: DialogService,
      private recommendationResource: ConsultantPrimeRecommendationResource
  ) { }

  ngOnInit(): void {
    this.loadPosition()
  }

  loadPosition(): void {
    this.recommendationResource.getTalentMlpPositionRecommendationInfo(this.talentId).then(result => {
      this.positionRecommendedInfo = result
      this.isRecommended = result.isRecommended
      this.loading = false
    })
  }

  openRecommendPositionDialog(): void {
    const comp = this.dialogService.openOverlay(RecommendPositionDialogComponent, '600px')
    comp.instance.position = this.positionRecommendedInfo.position
    comp.instance.talentId = this.talentId
    let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(result => {
      if (result == true) {
        this.isRecommended = true
        this.result = true
      }
      subscription.unsubscribe()
    })
  }
}
