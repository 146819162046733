import {Component, OnInit} from '@angular/core';
import {SideSheetRef} from "../../utility/side-sheet/sideSheetRef";
import {GeneralUtilsService} from "../../service/general-utils.service";
import {ConsultantTalentResource} from "../../../generated/resources";
import {ConsultantAssessTalentData, ConsultantTalentMinimalData} from "../../../generated/data";
import {DialogService} from "../../utility/side-sheet/dialog.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-rate-talent-contacted-dialog',
    templateUrl: './rate-talent-contacted-dialog.component.html',
    styleUrls: ['./rate-talent-contacted-dialog.component.scss']
})
export class RateTalentContactedDialogComponent implements OnInit {

    //input
    talentId: number

    talentData: ConsultantTalentMinimalData

    ratingForm: FormGroup

    loadingCreateRating: boolean = false
    loadingTalent: boolean = true

    constructor(
        private sideSheetRef: SideSheetRef,
        private dialogService: DialogService,
        private generalUtilsService: GeneralUtilsService,
        private consultantTalentResource: ConsultantTalentResource,
        private fb: FormBuilder
    ) {
    }

    ngOnInit(): void {
        this.getTalent()
        this.initForm()
    }

    initForm() {
        this.ratingForm = this.fb.group({
            consultantReachedTalent: [null, Validators.required],
            consultantJobQuestion: [null],
            consultantJobAnswer: [null],
            knewAboutContact: [null],
            talentRating: [null],
            useableForFinancialAdvice: [null],
            other: [null],
        })

        this.ratingForm.controls.consultantReachedTalent.valueChanges.subscribe(next => {
            if (next == 'TalentReached') {
                this.ratingForm.controls.talentRating.setValidators(Validators.required)
                this.ratingForm.controls.knewAboutContact.setValidators(Validators.required)
                this.ratingForm.controls.useableForFinancialAdvice.setValidators(Validators.required)
                this.ratingForm.controls.consultantJobQuestion.setValidators(Validators.required)
                this.ratingForm.controls.consultantJobAnswer.setValidators(Validators.required)

                this.ratingForm.controls.talentRating.updateValueAndValidity()
                this.ratingForm.controls.knewAboutContact.updateValueAndValidity()
                this.ratingForm.controls.useableForFinancialAdvice.updateValueAndValidity()
                this.ratingForm.controls.consultantJobQuestion.updateValueAndValidity()
                this.ratingForm.controls.consultantJobAnswer.updateValueAndValidity()

            } else {
                this.ratingForm.controls.talentRating.clearValidators()
                this.ratingForm.controls.knewAboutContact.clearValidators()
                this.ratingForm.controls.useableForFinancialAdvice.clearValidators()
                this.ratingForm.controls.consultantJobQuestion.clearValidators()
                this.ratingForm.controls.consultantJobAnswer.clearValidators()

                this.ratingForm.controls.talentRating.updateValueAndValidity()
                this.ratingForm.controls.knewAboutContact.updateValueAndValidity()
                this.ratingForm.controls.useableForFinancialAdvice.updateValueAndValidity()
                this.ratingForm.controls.consultantJobQuestion.updateValueAndValidity()
                this.ratingForm.controls.consultantJobAnswer.updateValueAndValidity()

            }
        })

        this.ratingForm.controls.consultantJobQuestion.valueChanges.subscribe(next => {
            this.ratingForm.controls.consultantJobAnswer.setValue(null)
        })
    }

    getTalent() {
        this.loadingTalent = true
        this.consultantTalentResource.getMinimalTalent(this.talentId).then(result => {
            this.talentData = result
            this.loadingTalent = false
        })
    }

    changeLeadQuality(quality) {
        if (this.ratingForm.controls.talentRating.disabled) return
        this.ratingForm.controls.talentRating.setValue(quality)
    }

    close(result: boolean = false) {
        this.sideSheetRef.sheetClosed.next(result);
    }

    rateContact() {
        this.loadingCreateRating = true;

        if (this.ratingForm.controls.consultantReachedTalent.value != 'TalentReached') {
            this.ratingForm.patchValue({
                knewAboutContact: null,
                talentRating: null,
                useableForFinancialAdvice: null
            })
        }

        let data: ConsultantAssessTalentData = {
            consultantReachedTalent: this.ratingForm.controls.consultantReachedTalent.value,
            knewAboutContact: this.ratingForm.controls.knewAboutContact.value,
            talentRating: this.ratingForm.controls.talentRating.value,
            useableForFinancialAdvice: this.ratingForm.controls.useableForFinancialAdvice.value,
            consultantJobQuestion: this.ratingForm.controls.consultantJobAnswer.value,
            other: this.ratingForm.controls.other.value
        }

        this.consultantTalentResource.reportTalentCoachRatedTalent(data, this.talentId).then(result => {
                if (result) {
                    this.generalUtilsService.showSnackBar("Kontakt erfolgreich bewertet")
                    this.close(true)
                } else this.close(false)
                this.loadingCreateRating = false
            })
    }
}
