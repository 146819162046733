<div id="{{talent.id}}" class="card-wrapper mat-elevation-z0" fxLayout="row">

    <div class="talent-card width-100" fxLayout="row" fxLayoutAlign="space-between">
        <div style="padding-right: 8px" fxLayout="column">
            <div class="talent-name-text width-100 height-100">
                <span>{{talent?.firstName}} {{talent?.lastName}}</span>
            </div>
            <div class="last-login-text width-100">
                <span>Erhalten am {{talent?.assignDate | date: "dd.MM.yyyy hh:mm"}}</span>
            </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="center">
            <button class="button" mat-flat-button color="warn" (click)="openRateTalentContactedDialog(talent.id)">Bewerten</button>
        </div>
    </div>
</div>